import request from "@/utils/request";
import { filteParams } from "@/utils/handleData.js";
//获取营销活动集合
export const marketCompaignsList = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/market-campaigns",
    params,
  });
};

// 获取赠送数据统计
export const marketGiftData = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/market-campaigns/gift-data",
    params,
  });
};

// 
export const getMarketCampaignItem = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/market-campaigns/items",
    params:filteParams(params),
  });
};

// 获取已经指定参与会员会员
export const getMarketCampaignJoinVip= (params = {}) => {
  return request({
    method: "GET",
    url: "/api/market-campaigns/joined-vip-infos",
    params,
  });
};
// 获取指定会员（定向赠送）

export const getCanJoinVip= (params) => {
  return request({
    method: "GET",
    url: `/api/market-campaigns/can-join-vip-infos`,
    params:filteParams(params)
  });
};

//新增营销活动
export const addMarketCampaign = (data) => {
  return request({
    method: "POST",
    url: "/api/market-campaigns",
    data,
  });
};



//更新营销活动
export const updateMarketCampaign = (data) => {
  return request({
    method: "PUT",
    url: "/api/market-campaigns",
    data,
  });
};
//
//获取活动详情
export const getmarketCompaignDetails = (id) => {
  return request({
    method: "GET",
    url: `/api/market-campaigns/${id}`,
  });
};

// 获取营销活动日志集合
// 

export const getMarketCompaignLogs = (params) => {
  return request({
    method: "GET",
    url: `/api/market-campaigns/data-opr-logs`,
    params
  });
};
// 获取单个营销活动(开卡，关注)
//
export const getMarketSingleCampaigns = (id) => {
  return request({
    method: "GET",
    url: `/api/market-campaigns/single/${id}`,
  });
};

// 参加关注有礼活动
export const marketCompaignFocusGift = (data) => {
  return request({
    method: "POST",
    url: "/api/market-compaigns/focus-gift",
    data,
  });
};
//终止营销活动
export const stopMarketCompaigns = (id, data) => {
  return request({
    method: "PUT",
    url: `/api/market-campaigns/${id}/stop`,
    data,
  });
};

//终止营销活动
export const restoreMarketCompaigns = (id, data) => {
  return request({
    method: "PUT",
    url: `/api/market-campaigns/${id}/restore`,
    data,
  });
};

// 营销活动关键指标
export const getMarketIndicator = (params) => {
  return request({
    url: "/api/report/market-campaigns-indicator",
    method: "GET",
    params: filteParams(params),
  });
};

// 营销分析
export const getMarketAnalysis = (params) => {
  return request({
    url: "/api/report/market-coupon-analysis",
    method: "GET",
    params: filteParams(params),
  });
};

// 营销排名
export const getMarketRank = (params) => {
  return request({
    url: "/api/report/market-ranking",
    method: "GET",
    params,
  });
};

// 优惠类型核销

export const getMarketType = (params) => {
  return request({
    url: "/api/report/coupon-off-ranking",
    method: "GET",
    params,
  });
};

// 查看营销活动
export const getMarketCampaign = (id, params = {}) => {
  return request({
    url: `/api/market-compaigns/${id}`,
    params,
  });
};

// 开启营销活动
export const getMarketStar = (id, data) => {
  return request({
    url: `/api/market-compaigns/${id}/recovery`,
    method: "PUT",
    data,
  });
};

<template>
  <div class="controlContain">
    <div class="forgetPassPage">
      <div class="_title">
        <img src="https://jlk-sys-image.oss-cn-zhangjiakou.aliyuncs.com/202208151056534746160042807478817.png"
          alt="聚来客LOGO" />
        <div>聚哒客商户中心</div>
      </div>
      <div class="_content">
        <h2 style="font-size: 30px">请选择商户</h2>
        <!-- <div class="tips">该手机号已关联多个商户，请选择要操作的商户</div> -->
        <ul class="merchant_contain" :style="{ justifyContent: merchantList.length > 4 ? '' : 'center' }">
          <li class="merchant_type" v-for="(item, index) in merchantList" :key="index">
            <span :class="[
              'left_tips',
              item.vip_open_status == 2
                ? 'no_open'
                : item.vip_open_status == 3 || item.vip_open_status == 4
                  ? 'is_overdue'
                  : item.vip_open_status == 5
                    ? 'no_open'
                    : '',
            ]">{{ open_status[item.vip_open_status] }}</span>
            <span class="logo">{{
              item.merchant_pass_type == 2
                ? "直连"
                : item.merchant_pass_type == 1
                  ? item.qualification_type_name
                  : item.merchant_pass_type == 3
                    ? "无支付"
                    : "/"
            }}</span>
            <div class="merchant_name">
              {{ item.abbreviation_name }}
            </div>
            <div class="merchant_no">{{ item.merchant_no }}</div>
            <span :class="['btn', item.vip_open_status == 5 ? 'dis_btn' : '']" @click="
              Fn(
                item.vip_open_status == 2 || item.vip_open_status == 4
                  ? fn_status[item.vip_open_status]
                  : item.is_initialize
                    ? fn_status[item.vip_open_status]
                    : init_btn[item.is_initialize],
                item
              )
              ">{{
                item.vip_open_status == 2 || item.vip_open_status == 4
                  ? btn_status[item.vip_open_status]
                  : item.is_initialize
                    ? btn_status[item.vip_open_status]
                    : init_status[item.is_initialize]
              }}</span>
            <span class="create_date" v-if="item.actual_name || item.role_names">
              {{ item.actual_name }}{{ item.role_names ? " | " + item.role_names : "" }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      merchantList: [],
      open_status: {
        1: "已开通",
        2: "未开通",
        3: "即将过期",
        4: "已过期",
        5: "已禁用",
      },
      init_status: {
        false: "初始化商户",
      },
      init_btn: {
        false: "initMerchant",
      },
      btn_status: {
        1: "进入商户",
        2: "立即开通",
        3: "进入商户",
        4: "立即续费",
        5: "进入商户",
      },
      fn_status: {
        1: "goMerchant",
        2: "openMerchant",
        3: "goMerchant",
        4: "renewMerchant",
      },
    };
  },
  mounted() {
    document.title = "聚哒客·微会员";
    this.init();
  },
  methods: {
    // 进入商户
    openMerchant(item) {
      if (item.vip_open_status == 2 || item.vip_open_status == 4) {
        this.$confirm(
          `商户「${item.abbreviation_name}」微会员服务${item.vip_open_status == 2 ? "未开通" : "已过期"
          }，请联系服务商开通，感谢您的使用！`,
          "提示",
          {
            confirmButtonText: "确定",
            showCancelButton: false,
            showClose: false,
            closeOnPressEscape: false,
            closeOnClickModal: false,
          }
        );
      }
    },

    async goMerchant(item) {
      const ret = await this.$API.getEntry(item.user_id);
      const data = {
        merchant_id: item.merchant_id,
        abbreviation_name: item.abbreviation_name,
        vip_interface_version: item.vip_interface_version,
      };
      let userInfo = sessionStorage.getItem("user")
        ? JSON.parse(sessionStorage.getItem("user"))
        : {};
      if (ret && ret.data) {
        Object.assign(userInfo, ret.data)
        this.$store.dispatch("setUserAction", userInfo).then(res => {
          this.$router.replace({ path: "/dashBoard", query: data });
        });

      }
    },
    // 续费开通
    renewMerchant(item) {
      this.$confirm(
        `商户「${item.abbreviation_name}」微会员服务已过期，服务不可使用，请联系服务商续费，感谢您的使用！`,
        "提示",
        {
          confirmButtonText: "确定",
          showCancelButton: false,
          showClose: false,
          closeOnPressEscape: false,
          closeOnClickModal: false,
        }
      );
    },
    Fn(method, item) {
      this[method] instanceof Function && this[method](item);
    },
    initMerchant(item) {
      if (item && item.merchant_id) {
        console.log(item);
        sessionStorage.setItem("merchant", JSON.stringify(item));
        this.$router.push({
          path: "/init",
        });
      }
    },
    async init() {
      const ret = await this.$API.getMerchantConsole();
      if (ret && ret.ok) {
        this.merchantList = ret.data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixin.scss";

.controlContain {
  height: 100%;
  width: 100%;

  .forgetPassPage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $sidebar-bg;
    display: flex;

    ._title {
      height: 54px;
      background: #fff;
      display: flex;
      align-items: center;
      padding: 0 30px;

      img {
        width: 38px;
        height: 38px;
        margin-right: 30px;
      }

      div {
        @include mixin-font_18_8($secondary-color);
      }
    }

    ._content {
      height: calc(100% - 54px);
      background: #f8f8f8;
      box-sizing: border-box;
      padding: 60px 340px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-y: auto;

      h2 {
        margin-bottom: 15px;
      }

      .tips {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
        margin-bottom: 60px;
      }

      .merchant_contain {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .merchant_type {
          position: relative;
          margin-right: 20px;
          margin-bottom: 30px;
          padding: 50px 20px 30px;
          text-align: center;
          box-sizing: border-box;
          width: 285px;
          height: 360px;
          background: #ffffff;
          border: 1px solid #efefef;
          border-radius: 6px;

          &:hover {
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
          }

          .left_tips {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 66px;
            height: 27px;
            background: #0ba976;
            opacity: 1;
            border-radius: 6px 0px 6px 0px;
            text-align: center;
            line-height: 27px;
            color: #fff;
          }

          .logo {
            width: 68px;
            height: 68px;
            background: #f6f8f7;
            border-radius: 50%;
            text-align: center;
            line-height: 68px;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #2b2b2b;
            display: inline-block;
            margin-bottom: 20px;
          }

          .merchant_name {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #202020;
            margin-bottom: 10px;
          }

          .merchant_no {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #999999;
          }

          .btn {
            width: 127px;
            height: 40px;
            background: #0ba976;
            opacity: 1;
            border-radius: 4px;
            color: #fff;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            position: absolute;
            bottom: 70px;
            left: 79px;
            line-height: 40px;
            cursor: pointer;
          }

          .create_date {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #999999;
            opacity: 1;
            position: absolute;
            bottom: 30px;
            left: 0px;
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
}

.dis_btn {
  background: #bbbbbb !important;
}

::-webkit-input-placeholder {
  /*Webkit browsers*/
  color: #000000 !important;
  font-size: 16px;
}

:-moz-placeholder {
  /*Mozilla Firefox 4 to 8*/
  color: #000000 !important;
  font-size: 16px;
}

::moz-placeholder {
  /*Mozilla Firefox 19+*/
  color: #000000 !important;
  font-size: 16px;
}

:-ms-input-placeholder {
  /*Internet Explorer 10+*/
  color: #000000 !important;
  font-size: 16px;
}

.no_open {
  background: #bbbbbb !important;
}

.is_overdue {
  background: #ff4a4a !important;
}

.qrCodeLayout {
  #weixin {
    color: red;

    html {
      color: red;
    }

    .impowerBox {
      color: red;

      .wrp_code {
        .qrcode {
          width: 160px;
        }
      }
    }
  }
}
</style>

<template>
    <div class="pwd_contain">
        <div class="pass_label">
            密码规则：长度6到20位，支持数字、字母、符号~!@#$%^&*
        </div>
        <el-form class="formItem" ref="formRef" :model="ruleForm" :rules="rules">
            <el-form-item prop="phone" label="手机号码" style="width: 100%">
                <el-input style="width: 100%" v-model="ruleForm.phone" placeholder="手机号码" disabled />
            </el-form-item>
            <el-form-item label="验证码" prop="captcha_code" style="width:100%">
                <div style="display: flex;align-items: center;justify-content: center;width: 100%;">
                    <el-input v-model="ruleForm.captcha_code" :placeholder="'验证码'" style="margin-right: 10px;" />
                    <el-button size="mini" type="primary" :loading="loading" :disabled="!!count" plain
                        @click="getCaptcha" class="btn">
                        {{ !loading && count ? count + "s" : "获取验证码" }}
                    </el-button>
                </div>
            </el-form-item>
            <el-form-item label="设置新密码" prop="pwd" style="width: 100%">
                <el-input style="width: 100%" autocomplete="new-password" type="password" v-model="ruleForm.pwd"
                    placeholder="新密码" />
            </el-form-item>
            <el-form-item label="确认新密码" prop="re_pwd" class="last-item" style="width: 100%">
                <el-input style="width: 100%" autocomplete="new-password" type="password" v-model="ruleForm.re_pwd"
                    placeholder="确认新密码" />
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { $_md5 } from "@/utils/md5";
import { cloneDeep } from 'lodash'
export default {
    props: {
        formData: {
            type: Object,
            default: () => { }
        }
    },
    mounted() {
        this.ruleForm.phone = this.formData.phone
    },
    data() {
        const confirmPwd = (rule, value, callback) => {
            if (value && value !== this.ruleForm.pwd) {
                return callback(new Error("两次输入的密码不一致!"));
            } else {
                callback();
            }
        };
        const validatePwd = (rule, value, callback) => {
            if (!value || value === "") {
                callback();
            } else if ((value && value.length < 6) || (value && value.length > 20)) {
                callback(new Error("请输入6-20个字符的密码"));
            } else if (!/^[A-Za-z0-9!@#$%]+$/.test(value)) {
                callback(new Error("密码格式不正确"));
            } else {
                callback();
            }
        };
        const validatePhone = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入新手机号码"));
            } else if (!/^1[0-9]{10}$/.test(value)) {
                callback(new Error("手机号码格式不正确"));
            } else {
                callback();
            }
        };
        return {
            ruleForm: {
                phone: "",
                captcha_code: "",
                pwd: "",
                re_pwd: "",
            },
            rules: {
                phone: [{ required: true, validator: validatePhone, trigger: "blur" }],
                captcha_code: [
                    {
                        required: true,
                        message: "请输入验证码",
                        trigger: "blur",
                    },
                ],
                pwd: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入新密码",
                    },
                    { validator: validatePwd, trigger: "blur" },
                ],
                re_pwd: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请再次输入新密码",
                    },
                    { validator: confirmPwd, trigger: "blur" },
                ],
            },
            loading: false,
            count: 0,
            countDownTimer: null
        }
    },
    methods: {
        async getCaptcha() {
            if (this.loading) return false;
            this.loading = true;
            try {
                await this.$API.getForgetCaptcha({ phone: this.formData.phone });
                this.loading = false;
                this.count = 60;
                this.countDownTimer = setInterval(() => {
                    this.count--;
                    if (this.count <= 0) {
                        clearInterval(this.countDownTimer);
                        this.count = 0
                    }
                }, 1000);
            } catch (error) {
                this.loading = false;
            }
        },
        handleOk() {
            return new Promise((resolve, reject) => {
                this.$refs.formRef.validate(async (valid) => {
                    if (valid) {
                        const cloneData = cloneDeep(this.ruleForm);
                        cloneData.pwd = $_md5(cloneData.pwd);
                        cloneData.re_pwd = $_md5(cloneData.re_pwd);
                        const ret = await this.$API.getForgetPwd(cloneData);
                        if (ret && ret.ok) {
                            this.$JlkMessage("更新成功", "success")
                            resolve();
                        }

                    }
                });
            });
        }

    },
    beforeDestroy() {
        if (this.countDownTimer) {
            clearInterval(this.countDownTimer);
        }
    }
}
</script>

<style lang="scss" scoped>
.pwd_contain {
    .pass_label {
        font-weight: bold;
        font-size: 14px;
        padding-bottom: 30px;
    }
}

.forgetPass {
    color: #0BA976;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 0px;
    top: 0px;
}

.el-button--primary.is-plain {
    background: #fff;
}

:deep(.el-form-item__label) {
    display: flex !important;
    justify-content: flex-start;
    padding: 0;
}

:deep(.el-form-item) {
    margin-bottom: 30px !important;
}

:deep(.last-item.el-form-item) {
    margin-bottom: 0 !important;
}
</style>
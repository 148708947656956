import { JSEncrypt } from "jsencrypt";

export function $_md5(password) {
  // RSA 加密
  const pulicKey = `-----BEGIN PUBLIC KEY-----                        MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCF6MtPGLlxpgccR60NVsWsX6wn
U8EF9xURlD7y1iBdLsPzSbR8xaS9vlwTh3b4cp9CVAOTmDQ5A0CF46Cx7lFzXU8N
xOaga8BVD7TSaqc0/yXwdavZQrJh+8xFDpwYOzfSRRI3IHxLSPeaRkC09KuQ/MIP
7+hwpZ0ohUWdZX7nzwIDAQAB
-----END PUBLIC KEY-----`;
  var jsE = new JSEncrypt();
  jsE.setPublicKey(pulicKey);
  return jsE.encrypt(password);
}

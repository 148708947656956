import axios from "axios";
import $router from "../router";
import store from "@/store/index.js";

import { JlkMessage, JlkLoading } from "@/utils/message";

import { getToken, setToken } from "./cookie";

import consoleConfig from "@/utils/config.js";

const qs = require("qs");

let isLock = false;
let refreshNum = 0;

const request = axios.create();
const whiteList = ["/api/sys-templates/initialize"];

const instanceConf = (config) => {
  if (config.method === "get") {
    // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
    config.paramsSerializer = function (params) {
      return qs.stringify(params, {
        arrayFormat: "repeat",
      });
    };
  }
  if (whiteList.findIndex((item) => config.url.indexOf(item) > -1) <= -1) {
    JlkLoading("open", "数据加载中...");
  }

  const merchant = sessionStorage.getItem("merchant")
    ? JSON.parse(sessionStorage.getItem("merchant"))
    : "";
  const user = sessionStorage.getItem("user")
    ? JSON.parse(sessionStorage.getItem("user"))
    : "";
  const loginInfo = sessionStorage.getItem("token")
    ? JSON.parse(sessionStorage.getItem("token"))
    : "";
  config.headers.Authorization = loginInfo.token || "";
  config.headers["login-terminal"] = 1;
  config.headers["Platform"] = 1; // 对应平台id
  config.headers["System"] = 2; //  对应系统id
  config.headers["Content-Type"] = "application/json";
  config.headers["Current-User-Id"] = user.user_id || "";
  config.headers["merchant-id"] = merchant.merchant_id || "";
  return config;
};
// 请求拦截器
request.interceptors.request.use(instanceConf, (error) => {
  JlkLoading("close", "");
  return Promise.reject(error);
});
request.interceptors.response.use(
  async function (response) {
    refreshNum = 0;
    JlkLoading("close", "");
    // noNeedHandle不需要处理,直接返回
    if (response.config && response.config.noNeedHandle && response.data) {
      return response.data;
    }
    if (response.data.ok) {
      return response.data;
    } else {
      if (consoleConfig.statusList.includes(Number(response.data.err.code))) {
        return response.data;
      }
      JlkMessage(response.data.err.msg, "error");
      return response.data;
    }
  },
  async (error) => {
    console.log(error);
    const response = error.response;
    let requests = [];
    console.log(response);
    if (response) {
      if (
        response.status === 401 ||
        (response.data && response.data.err.code === 8011)
      ) {
        // 未授权锁住请求刷新token,获取新token后请求数据
        if (!isLock) {
          isLock = true;
          const loginInfo = sessionStorage.getItem("token")
            ? JSON.parse(sessionStorage.getItem("token"))
            : "";
          let data = {
            refresh_token: loginInfo.refresh_token,
          };
          refreshNum++;
          console.log("我的111222333444-----------------");
          if (refreshNum > 2) {
            isLock = false;   //退出后重置
            //如何刷新token超过3次，直接退出登录
            JlkLoading("close", "");
            store.dispatch("LOINGOUT");
            return;
          }
          try {
            const ret = await axios({
              headers: {
                "Login-Terminal": 1,
                Authorization: loginInfo.token,
                System: 2, //  对应系统id
                Platform: 1,
              },
              method: "PUT",
              url: "https://merc-dev.jlksaas.net/api/merc/personal/refresh",
              data,
            });
            if (ret.data.ok) {
              console.log("我的111222333444666-----------------");
              // 更新cookie中的token
              if (getToken("token")) {
                setToken(ret.data.data.token);
                localStorage.setItem(
                  "cache_user",
                  JSON.stringify(ret.data.data)
                );
              }
              sessionStorage.setItem("token", JSON.stringify(ret.data.data));
              requests.forEach((cb) => cb(ret.data.data.token));
              requests = [];
              isLock = false;
              return request(instanceConf(response.config));
            } else {
              console.log("我的111222333444666777-----------------");
              const msg =
                ret.data.err && ret.data.err.msg
                  ? ret.data.err.msg + ",请重新登录"
                  : "网络开小差了,请稍后刷新重试!";
              sessionStorage.clear();
              localStorage.clear();
              location.reload();
              JlkMessage(msg, "error");
            }
          } catch (error) {
            sessionStorage.clear();
            localStorage.clear();
            $router.push({
              name: "login",
            });
            JlkMessage("登录信息失效", "error");
            isLock = false;
          } finally {
            JlkLoading("close", "");
          }
        } else {
          console.log("我的111222333444-----------------");
          return new Promise((resolve) => {
            requests.push((token) => {
              response.config.headers.System = 2;
              response.config.headers.Platform = 1;
              response.config.headers.Authorization = token;
              resolve(request(instanceConf(response.config)));
            });
          });
        }
      } else {
        const msg =
          response &&
          response.data &&
          response.data.err &&
          response.data.err.msg;
        JlkMessage(msg || "网络开小差了,请稍后刷新重试!", "error");
        JlkLoading("close", "");
      }
    } else {
      JlkLoading("close", "");
      const msg =
        response && response.data && response.data.err && response.data.err.msg;
      JlkMessage(msg || "网络开小差了,请稍后刷新重试!", "error");
    }
  }
);

export default request;

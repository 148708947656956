<template>
  <div :class="['wrap', flagWrapActivate ? 'wrapActivate' : '']">
    <div class="menuList">
      <div class="menu">
        <div class="menu_list">
          <div class="_item" :class="{ '_item-active': item.menu_id === activeLevel1 }"
            v-for="(item, index) in menu_level1" :key="index" @click="eventCallClickMenu(item.menu_id, item.menu_name)">
            <span :class="['iconfont', item.menu_icon_url]"></span>
            <div :class="flagWrapActivate ? 'activeMenu' : ''">
              {{ item.menu_name }}
            </div>
          </div>
        </div>
        <div class="border" v-if="menu_func && menu_func.length"></div>
        <div class="menu_list" v-if="menu_func && menu_func.length">
          <div :class="{ '_item-active': item.menu_id === activeLevel1 }" class="_item"
            v-for="(item, index) in menu_func" :key="index" @click="goRoute(item)">
            <span :class="['iconfont', item.menu_icon_url]"></span>
            <div :class="flagWrapActivate ? 'activeMenu' : ''">
              {{ item.menu_name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="left_menu" v-show="levelMenusActive && allTreeDataLIst && allTreeDataLIst.length">
      <div class="menus_title">
        <span class="title">{{ title || "账号管理" }}</span>
        <span class="iconfont icon-daohangguanbi" @click="closeLevelMenus"></span>
      </div>
      <div class="menus_container">
        <div v-for="(item, index) in allTreeDataLIst" :key="index" class="menus_wrap" v-show="item.children.length">
          <div class="warp_title">{{ item.menu_name }}</div>
          <div class="menus_route">
            <span v-for="(ele, i) in item.children" @click="toRouter(ele.request_address)" :key="i">{{ ele.menu_name
              }}</span>
          </div>
        </div>
      </div>
    </div>
    <div :class="['toggle', !flagWrapActivate ? 'activeWidth' : '']" @click="toggle">
      <span :class="[
        'iconfont',
        flagWrapActivate ? 'icon-daohangshouqi_1' : 'icon-daohangzhankai1',
      ]"></span>
      <span v-show="flagWrapActivate">收起</span>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
export default Vue.extend({
  name: "jlkAside",
  data() {
    return {
      activeLevel1: "",
      activeLevel3: "",
      treeData: [],
      allTreeDataLIst: [],
      openeds: ["0", "1", "2", "3", "4", "5", "6", "7"],
      levelMenusActive: false,
      title: "",
      member_info_version: "",
    };
  },
  created() {
    this.initComponent();
  },
  computed: {
    ...mapState({
      flagWrapActivate: (state) => state.app.sidebar,
    }),
    ...mapGetters(["menu_level1", "menu_level2", "menu_level3", "menu_func"]),
  },
  methods: {
    // 功能菜单的外链跳转
    async goRoute(item) {
      this.activeLevel1 = item.menu_id;
      console.log(this.activeLevel1);
      if (item.menu_type == 3) {
        window.open(item.request_address, "_blank");
      }
      if (item.menu_type == 2) {
        let url =
          item.request_address.indexOf("?") > -1
            ? item.request_address.split("?")[0]
            : item.request_address;
        const service_id =
          item.request_address.indexOf("?") > -1
            ? item.request_address.split("?")[1].indexOf("=")
              ? item.request_address.split("?")[1].split("=")[1]
              : item.request_address
            : item.request_address;
        if (!url) return this.$JkMessage("菜单地址配置有误", "warning");
        if (!service_id)
          return this.$JkMessage("请求地址没有配置服务id", "warning");
        const ret = await this.$API.checkOpenService({
          productVal: service_id,
        });

        if (ret?.ok && ret?.data) {
          this.$router.push(url);
        } else {
          this.$router.push({
            path: "/available",
            query: {
              title: item.menu_name,
            },
          });
        }
      }
    },
    initComponent() {
      this.dataHandle();
    },
    toggle() {
      this.$store.dispatch("app/setSideBarAction", !this.flagWrapActivate);
    },
    closeLevelMenus() {
      this.levelMenusActive = false;
    },
    toRouter(path) {
      this.activeLevel3 = path;
      this.$router.push({
        path
      });
    },
    async dataHandle() {
      let menuLevel2 = JSON.parse(JSON.stringify(this.menu_level2));
      const menuLevel3 = JSON.parse(JSON.stringify(this.menu_level3));
      // gensit
      const obj = menuLevel2.map((res1) => {
        res1.children = menuLevel3.filter((res2) => {
          return res2.parent_menu_id === res1.menu_id;
        });
        return res1;
      });
      this.allTreeDataLIst = obj;
      this.allData = obj;
    },
    async eventCallClickMenu(e, name) {
      this.title = name;
      try {
        this.activeLevel1 = e;
        const allData = JSON.parse(JSON.stringify(this.allData));
        this.allTreeDataLIst = allData.filter(
          (res) => res.parent_menu_id === e
        );
        if (name == "分析报表") {
          const member_info = await this.$store.dispatch("getMemberInfoStore");
          //v1版本不显示此菜单
          if (Number(member_info.vip_interface_version) <= 1) {
            this.allTreeDataLIst = this.allTreeDataLIst.filter(
              (ele) => ele.menu_name != "概况"
            );
          }
        }
        this.levelMenusActive = true;
      } catch (e) {
        this.allTreeDataLIst.length = 0;
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.wrapActivate {
  width: 180px !important;
}

.left_menu {
  height: 100%;
  width: 460px;
  background: #fff;
  box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.05);
  position: absolute;
  right: -460px;
  top: 0px;
  z-index: 10000;

  .menus_title {
    @include flexContent(center, space-between);
    padding: 8px 15px;
    border-bottom: 1px solid #f2f2f2;

    .title {
      @include mixin-font_14_4($secondary-color, PingFang SC);
      font-weight: bold;
    }

    .iconfont {
      color: #cccccc;
      cursor: pointer;
    }
  }

  .menus_container {
    padding: 14px 15px;
    overflow-x: hidden;
    height: 100%;
    box-sizing: border-box;

    .menus_wrap {
      width: 430px;
      background: #f8f8f8;
      opacity: 1;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 15px 15px 0px 15px;
      margin-bottom: 20px;

      .warp_title {
        @include mixin-font_14_4($secondary-color, PingFang SC);
        font-weight: bold;
        margin-bottom: 10px;
      }

      .menus_route {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        span {
          @include mixin-font_14_4($secondary-color, PingFang SC);
          color: #666;
          margin: 0 15px 15px 0px;
          cursor: pointer;

          &:hover {
            color: $primary_color;
          }

          &::after {
            content: "";
            display: inline-block;
            width: 1px;
            height: 11px;
            background: #e1e1e1;
            margin-left: 15px;
          }
        }
      }
    }
  }
}

.toggle {
  width: 65px;
  height: 30px;
  background: $primary_color;
  opacity: 1;
  border-radius: 4px;
  position: fixed;
  bottom: 20px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
  transition: all 0.5s;
  cursor: pointer;
  left: 58px;

  .iconfont {
    font-size: 12px;
  }

  .icon-daohangshouqi_1 {
    margin-right: 4px;
  }
}

.activeWidth {
  width: 36px !important;
  left: 10px !important;
}

.wrap {
  background: #f6f8f7;
  transition: all 0.4s;
  height: 100%;
  width: 56px;
  display: flex;
  flex-direction: column;
  position: relative;

  .aside_top {
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;

    ._logo {
      width: 38px;
      height: 38px;

      img {
        width: 100%;
        height: 100%;
        background-size: none;
      }
    }
  }

  .menuList {
    flex: 1;
    width: 100%;
    padding: 15px 10px;
    box-sizing: border-box;

    .menu {
      width: 100%;
      height: 100%;
      z-index: 1111;

      .border {
        width: 100%;
        height: 1px;
        background: #f2f2f2;
        opacity: 1;
        margin: 15px 0px;
      }

      .menu_list {
        width: 100%;
        box-sizing: border-box;
        flex: 1;

        ._item {
          height: 32px;
          box-sizing: border-box;
          padding: 6px 10px;
          display: flex;
          align-items: center;
          color: #666666;
          font-size: 14px;
          border-radius: 2px;
          margin-bottom: 5px;

          &:hover {
            cursor: pointer;
            background: #eff2f1;
            font-weight: 400;
          }

          &:active {
            color: $primary_color;
            background: #e8ebea;
          }

          .iconfont {
            margin-right: 10px;
            width: 14px;
            height: 14px;
            line-height: 14px;
          }

          div {
            visibility: hidden;
            white-space: nowrap;
            opacity: 0;
            transition: all 0.3s;
          }

          .activeMenu {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }

    .menu2Level {
      position: absolute;
      z-index: -1;
      width: 126px;
      height: 100%;
      left: -70px;
      top: 0px;
      background: $grounding-color;
      transition: left 0.3s;
    }
  }
}

._item-active {
  color: $primary_color !important;
  background: #e8ebea;
}

.menu2LevelActivate {
  left: 136px !important;
  opacity: 1 !important;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  transition: opacity 0s;
  z-index: 2;
  overflow: hidden;
}

.menu2LevelActivateContainer {
  height: 100%;
  overflow-y: auto;

  .active-level-3 {
    color: $primary-color;
  }

  ::v-deep .el-tree {
    &>div {
      border-bottom: 1px solid $borderTest-color;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
  }

  ::v-deep .el-tree-node__content {
    height: 40px !important;
  }

  ::v-deep .el-tree-node__content {
    padding: 0px 10px !important;
    justify-content: space-between;
  }

  ::v-deep .el-tree-node__expand-icon.iconfont.icon-xiala {
    transform: rotate(-180deg);
    order: 2;
  }

  ::v-deep .expanded {
    transform: rotate(0) !important;
  }
}

.el-menu {
  height: 40px;
  line-height: 40px;

  ::v-deep .el-menu-item-group {
    .el-menu-item-group__title {
      display: none;
    }

    .el-menu-item {
      width: 100%;
      min-width: 0px;

      span {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }
  }

  .is-active {
    color: #2b2b2b;
  }

  ::v-deep .el-submenu__title {
    height: 40px;
    line-height: 40px;
    padding: 0px 15px !important;

    .el-icon-arrow-down {
      color: #2b2b2b;
      font-weight: bold;
    }
  }

  ::v-deep .el-menu-item {
    height: 40px;
    line-height: 40px;
    width: 100%;
    padding: 0px 15px !important;
    font-size: 14px;
    font-family: "PingFang Medium";
    font-weight: bold;
    color: #2b2b2b;
    opacity: 1;
  }

  ::v-deep .el-menu-item {
    height: 40px;
    line-height: 40px;
    width: 100%;
    padding: 0px 15px !important;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #2b2b2b;
    opacity: 1;
    min-width: 125px;
  }

  ::v-deep .el-menu-item:hover,
  ::v-deep .el-menu-item:focus {
    background-color: #fff !important;

    a {
      color: $primary-color !important;
    }
  }
}
</style>

import request from '@/utils/request'
export const queryMenu = (params = {}) => {
  return request({
    method: 'GET',
    url: '/api/menus',
    params
  })
}
export const querySingleMenu = id => {
  return request({
    method: 'GET',
    url: `/api/menus/${id}`
  })
}
export const addMenu = data => {
  return request({
    method: 'POST',
    url: '/api/menus',
    data
  })
}
export const changeMenu = (id, data) => {
  return request({
    method: 'PUT',
    url: '/api/menus/' + id,
    data
  })
}
export const queryOperate = data => {
  return request({
    method: 'GET',
    url: '/api/dictionaries/operate',
    data
  })
}
export const deleteMenu = data => {
  return request({
    method: 'PUT',
    url: '/api/menus',
    data
  })
}

<template>
  <div class="account_contain">
    <div class="header">
      <div class="header_flex">
        <div class="header_flex_center">
          <img src="https://jlk-sys-image.oss-cn-zhangjiakou.aliyuncs.com/202208151056534746160042807478817.png"
            class="logo-image" />
        </div>
        <div class="header_flex_title">
          <span>聚哒客商户中心 | 账号中心</span>
          <!-- <span style="line-height: 1.1; padding: 0 5px">|</span>
          <span>账号中心</span> -->
        </div>
      </div>

      <!-- 用户名字 -->
      <!-- <div class="header_right" @click.stop="toggleUserDialog">
        <div class="header_user_name">
          {{ userInfo.actual_name }}
        </div>
        <span class="iconfont icon-zhankai" style="transform: scale(0.8);font-size: 12px;"></span>
      </div> -->
      <!-- 用户弹窗 -->
      <transition leave-active-class="animate__animated animate__fadeOutUpBig">
        <NavbarDialog v-show="showUserDialog" />
      </transition>
    </div>
    <div class="content">
      <AccountMenu :activeLabel.sync="activeLabel" @tab="tabClick" :activeIndex.sync="activeIndex" />
      <div class="content_contain">
        <div class="content-header">{{ activeLabel }}</div>
        <AccountInfo v-if="activeIndex === 1" :initData="initData" @init="init" />
        <SafeSetting v-if="activeIndex === 2" :initData="initData" @getCurrentInit="init" />
      </div>
    </div>
  </div>
</template>

<script>
import bindWechat from "./components/bindWechat.vue"
import AccountMenu from "./components/AccountMenu.vue";
import AccountInfo from "./components/AccountInfo.vue";
import SafeSetting from "./components/SafeSetting.vue";
import NavbarDialog from "@/layout/components/JlkHeader/components/NavbarDialog.vue";
/**
 * 打开用户下拉
 */

export default {
  components: {
    AccountMenu,
    AccountInfo,
    SafeSetting,
    NavbarDialog
  },
  data() {
    return {
      activeIndex: 1,
      activeLabel: "账号资料",
      logo: "",
      isBind: false,
      showUserDialog: false,
      initData: {
        user_id: "",
        phone: "",
        actual_name: "",
        gender: "",
        email: "",
        id_card: ""
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    tabClick(index) {
      this.activeIndex = index
      if (index == 1) {
        this.init()
      }
    },
    async init() {
      return new Promise(async (resolve, reject) => {
        this.isBind = false
        const ret = await this.$API.getPersonalInfo()
        if (ret && ret.ok) {
          resolve()
          this.initData = ret.data
          this.isBind = !!ret.data.wechat_union_id;
          if (!this.isBind && this.$route.query.code) {  // 没有绑定且返回有对应的code
            this.handleBindWechat({ code: this.$route.query.code });
            this.activeIndex = 2
          }
        }
      })
    },
    async handleBindWechat(data) {
      const res = await this.$API.getQrcodeBind(data);
      this.$router.push({
        path: "/account",
        query: {}
      })
      if (res && res.ok) {
        this.$JlkMessage("绑定成功", "success");
        this.init()// 绑定成功后再次更新数据
      } else {
        this.isBind = true //  防止再次调用
        console.log(res)
        const tips = res.err && res.err.msg ? res.err.msg : ''
        if (!this.initData.wechat_union_id) {
          const title = '立即绑定'
          const comp = bindWechat
          this.$dialog(
            comp,
            {
              row: Object.assign(this.initData, { tips: tips }),
              ok: () => {
              },
            },
            {
              title: title,
              width: '700px',
              okText: "确定",
              footer: false
            }
          )
        }
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.account_contain {
  background: #f2f3f5;
  overflow: auto;
  height: 100%;

  .header {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 46px;
    padding: 0 15px 0px 30px;

    .header_flex {
      display: flex;

      .header_flex_center {
        width: 60px;
        height: 30px;
        display: flex;
        align-items: center;
        border-right: 1px solid #f2f2f2;

        img {
          height: 30px;
          width: 30px;
        }
      }
    }

    .header_flex_title {
      display: flex;
      align-items: center;
      font-size: #666666;
      font-weight: bold;
      padding-left: 20px;
      font-size: 14px;
      cursor: pointer;
    }

    .header_right {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px 15px;
      cursor: pointer;

      .header_user_name {
        font-weight: bold;
        font-size: 14px;
        margin-right: 5px;
        max-width: 115px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
    }
  }

  .content {
    height: calc(100% - 46px);
    overflow: auto;
    display: flex;
    justify-content: center;
    padding: 30px;
    box-sizing: border-box;

    .content_contain {
      margin-left: 30px;
      border-radius: 8px;
      background: #fff;
      max-width: 910px;
      min-width: 360px;
      width: 100%;
    }

    .content-header {
      font-weight: bold;
      font-size: 16px;
      padding: 20px 30px;
      border-bottom: 1px solid #f2f2f2;
    }
  }
}
</style>

import {
  filteParams
} from '@/utils/handleData.js'
import request from '@/utils/request'
//删除充值方案
export const delRechargeRule = (ID) => {
  return request({
    method: 'DELETE',
    url: `/api/recharge-rule/${ID}`,
  })
}
//获取单个充值方案
export const getRechargeRule = (id) => {
  return request({
    method: 'GET',
    url: `/api/recharge-rule/${id}`,
  })
}
//获取充值方案集合
export const rechargeRuleList = (params) => {
  return request({
    method: 'GET',
    url: '/api/recharge-rule',
    params: filteParams(params),
  })
}
//新增充值方案
export const addRechargeRule = (data) => {
  return request({
    method: 'POST',
    url: '/api/recharge-rule',
    data,
  })
}

//修改充值方案的状态
export const changeRechargeRule = (data) => {
  return request({
    url: `/api/recharge-rule`,
    method: 'PUT',
    data,
  })
}

// 获取充值概况
export const getChargeSituation = (params) => {
  return request({
    url: '/api/recharge-rule/general-situation',
    method: 'GET',
    params,
  })
}

// 操作充值方案
export const putChargeRule = (id, data) => {
  return request({
    url: `/api/recharge-rule/${id}`,
    method: 'PUT',
    data
  })
}

// 更新充值方案排序

export const sortCharge = (data) => {
  return request({
    url: '/api/recharge-rule/sort',
    method: "PUT",
    data
  })
}

//充值流水详情
export const getChargeSendDetail = (params) => {
  return request({
    url: '/api/recharge-rule/flow/recharge-send-detail',
    method: 'GET',
    params
  })
}
<template>
  <span class="jlk-button-text" :class="{ 'disabled': disabled }" @click="handleClick">{{ label }}</span>
</template>

<script>
export default {
  props: {
    label: {
      type: [String, Number],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick() {
      if (!this.disabled) {
        // 按钮操作处理函数
        this.$emit('click', {})
      }
    },
  }
}
</script>
<template>
  <div class="content_contain">
    <div class="content_header" v-if="title">
      <div class="header_left">
        {{ title }}
        <slot name="secondTitle" class="secondTitle"></slot>
      </div>
      <div class="header_right">
        <slot name="header_right"></slot>
      </div>
    </div>
    <div class="borders"></div>
    <div class="main">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: () => "",
    },
  },
};
</script>

<style lang="scss" scoped>
.content_contain {
  background: #fff;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding: 0px 25px;
  border: 1px solid #efefef;
  .content_header {
    display: flex;
    height: 52px;
    line-height: 52px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    color: #2b2b2b;
    font-size: 16px;
    .header_left {
      font-size: 16px;
      color: #2b2b2b;
      font-weight: bold;
    }
    .secondTitle {
      color: #666;
    }
  }
  .borders {
    width: 100%;
    height: 0.5px;
    background: #efefef;
  }
  .main {
    width: 100%;
    height: 100%;
  }
}
</style>
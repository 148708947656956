<template>
  <el-dialog
    :custom-class="`jlk-custom-dialog-style${configProps.centered ? ' jlk-custom-dialog-centered' : ''}${configProps.maxHeight ? ' jlk-custom-dialog-maxHeight' : ''}`"
    :width="configPropsWidth"
    v-dialogDrag="configProps.dialogDrag"
    :title="configProps.title"
    :visible.sync="visible"
    :modal="!configProps.dialogDrag"
    :lock-scroll="!configProps.dialogDrag"
    :append-to-body="true"
    :close-on-click-modal="configProps.maskClosable"
    :before-close="handleClose"
    :destroy-on-close="true"
    :modal-append-to-body="false"
    v-if="visible"
  >
    <div slot="title" class="el-dialog__title">
      {{ configProps.title }}
      <span v-if="configProps.secondaryText" :style="customStyle">{{
        configProps.secondaryText
      }}</span>
    </div>
    <conponents
      ref="_c"
      :is="content"
      v-bind="componentProps"
      @ok="handleOk"
      @close="handleClose"
    />
    <div v-if="configProps.footer" slot="footer" class="dialog-footer">
      <el-button
        v-if="configProps.showCancelButton"
        class="el-button__cancel"
        size="mini"
        @click="handleClose"
      >
        {{ configProps.cancelText }}
      </el-button>
      <el-button
        v-if="configProps.showConfirmButton"
        size="mini"
        type="primary"
        @click="handleOk"
      >
        {{ configProps.okText }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import directive from "./directives";
export default {
  data() {
    return {
      visible: false,
      // 组件内容
      content: "",
      // 组件传值内容
      componentProps: {},
      configProps: {
        // 弹框标题
        title: "",
        // 弹框宽度
        width: "600",

        // 点击遮罩层关闭
        maskClosable: false,
        // 居中显示弹框
        centered: true,
        // 确认按钮文本
        okText: "确定",
        // 取消按钮文本
        cancelText: "取消",
        // 开启弹框拖拽 ( 跟 显示遮罩层 | body滚动锁定 互斥 )
        dialogDrag: false,
        // 显示底部按钮
        footer: true,
        // 显示取消按钮
        showCancelButton: true,
        // 显示确定按钮
        showConfirmButton: true,
        // 标题后面的注释
        secondaryText: "",
        // 注释的样式
        customStyle: {},
        // 最大高度
        maxHeight: true,
      },
      customStyle: {
        color: "#666",
        fontSize: "16px",
        fontWeight: "normal",
        marginLeft: "10px",
        verticalAlign: "text-bottom",
      },
    };
  },
  directives: {
    ...directive,
  },
  computed: {
    configPropsWidth() {
      return this.configProps.width.indexOf("px") > -1
        ? this.configProps.width
        : this.configProps.width + "px";
    },
  },
  methods: {
    handleClose() {
      this.visible = false;
      if (this.componentProps.cancel) {
        this.componentProps.cancel && this.componentProps.cancel();
      }
    },
    /**
     * ok调用子组件的handleOk
     */
    handleOk(data) {
      if (this.$refs._c.handleOk) {
        this.$refs._c.handleOk().then((res) => {
          this.componentProps.ok && this.componentProps.ok(res);
          this.visible = false;
        });
      } else {
        this.componentProps.ok && this.componentProps.ok(data);
        this.visible = false;
      }
    },
  },
};
</script>

<style lang="scss">
.jlk-custom-dialog-centered {
  margin-top: 0 !important;
  top: 50%;
  transform: translate(0, -50%);
}
.jlk-custom-dialog-maxHeight{
    ::v-deep.el-dialog__body{
        max-height: 700px;
        overflow-y: auto;
    }
}
/* 根据需求修改弹框样式 */
.jlk-custom-dialog-style {
  .el-dialog__header {
    padding: 20px;
  }
  .el-dialog__body {
    padding: 0 20px 20px;
  }
  .el-dialog__headerbtn {
    right: 20px;
  }
}
</style>

<template>
  <div
    class="flexAlignCenter"
    style="justify-content: center; height: 100%; user-select: none"
  >
    <img
      src="https://jlk-sys-image.oss-cn-zhangjiakou.aliyuncs.com/202307101638294731561294640802902.png"
      alt=""
      width="800"
    />
  </div>
</template>

<script>
export default {
  mounted() {
    if (this.$route && this.$route.query && this.$route.query.merchant_id) {
      sessionStorage.setItem("merchant", JSON.stringify(this.$route.query));
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
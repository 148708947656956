<template>
  <div class="jlkNav">
    <div class="jlkNavContent">
      <div class="handleLeft">
        <div class="towardsLeft" @click="updateRoute()">
          <span class="iconfont icon-zuojiantou"></span>
        </div>
      </div>
      <div class="nav tags-view-container" ref="container">
        <div class="tags-view-wrapper" ref="wrapper" :style="{ left: clientWidth + 'px' }">
          <router-link v-for="(tag, index) in visitedViews" ref="tag" :key="index"
            :class="isActive(tag) ? 'active' : ''" :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
            tag="span" class="tags-view-item" @click.middle.native="!isAffix(tag) ? closeSelectedTag(tag) : ''">
            {{
              tag.meta.title
                ? tag.meta.title
                : decodeURIComponent(tag.query.title)
            }}
            <span v-if="!isAffix(tag) && tag.name != 'dashBoard'" style="margin-left: 6px" class="el-icon-close"
              @click.prevent="closeSelectedTag(tag)" />
          </router-link>
        </div>
      </div>
      <div class="handleRight" style="margin-right: 1px" @click="updateRoute('add')">
        <div class="towardsLeft">
          <span class="iconfont icon-youjiantoujinru"></span>
        </div>
      </div>
      <div class="handleRight" @click.stop="handleOpen">
        <div class="towardsLeft">
          <span class="iconfont icon-xiala"></span>
        </div>
      </div>
    </div>
    <ul v-show="rightMenuVisible" class="rightMenu">
      <li @click="closeCurrent">关闭当前标签</li>
      <li @click="closeAll">关闭全部标签</li>
      <li @click="closeOther" style="border-bottom: 1px solid #eaeaea">
        关闭其他标签
      </li>
      <li v-for="(item, index) in visitedViews" :key="index" @click="handleRightMenuVisible(false)">
        <router-link tag="span" :to="{ path: item.path, query: item.query, fullPath: item.fullPath }">{{ item.meta.title
          }}</router-link>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "jlkNav",
  data() {
    return {
      clientWidth: 0,
      visible: false,
    };
  },
  mounted() {
    this.addTags();
    this.updateClient();
    window.onresize = () => {
      //调用methods中的事件
      this.clientWidth = 0;
      this.updateClient();
    };
  },
  destroyed() {
    window.onresize = null;
  },
  computed: {
    ...mapState({
      rightMenuVisible: (state) => state.tagsView.rightMenuVisible,
    }),
    visitedViews() {
      return this.$store.state.tagsView.visitedViews;
    },
  },
  methods: {
    ...mapActions({
      handleRightMenuVisible: "tagsView/handleRightMenuVisible",
    }),
    handleOpen() {
      this.handleRightMenuVisible(!this.rightMenuVisible);
    },
    clearVislble() {
      this.visible = false;
    },
    closeAll() {
      const view = this.$route;
      this.$store.dispatch("tagsView/delAllViews").then(({ visitedViews }) => {
        this.toLastView(visitedViews, view);
        this.handleRightMenuVisible(false)
      });
      this.handleOpen();
    },
    closeOther() {
      this.$router.push(this.$route);
      this.handleOpen();
      this.$store.dispatch("tagsView/delOthersViews", this.$route).then(() => {
        this.moveToCurrentTag()
        this.handleRightMenuVisible(false)
      });
    },
    moveToCurrentTag() {
      const tags = this.$refs.tag;
      this.$nextTick(() => {
        for (const tag of tags) {
          if (tag.to.path === this.$route.path) {
            this.$refs.scrollPane.moveToTarget(tag);
            // when query is different then update
            if (tag.to.fullPath !== this.$route.fullPath) {
              this.$store.dispatch("tagsView/updateVisitedView", this.$route);
            }
            break;
          }
        }
      });
    },
    closeCurrent() {
      if (['dashBoard'].indexOf(this.$route.name) <= -1) {
        const view = this.$route
        this.$store
          .dispatch('tagsView/delView', view)
          .then(({ visitedViews }) => {
            if (this.isActive(view)) {
              this.toLastView(visitedViews, view)
            }
            this.handleRightMenuVisible(false)
          })
      }
    },
    toLastView(visitedViews) {
      const latestView = visitedViews.slice(-1)[0];
      if (latestView) {
        this.$router.push(latestView.fullPath);
      } else {
        this.$router.push("/");
      }
    },
    updateRoute(type) {
      let i = "";
      this.visitedViews.forEach((item, index) => {
        if (item.path === this.$route.path) {
          if (type === "add") {
            if (index === this.visitedViews.length - 1) {
              i = "";
            } else {
              i = index + 1;
            }
          } else {
            if (index === 0) {
              i = "";
            } else {
              i = index - 1;
            }
          }
        }
      });
      if (i !== "") {
        const obj = { ...this.visitedViews[i] };
        delete obj.name;
        this.$router.push(obj);
      }
    },
    updateClient() {
      this.$nextTick(() => {
        const totalClient =
          this.$refs.wrapper && this.$refs.wrapper.clientWidth; //导航栏总长度
        const containClient =
          this.$refs.container && this.$refs.container.clientWidth; // 容器的总长度
        const tags = document.querySelectorAll(".tags-view-item");
        var startPos = -this.clientWidth;
        var endPos = startPos + containClient;
        // 获取当前Menu的位置
        var menuStartPos = 0;
        var menuEndPos = 0;
        // 获取当前MenuIndex
        var menuIndex = this.visitedViews.indexOf(this.$route);
        // 矫正MunuPos
        for (let i = 0; i < menuIndex; i++) {
          console.log(
            "offsetWidth:",
            tags[i].offsetWidth,
            "clientWidth:",
            tags[i].clientWidth
          );
          menuStartPos += tags[i] ? tags[i].offsetWidth : 0 + 1;
        }
        menuEndPos = menuStartPos + tags[menuIndex] ? tags[menuIndex].offsetWidth : 0;
        // 计算是否需要偏移
        // 如果当前元素最后一个也不能满足导航栏宽度则修正为不偏移 解决在拖动窗体时没有修正界面问题
        if (
          totalClient <= containClient ||
          (menuIndex === this.visitedViews.length - 1 &&
            menuEndPos - startPos < endPos)
        ) {
          this.clientWidth = 0;
          startPos = 0;
          endPos = startPos + containClient;
        }
        if (menuEndPos > endPos) {
          this.clientWidth += endPos - menuEndPos;
        }
        if (menuStartPos < startPos) {
          this.clientWidth += startPos - menuStartPos;
        }
      });
    },
    eventCallClickTab(e) {
      this.$router.push(e.native);
    },
    isActive(route) {
      return route.path === this.$route.path;
    },
    isAffix(tag) {
      return tag.meta && tag.meta.affix;
    },
    closeSelectedTag(view) {
      if (view.path == "/dashBoard") return;
      this.$store
        .dispatch("tagsView/delView", view)
        .then(({ visitedViews }) => {
          if (this.isActive(view)) {
            this.toLastView(visitedViews, view);
          }
          this.updateClient();
        });
    },
    closeOthersTags() {
      this.$router.push(this.selectedTag);
      this.$store
        .dispatch("tagsView/delOthersViews", this.selectedTag)
        .then(() => {
          this.moveToCurrentTag();
        });
    },
    closeAllTags(view) {
      this.$store.dispatch("tagsView/delAllViews").then(({ visitedViews }) => {
        if (this.affixTags.some((tag) => tag.path === view.path)) {
          return;
        }
        this.toLastView(visitedViews, view);
      });
    },
    addTags() {
      const { name } = this.$route;
      if (name) {
        this.$store.dispatch("tagsView/addView", this.$route);
      }
      return false;
    },
  },
  watch: {
    $route() {
      this.addTags();
      this.updateClient();
    },
  },
};
</script>
<style lang="scss" scoped>
.jlkNav {
  height: 37px;
  width: 100%;
  position: relative;
  z-index: 1;

  .el-icon-close {
    width: 16px;
    height: 15px;
    line-height: 16px;
    text-align: center;
    font-size: 12px;
    color: $close-color;
    border-radius: 50%;
  }

  .jlkNavContent {
    cursor: pointer;
    height: 100%;
    display: flex;

    .handleLeft,
    .handleRight {
      height: 100%;
      width: 36px;

      .towardsLeft {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 6px rgba(68, 87, 207, 0.05);

        .iconfont {
          font-size: 12px;
          color: $borderTest-color;
        }
      }

      &:hover {
        background: $primary_color !important;
        color: #fff !important;
      }
    }

    .nav {
      flex: 1;
      background: $nav-color;
      display: flex;

      ._item {
        display: flex;
        align-items: center;
        margin-right: 1px;
        padding: 0 15px;
        background: $grounding-color;
        box-shadow: 0px 0px 6px rgba(68, 87, 207, 0.05);
        cursor: pointer;

        ._itemText {}

        .iconfont {
          transform: scale(0.5);
          font-size: 12px;
          margin-left: 15px;

          &:hover {
            opacity: 0.8;
          }
        }
      }

      .activeNav {
        background: $primary-color;
        box-shadow: 0px 0px 6px rgba(68, 87, 207, 0.05);
        color: $grounding-color;
      }
    }
  }
}

.tags-view-container {
  z-index: 101;
  width: 100%;
  height: 37px;
  box-sizing: border-box;
  overflow-y: hidden;
  white-space: nowrap;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  // border-bottom: 1px solid #d8dce5;
  // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
  .tags-view-wrapper {
    transition: all 0.3s;
    position: absolute;
    top: 0px;
    left: 0px;
    color: $hint-color;

    .tags-view-item {
      // width: 100px;
      margin-right: 1px;
      display: inline-block;
      position: relative;
      cursor: pointer;
      // height: 35px;
      background: #fff;
      opacity: 1;
      padding: 2px 15px;
      font-size: 14px;
      line-height: 35px;
      box-sizing: border-box;
      box-shadow: 0px 0px 6px rgba(68, 87, 207, 0.05);
      opacity: 1;
      border-radius: 0px;

      &:first-of-type {}

      &.active {
        background-color: $primary-color;
        color: #fff;
        border-color: $primary-color;

        .el-icon-close {
          background-color: #fff;
          color: $primary-color;
        }
      }

      &:hover {
        .el-icon-close {
          background-color: $border-color;
          color: $hint-color;
        }
      }
    }
  }
}

.rightMenu {
  position: absolute;
  right: 10px;
  top: 42px;
  width: 152px;
  cursor: pointer;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
  background: #fff;
  border-radius: 6px;
  max-height: 325px;
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;

  li {
    width: 152px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: $hint-color;
    background: #fff;
    box-sizing: border-box;
    padding: 0px 15px;
    text-align: left;

    span {
      display: inline-block;
      width: 100%;
    }
  }

  li:hover {
    background: #f8f8f9;
  }
}
</style>

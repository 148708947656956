import request from "@/utils/request";
import { filteParams } from "@/utils/handleData.js";

// 登录
export const getPwdLogin = (data) => {
  return request({
    method: "post",
    url: "/api/merc/personal/pwd",
    data,
  });
};

// 登出
export const getLogout = () => {
  return request({
    method: "DELETE",
    url: "/api/merc/personal/logout",
  });
};

//  获取个人信息
export const getPersonalInfo = () => {
  return request({
    method: "GET",
    url: "/api/merc/personal/current",
  });
};

// 获取登录的权限
export const getPermissSource = () => {
  return request({
    method: "post",
    url: "/api/personal/permission-resources",
  });
};

//  更新个人信息
export const putPersonalInfo = (data) => {
  return request({
    method: "PUT",
    url: "/api/merc/personal/account/info",
    data,
  });
};

//  修改账户密码
export const putAccountPwd = (data) => {
  return request({
    method: "PUT",
    url: "/api/merc/personal/account/pwd",
    data,
  });
};

//  发送验证码
export const postSendCaptcha = (data) => {
  return request({
    method: "POST",
    url: "/api/merc/personal/identity/verify/captcha",
    data,
  });
};

//  检查验证码

export const postCheckCaptcha = (data) => {
  return request({
    method: "POST",
    url: "/api/merc/personal/identity/verify/captcha/check",
    data,
  });
};

// 发送新的手机号码
export const postSendNewCaptcha = (data) => {
  return request({
    method: "POST",
    url: "/api/merc/personal/phone/new/captcha",
    data,
  });
};

// 更新手机号码
export const updateMercPhone = (data) => {
  return request({
    method: "PUT",
    url: "/api/merc/personal/phone/change",
    data,
  });
};

// 忘记密码验证码

export const getForgetCaptcha = (data) => {
  return request({
    method: "POST",
    url: "/api/merc/personal/pwd/forget/captcha",
    data,
  });
};

// 忘记密码设置密码

export const getForgetPwd = (data) => {
  return request({
    method: "PUT",
    url: "/api/merc/personal/pwd/from/forget",
    data,
  });
};

export const getQrcodeBind = (data = {}) => {
  return request({
    method: "POST",
    url: "/api/merc/personal/bind/wx/qrcode",
    data,
  });
};

export const personalUnbindWechat = () => {
  return request({
    method: "DELETE",
    url: "/api/merc/personal/unbind/wx",
  });
};

// /api/users/base-account/{phone}
//  检测手机号
export const getCheckBaseAccount = (phone) => {
  return request({
    method: "GET",
    url: `/api/users/base-account/${phone}`,
  });
};

// 更换手机号

export const updateBasePhone = (data) => {
  return request({
    method: "PUT",
    url: `/api/users/base-account/phone`,
    data,
  });
};

// 获取登录验证码

export const getLoginCode = (data) => {
  return request({
    method: "POST",
    url: `/api/merc/personal/login/phone/captcha`,
    data,
  });
};

// 获取登录验证码

export const phoneLogin = (data) => {
  return request({
    method: "POST",
    url: `/api/merc/personal/login/phone`,
    data,
  });
};

// 扫码登录
export const ercodeLogin = (data) => {
  return request({
    method: "POST",
    url: "/api/merc/personal/login/wx/qrcode",
    data,
  });
};

// 刷新token

export const getMercRefreshToken = (data) => {
  return request({
    method: "PUT",
    url: "/api/merc/personal/refresh",
    data,
  });
};

import request from "@/utils/request";

export const login = (data) => {
  return request({
    method: "POST",
    url: "/api/personal/pw-login",
    data,
  });
};
export const queryUserList = (params) => {
  return request({
    method: "GET",
    url: "/api/users",
    params,
  });
};
export const handleUserList = (data) => {
  return request({
    method: "PUT",
    url: "/api/users",
    data,
  });
};
export const queryUserInfo = (id) => {
  return request({
    method: "GET",
    url: `/api/users/${id}`,
  });
};
export const queryUserRoles = (id) => {
  return request({
    method: "GET",
    url: `/api/users/${id}/roles`,
  });
};
export const settingUserRoles = (id, data) => {
  return request({
    method: "PUT",
    url: `/api/users/${id}/roles`,
    data,
  });
};
export const addUser = (data) => {
  return request({
    method: "POST",
    url: "/api/users",
    data,
  });
};
export const changeUser = (id, data) => {
  return request({
    method: "PUT",
    url: `/api/users/${id}`,
    data,
  });
};
export const unbindWechat = (id) => {
  return request({
    method: "PUT",
    url: `/api/users/${id}/unbind-wechat`,
  });
};
export const forgetPw = (data) => {
  return request({
    method: "post",
    url: "/api/sms/send",
    data,
  });
};
export const newPW = (data) => {
  return request({
    method: "put",
    url: "/api/personal/new-pw",
    data,
  });
};

export const getDictStore = (
  params = {
    type: 82,
  }
) => {
  return request({
    method: "get",
    url: "/api/dictionaries/branch",
    params,
  });
};

export const getScan = (params = {}) => {
  return request({
    method: "POST",
    url: "/api/personal/wx-login",
    params,
  });
};

export const getAccessToken = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/personal/wachat-access-token",
    params,
  });
};

export const getWachatPerson = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/personal/wachat-person",
    params,
  });
};

export const bindWechat = (data) => {
  return request({
    method: "PUT",
    url: "/api/personal/bind-wechat?code=" + data,
  });
};

export const getSysPay = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/system/sys-pay-system",
    params,
  });
};

export const setSys = (data) => {
  return request({
    method: "POST",
    url: "/api/system/sys-vippay-system",
    data,
  });
};

export const getSys = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/system",
    params,
  });
};

export const getMemberInfo = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/merchants/member",
    params,
  });
};

export const initSystem = (id, data) => {
  return request({
    method: "POST",
    url: "/api/sys-templates/initialize/" + id,
    data,
  });
};

export const smsLogin = (data) => {
  return request({
    method: "POST",
    url: "/api/personal/sms-login",
    data,
  });
};

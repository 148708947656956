<template>
  <div class="tab" :style="{ height: tabboxHeight }">
    <el-table
      ref="jlkTable"
      v-if="showTable"
      row-class-name="row-class-name"
      :span-method="cellMerge"
      sortable="custom"
      :show-summary="showSummary"
      :summary-method="getSummaries"
      :tree-props="tableTreeProps"
      @sort-change="sortChange"
      @cell-mouse-enter="enter"
      @cell-mouse-leave="leave"
      @cell-click="cellClick"
      @selection-change="handleSelectionChange"
      :data="tableData"
      :border="border"
      style="width: 100%"
      :header-cell-style="{ background: '#F3F3F5', color: '#2B2B2B' }"
      :height="tableHeight"
      :row-key="rowKey"
    >
      <el-table-column v-if="selection" type="selection" width="60" />
      <!-- 序号列 -->
      <el-table-column
        v-if="config.index"
        align="center"
        width="100"
        type="index"
        :index="1"
        :sortable="config.sort"
        :label="config.indexName || '步骤'"
      />
      <!-- 循环遍历表头展示数据 -->
      <template v-for="(item, index) in tableHead">
        <jlk-table-column :rows="item" v-if="!item.hide" :key="index">
          <template :slot="item.slot" slot-scope="scope"
            ><slot v-if="item.slot" :scope="scope.scope" :name="item.slot"
          /></template>
          <template v-if="item.children && item.children.length">
            <jlk-table-column
              v-for="(childRow, childIndex) in item.children"
              :key="childIndex + 'a'"
              :rows="childRow"
            >
              <template :slot="childRow.slot" slot-scope="scope1"
                ><slot
                  v-if="childRow.slot"
                  :scope="scope1.scope"
                  :name="childRow.slot"
              /></template>
            </jlk-table-column>
          </template>
        </jlk-table-column>
      </template>
    </el-table>
    <div class="pagination" v-if="showPagination" ref="pagination">
      <div>
        <slot name="paginationLeft"></slot>
      </div>
      <JlkPagination
        ref="myPaginationRef"
        :formPage="formPage"
        :total="total"
        @change="handlePaginationChange"
      />
    </div>
  </div>
</template>
<script>
import jlkTableColumn from "./jlkTableColumn";
export default {
  props: {
    rowKey: {
      type: String,
      default: () => "id",
    },
    // table子集
    tableTreeProps: {
      type: Object,
      default: () => {},
    },
    // 是否开启多选
    selection: {
      type: Boolean,
      default: false,
    },
    tableHead: {
      type: Array,
    },
    tableData: {
      type: Array,
    },
    config: {
      // 配置  其他table配置依次添加
      type: Object,
      default: () => {
        return {
          sort: false, // 是否需要多选
          index: false, // 是否需要序号
        };
      },
    },
    cellMerge: {
      default: () => {
        return null;
      },
    },
    formPages: {
      type: Object,
      default: () => {
        return {
          index: 1,
          size: 20,
        };
      },
    },
    tabboxHeight: {
      type: String,
      default: "",
    },
    border: {
      type: Boolean,
      default: true,
    },
    showSummary: {
      type: Boolean,
      default: false,
    },
    tableHeight: {
      // 可以监听屏幕高度获取。 // 高度
      default: null,
    },
    showPagination: {
      type: Boolean,
      default: false,
    },
    total: {
      type: [String, Number],
      default: 0,
    },
  },
  components: {
    jlkTableColumn,
  },
  data() {
    return {
      spanArr: [],
      pos: "",
      showTable: true,
      formPage: Object.assign({ index: 1, size: 20 }, this.formPages),
    };
  },
  activated() {
    this.showTable = false;
    this.$nextTick(() => {
      this.showTable = true;
    });
  },
  updated() {
    this.$nextTick(() => {
      this.$refs["jlkTable"] && this.$refs["jlkTable"].doLayout();
    });
  },
  methods: {
    handlePaginationChange(page) {
      this.formPage = page;
      this.$emit("changePage", page);
    },
    getSummaries(param) {
      const { columns, data } = param;
      var sums = [];
      if (!this.showSummary) return;
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计"; //这里就是显示你要写的啥名字,是合计还是汇总什么
          return;
        }
        this.tableHead.forEach((ele, i) => {
          var index = this.selection ? i + 1 : i;
          if (column.property == ele.prop && ele.summary) {
            sums[index] = ele.total;
          }
        });
      });
      return sums;
    },
    clearSelection() {
      this.$refs.jlkTable.clearSelection();
    },
    enter(row) {
      if (this.$refs["popover" + row.id]) {
        this.$refs["popover" + row.id].showPopper = true;
      }
    },
    leave(row) {
      if (this.$refs["popover" + row.id]) {
        this.$refs["popover" + row.id].showPopper = false;
      }
    },
    handleSelectionChange(row) {
      this.$emit("selectionChange", row);
    },
    cellClick(row, column, cell, event) {
      this.$emit("cellClick", row, column, cell, event);
    },
    sortChange(row) {
      this.$emit("sortChange", row);
    },
  },
};
</script>
<style lang="scss" scope>
.tab .el-table__fixed-right-patch {
  background: #f3f3f5;
}
.el-table {
  border-radius: 6px 6px 0 0;
}
.row-class-name td:last-child {
  border-right: none;
}
.pagination {
  padding: 0 15px;
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.myNote {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.jlk-pointer {
  cursor: pointer;
}
.el-table__footer-wrapper {
  .has-gutter {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 0px;
    color: #2b2b2b;
    .el-table__cell {
      background: #eeeeee !important;
    }
  }
}
.el-table__header-wrapper,
.el-table__fixed {
  th {
    background: rgba(248, 248, 248, 1) !important;
  }
}
.el-table__fixed {
  th {
    background: rgba(248, 248, 248, 1) !important;
  }
}
.el-table__fixed-footer-wrapper tbody td {
  background-color: #eee !important;
}
.el-table__body tr.hover-row > td {
  background: #f6f8f7 !important;
}
.el-table__body-wrapper {
  tr {
    color: #2b2b2b;
  }
}
.el-table__body tr.hover-row.current-row > td,
.el-table__body tr.hover-row.el-table__row--striped.current-row > td,
.el-table__body tr.hover-row.el-table__row--striped > td,
.el-table__body tr.hover-row > td {
  background: #f6f8f7 !important;
}

.el-table__footer-wrapper {
  .has-gutter {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 0px;
    color: #2b2b2b;
    td {
      background: #eeeeee !important;
    }
  }
}
</style>

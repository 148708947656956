<template>
  <div class="user-dialog-container" @click.stop>
    <div class="user_contain">
      <img v-if="userInfo && userInfo.wechat_avatar" class="user_img" :src="userInfo.wechat_avatar || ''" alt="" />
      <div v-else class="user_img_name">
        {{ userInfo && userInfo.actual_name ? (userInfo.actual_name).substring(0, 1) : "" }}
      </div>
      <div class="user_name">
        {{ userInfo && userInfo.actual_name ? userInfo.actual_name : '' }}
      </div>
      <div class="user_phone">
        {{ userInfo && userInfo.phone ? userInfo.phone : '' }}
      </div>
      <el-button class="account_btn" size="large" type="primary" round @click="handleAccount">管理账号</el-button>
    </div>
    <div class="dia-footer" @click="logout">退出登录</div>
  </div>
</template>

<script>
export default {
  props: {
    userInfo: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      defaultSettings: {
        version: "0.01",
      },
    };
  },
  methods: {
    handleAccount() {
      window.open("/account", "_blank");
    },
    // 获取右边侧边栏的用户信息
    refresh() {
      location.reload();
    },
    async logout() {
      const ret = await this.$API.getLogout()
      if (ret && ret.ok) {
        this.$store.dispatch("LOINGOUT");
      }
    },
    toPersonalMsg() {
      this.$router.push({
        name: "personalInformation",
      });
    },
    toChangePassword() {
      this.$router.push({ name: "changePassword" });
    },
    goDetail() {
      this.$router.push({
        path: "/system/serviceProvider/registrationInfo",
      });
      this.closeUserInfo();
    },
  },
};
</script>

<style lang="scss" scoped>
.user-dialog-container {
  width: 320px;
  box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  position: fixed;
  right: 0;
  top: 46px;
  margin: 5px 10px;
  transition: height 0.1s;
  z-index: 999;
  overflow: hidden;
  border-radius: 12px;
  box-sizing: border-box;

  .user_contain {
    padding: 30px 20px;
    flex-direction: column;
    display: flex;
    align-items: center;

    .user_img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }

    .user_img_name {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: #0ba976;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      font-weight: bold;
    }

    .user_name {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      padding-top: 15px;
    }

    .user_phone {
      text-align: center;
      color: #666666;
      font-size: 14px;
      padding-top: 10px;
    }

    .account_btn {
      margin-top: 15px;
      height: 40px;
      width: 200px;
    }
  }
}

.dia-footer {
  height: 50px;
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #666;
  border-top: 1px solid #f2f2f2;

  &:hover {
    background-color: #ff4a4a;
    color: #fff;
  }
}
</style>

<template>
  <div class="receivingCard">
    <div class="topContent">
      <div class="title">
        <span class="topMainTitle">{{ title }}</span>
      </div>
      <div class="topMainSum">
        {{ total }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    total: {
      type: [Number, String],
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.receivingCard {
  margin-right: 20px;
  height: 95px;
  width: 260px;
  padding: 15px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 6px;
  font-size: 16px;
  color: #666666;
  background: #f6f8f7;
  & > .topContent {
    .title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .iconfont.topIcon {
        height: 32px;
        font-size: 30px;
      }
      .topMainTitle{
          font-size: 16px;
          font-weight: 400;
      }
    }
    .topMainSum {
      font-size: 24px;
      color: $primary_color;
      font-weight: 800;
      height: 33px;
      margin-top: 10px;
      margin-bottom: 5px;
    }
  }
}
</style>

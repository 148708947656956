import request from "@/utils/request";

// 关键指标
export const getDataMarketKeyIndicators = (params = {}) =>
  request({
    url: "/api/reports/data-market/key-indicators",
    method: "GET",
    params,
  });

// 交易走势图
export const getDataMarketTrend = (params = {}) =>
  request({ url: "/api/reports/data-market/trend", method: "GET", params });

// 支付方式交易
export const getDataMarketPayWay = (params = {}) =>
  request({ url: "/api/reports/data-market/pay-way", method: "GET", params });

// 支付方式交易占比
export const getDataMarketPayWayPie = (params = {}) =>
  request({
    url: "/api/reports/data-market/pay-way-pie",
    method: "GET",
    params,
  });

// 商户交易排行
export const getMerchantTransactionRanking = (params = {}) =>
  request({
    url: "/api/reports/data-market/merchant-transaction-ranking",
    method: "GET",
    params,
  });

// 门店交易排行
export const getBranchTransactionRanking = (params = {}) =>
  request({
    url: "/api/reports/data-market/branch-transaction-ranking",
    method: "GET",
    params,
  });

// 交易流水
export const getTransactionFlow = (params = {}) =>
  request({ url: "/api/reports/transaction-data/flow", method: "GET", params });

// 交易流水详情
export const getTransactionFlowDetail = (params = {}) =>
  request({
    url: "/api/reports/transaction-data/order",
    method: "GET",
    params,
  });

// 交易流水导出
export const getTransactionFlowExport = (params = {}) =>
  request({
    url: "/api/reports/transaction-data/export",
    method: "GET",
    params,
  });

// 结算报表
export const getSettlementreport = (params = {}) =>
  request({
    url: "/api/reports/settlement",
    method: "GET",
    params,
  });

// 结算报表导出
export const getSettlementreportExport = (params = {}) =>
  request({ url: "/api/reports/settlement/export", method: "GET", params });

// 刷新结算单状态
export const refreshsettlementstatus = (data = {}) =>
  request({
    url: "/api/reports/settlement/refresh-status",
    method: "POST",
    data,
  });

// 获取服务商信息
export const getgradeprivilege = (data = {}) =>
  request({
    url: "/api/serviceregister/getgradeprivilege",
    method: "POST",
    data,
  });

// 所属门店
export const getBranchesBelong = (params) =>
  request({ url: "/api/branches/belong", method: "GET",  params });

// 获取服务商信息
export const getServiceProvider = (params) =>
  request({
    url: "/api/service-registers/service-provider",
    method: "GET",
    params,
  });
// 获取注册信息
export const getRegisterInfo = (params) =>
  request({
    url: "/api/service-registers/register-info",
    method: "GET",
    params,
  });

// 角色用户关联列表
export const relationUsers = (id, params = {}) =>
  request({ url: `/api/roles/${id}/relation-users`, method: "GET", params });

// 获取商户用户下的通道集合
export const getMerchantUserPasses = (params = {}) =>
  request({
    url: "/api/dictionaries/merchant-user-passes",
    method: "GET",
    params,
  });

// 获取商户用户下的商户集合
export const getAllMerhcant = (params = {}) =>
  request({ url: "/api/dictionaries/all-merchant", method: "GET", params });

// 交易状态字典
export const getDicPayOrderStatus = (params = {}) =>
  request({ url: "/api/dictionaries/pay-order-status", method: "GET", params });

// 交易终端字典
export const getDicPayClient = (params = {}) =>
  request({ url: "/api/dictionaries/pay-client", method: "GET", params });

// 交易渠道字典
export const getDicTranPayType = (params = {}) =>
  request({ url: "/api/dictionaries/tran-pay-type", method: "GET", params });

// 获取门店字典
export const getDicBranches = (params = {}) =>
  request({ url: "/api/dictionaries/branches", method: "GET", params });

// 获取商户字典集合
export const getDicMerchants = (params = {}) =>
  request({ url: "/api/dictionaries/merchants", method: "GET", params });

// 导出直连报表
// export-direct-bills
export const exportDirectBill = (params = {}) =>
  request({
    url: "/api/reports/transaction-data/export-direct-bills",
    method: "GET",
    params,
  });

// 直连结算

export const getDirectSettlement = (params = {}) =>
  request({
    url: "/api/reports/transaction-data/direct-bills",
    method: "get",
    params,
  });

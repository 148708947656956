import request from '@/utils/request'
export const getOfficialAccounts = (params = {}) => {
  return request({
    method: 'GET',
    url: '/api/official-accounts',
    params
  })
}
export const getOfficialDetail = id => {
  return request({
    method: 'POST',
    url: `/api/official-accounts/authorizer-info`
  })
}
export const getPermissionsList = (id = {}) => {
  return request({
    method: 'GET',
    url: `/api/official-accounts/officialaccount-permissions${id}`
  })
}

export const getAccountMenus = (params = {}) => {
  return request({
    method: 'GET',
    url: '/api/wechat-official-accounts/menus',
    params
  })
}

export const addAccountMenus = (params = {}) => {
  return request({
    method: 'POST',
    url: '/api/wechat-official-accounts/menus',
    params
  })
}
// 获取公众号模板消息发送日志
export const templateMsgLogs = (params = {}) => {
  return request({
    method: 'GET',
    url: '/api/template-msgs/logs',
    params
  })
}

// 获取公众号模板库（我的模板）
export const templateMsgDic = (params = {}) => {
  return request({
    method: 'GET',
    url: '/api/template-msgs',
    params
  })
}

// 设置所属行业
export const industry = (data = {}) => {
  return request({
    method: 'PUT',
    url: '/api/template-msgs/industry',
    data
  })
}

// 获取已设置的行业
export const getIndustry = (params = {}) => {
  return request({
    method: 'GET',
    url: '/api/wechat-official-accounts/industry',
    params
  })
}
// 删除模板
export const delIndustry = (params = {}) => {
  return request({
    method: 'DELETE',
    url: '/api/wechat-official-accounts/template-msg',
    params
  })
}
// 重发消息
export const resend = id => {
  return request({
    method: 'POST',
    url: `/api/template-msgs/${id}/resend`
  })
}

// 操作消息模板
export const operationsTemplate = (data = {}) => {
  return request({
    method: 'PUT',
    url: '/api/template-msgs',
    data
  })
}
// 获取所有的小程序列表
export const getAllApplets = (id = {}) => {
  return request({
    method: 'GET',
    url: `/api/dictionaries/applets`
  })
}
// 获取公众号模板库
export const publicTemplate = (params = {}) => {
  return request({
    method: 'GET',
    url: '/api/template-msgs/public',
    params
  })
}

// 新增菜单
export const addMenus = (params = {}, id) => {
  return request({
    method: 'POST',
    url: `/api/wechat-official-accounts/menus?appId=${id}`,
    data: params
  })
}

export const delAccount = (params = {}) => {
  return request({
    method: 'PUT',
    url: '/api/official-accounts',
    data: params
  })
}

// 查询商户列表
export const getMechantList = (params = {}) => {
  return request({
    method: 'GET',
    url: '/api/merchant',
    params
  })
}

export const addAccount = (params = {}) => {
  return request({
    method: 'POST',
    url: '/api/official-accounts',
    data: params
  })
}

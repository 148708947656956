<template>
  <div class="wrap top_cards" ref="jlkContentHeader" >
    <div class="up" v-if="$slots.upLeft || $slots.upRight">
      <div class="upLeft">
        <slot name="upLeft"></slot>
      </div>
      <div class="upRight">
        <slot name="upRight"></slot>
      </div>
    </div>
    <div class="center">
      <slot name="center"></slot>
    </div>
    <div class="down" v-if="$slots.downLeft || $slots.downRight">
      <div class="downLeft">
        <slot name="downLeft"></slot>
      </div>
      <div class="downRight">
        <slot name="downRight"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "jlkContentHeader",
  props: {
    fullScreen: {
      default: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  display: flex;
  flex-direction: column;
  .up {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    .upLeft {
      flex: 1;
      .contenTitle {
        display: flex;
        align-items: flex-end;
        @include mixin-font_18_b(#2b2b2b);
        .secondary {
          margin-left: 10px;
          @include mixin-font_18_b($hint-color);
        }
      }
    }
  }
  .down {
    min-height: 40px;
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    transition: all 0.5s;
    .downRight {
      cursor: pointer;
      display: flex;
      line-height: 20px;
      height: 100%;
      padding-right: 15px;
      &:hover {
        opacity: 0.8;
      }
      &:active {
        opacity: 1;
      }
      .fullScreen {
        display: flex;
        width: 60px;
        height: 40px;
        align-items: center;
      }
    }
    .downLeft {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      min-height: 40px;
      ::v-deep .el-select {
        input {
          border: none;
          background: #f6f8f7;
          height: 30px;
          line-height: 30px;
        }
        .el-input__inner:hover {
          background-color: $border-color;
        }
        .el-input__icon {
          line-height: 30px;
        }
      }
      .select-search ::v-deep {
        .el-input__inner:hover {
          background-color: #fff;
        }
        .el-input__inner:focus {
          border-color: $primary-color;
        }
        .el-input.is-disabled .el-input__inner {
          background-color: #f5f7fa;
        }
        input {
          background: #fff;
          border: 1px solid #dcdfe6;
        }
      }
      .el-dropdown {
        margin-right: 30px;
      }
      & > .el-input {
        height: 30px;
        width: 250px !important;
        ::v-deep input {
          height: 30px !important;
          line-height: 30px;
        }
      }
      ::v-deep .el-input.is-disabled .el-input__inner {
        background-color: #f2f2f2;
      }
      & > .el-date-editor {
        height: 30px;
        ::v-deep .el-input__icon {
          line-height: 1.8 !important;
        }
        ::v-deep .el-range-separator {
          line-height: 1.5 !important;
        }
      }
      button {
        @include mixin-font_14_4($secondary-color);
        height: 20px;
        line-height: 0px;
        &:active {
          opacity: 0.8;
        }
      }
    }
  }
}
.top_cards {
  .down {
    .downLeft {
      margin: 0;
    }
  }
}
</style>

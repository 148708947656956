import request from '@/utils/request'
// 获取登录日志
export const queryLoginLog = (params = {}) => {
  return request({
    method: 'GET',
    url: '/api/logs/login-logs',
    params
  })
}
// 获取操作日志
export const queryOperationLog = (params = {}) => {
  return request({
    method: 'GET',
    url: '/api/logs/operate-logs',
    params
  })
}

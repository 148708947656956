import request from '@/utils/request'
import { filteParams } from '@/utils/handleData.js'

export const getBranchLists = (params = {}) => {
  return request({
    method: 'GET',
    url: '/api/item/brand-info',
    params:filteParams(params),
  })
}

export const getClassLists = (params = {}) => {
  return request({
    method: 'GET',
    url: '/api/item/category-info',
    params:filteParams(params),
  })
}

export const getGoodsLists = (params = {}) => {
  return request({
    method: 'GET',
    url: '/api/item/item-info',
    params:filteParams(params),
  })
}

export const getClassTree = (params = {}) => {
  return request({
    method: 'get',
    url: '/api/item/category-info-tree',
    params:filteParams(params),
  })
}

export const getBrandTree = (params = {}) => {
  return request({
    method: 'get',
    url: '/api/item/brand-info-tree',
    params:filteParams(params),
  })
}

export const asyncGoodsInfo = (data = {}) => {
  return request({
    method: 'POST',
    url: '/api/item/send-item-sync',
    data,
  })
}

export const asyncClassInfo = (data = {}) => {
  return request({
    method: 'POST',
    url: '/api/item/send-cls-sync',
    data,
  })
}

export const asyncBranchInfo = (data = {}) => {
  return request({
    method: 'POST',
    url: '/api/item/send-brand-sync',
    data,
  })
}

<template>
  <div
    class="merchantCenterContainer"
    style="
      justify-content: center;
      height: 100%;
      user-select: none;
      position: relative;
      min-width: 360px;
    "
  >
    <img
      src="https://jlk-sys-image.oss-cn-zhangjiakou.aliyuncs.com/202208291002335202188926667207957.png"
      alt=""
      width="800"
    />
    <div class="wechat_apple">
      <ul class="ul_apple">
        <li class="li_apple" style="margin-bottom: 50px">
          <img
            src="https://jlk-sys-image.oss-cn-zhangjiakou.aliyuncs.com/202304131423035081975427430874938.png"
            alt=""
          />  
          <p class="merchant_tips">
            <span class="title">聚客掌柜商户端</span>
            <span
              >提供移动支付流水、结算报
              表、商户汇总、员工管理等功能，扫码直接使用！</span
            >
          </p>
        </li>
        <li class="li_apple">
          <img
            src="https://jlk-sys-image.oss-cn-zhangjiakou.aliyuncs.com/202208311506204670145879907196773.png"
            alt=""
          />
          <p>
            <span class="title">来客掌柜</span>
            <span
              >移动老板助手，提供门店销售流水、收银对账、商品调价，商品库存等功能，随时随地了解门店实际情况，开通请联系您的服务商！</span
            >
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.ul_apple {
  .li_apple {
    position: relative;
    &:hover p {
      display: flex !important;
    }
  }
  img {
    width: 240px;
    height: 240px;
    cursor: pointer;
  }
  p {
    position: absolute;
    bottom: 3px;
    left: 0px;
    width: 240px;
    height: 240px;
    box-sizing: border-box;
    padding: 0px 20px;
    background: rgba(102, 102, 102, 1);
    display: none;
    flex-direction: column;
    justify-content: center;
    border-radius: 4px;
    span {
      width: 100%;
      color: #fff;
      display: inline-block;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
    }
    .title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
}
.wechat_apple {
  position: absolute;
  right: 100px;
  top: 50%;
  transform: translate(0px, -50%);
}
.merchantCenterContainer {
  display: flex;
  align-items: center;
  // padding: 100px 580px 0px 360px;
}
</style>
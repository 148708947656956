export function hasPermission(permission, perms) {
  let hasPermission = false
  for (var i = 0; i < permission.length; i++) {
    if (
      permission[i].permission_value.includes(perms) &&
      permission[i].operate
    ) {
      hasPermission = true
      break
    }
  }
  return hasPermission
}

import {
    sortKey
} from '@/utils/handleData'
const getters = {
    menu_level1: (state) => {
        console.log(sessionStorage.getItem('isVip'))
        console.log('--------')
        const isVip = sessionStorage.getItem('isVip') === 'true' ? 'merchant_center_permissions' : 'vip_permissions'
        return state.user.user[isVip].filter((item) => item.menu_type == 1 && item.menu_level === 1).sort(sortKey('sort_code'))
    },
    menu_level2: (state) => {
        const isVip = sessionStorage.getItem('isVip') === 'true' ? 'merchant_center_permissions' : 'vip_permissions'
        return state.user.user[isVip].filter((item) => item.menu_level === 2).sort(sortKey('sort_code'))
    },
    menu_level3: (state) => {
        const isVip = sessionStorage.getItem('isVip') === 'true' ? 'merchant_center_permissions' : 'vip_permissions'
        return state.user.user[isVip].filter((item) => item.menu_level === 3).sort(sortKey('sort_code'))
    },
    menu_func: (state) => {
        const isVip = sessionStorage.getItem('isVip') === 'true' ? 'merchant_center_permissions' : 'vip_permissions'
        return state.user.user[isVip].filter((item) => item.menu_type != 1 && item.menu_level === 1).sort(sortKey('sort_code'))
    },

}
export default getters
<template>
  <div class="subjectContainer">
    <div class="subjectContent">
      <div class="contain">
        <div class="fontcolortitle">
          {{ title }}
          <slot name="secondText"></slot>
        </div>
        <div class="fontcolorcontent">
          <slot name="action"></slot>
        </div>
      </div>
    </div>
    <div class="subbottom">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.subjectContainer {
  background: #ffffff;
  border: 1px solid #e1e1e1;
  opacity: 1;
  border-radius: 4px;
  padding: 0px 15px;
  margin-bottom: 15px;
  .subjectContent {
    height: 52px;
    line-height: 52px;
    border-bottom: 1px solid #f2f2f2;
  }
  .subbottom {
    padding: 20px 0px 35px;
  }
  .contain {
    display: flex;
    justify-content: space-between;
  }
}
</style>

<template>
  <div style="height: 100%;">
    <component :is="isComponent"></component>
  </div>
</template>

<script>
import merchantCenterHome from "./components/merchantCenterHome.vue";
import miniMemberHome from "./components/miniMemberHome.vue";

export default {
  name: "dashboard",
  data() {
    return {
      isComponent:
        sessionStorage.getItem("isVip") === "false"
          ? miniMemberHome
          : merchantCenterHome,
    };
  },
};
</script>

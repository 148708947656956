<template>
  <div class="queryTable" ref="queryTable">
    <JlkContentHeader v-if="showHeader" ref="JlkContentHeader">
      <template slot="upLeft">
        <slot name="upleft"> </slot>
      </template>
      <template slot="upRight">
        <slot name="upRight"> </slot>
        <div
          class="btn_span"
          @click="toggle"
          v-if="
            headerQueryList &&
            headerQueryList.length > 0 &&
            $slots.upleft &&
            showOpened
          "
        >
          <span>{{ opened ? "展开" : "收起" }}</span>
          <span
            :class="['iconfont', opened ? 'icon-zhankai' : 'icon-ziyuan197']"
          ></span>
        </div>
      </template>
      <template slot="center" v-if="!opened">
        <slot name="center"></slot>
      </template>
      <template
        slot="downLeft"
        v-if="headerQueryList && headerQueryList.length > 0 && !opened"
      >
        <slot name="downLeft">
          <template v-for="(item, index) in headerQueryList">
            <el-select
              v-bind="{ ...item.bind }"
              class="select-search"
              style="
                width: 250px;
                margin-top: 5px;
                margin-bottom: 5px;
                margin-right: 10px;
              "
              v-model="queryForm[item.name]"
              v-if="item.type === 'select-filterable'"
              :key="index"
              clearable
              :multiple="item.multiple"
              collapse-tags
              filterable
              remote
              :disabled="item.disabled"
              :remote-method="(query) => remoteMethod(query, item)"
              :placeholder="item.placeholder"
              @change="handleSelectFilterable(item)"
            >
              <el-option
                v-for="(option, optionIndex) in item.optionList || []"
                :key="optionIndex"
                :label="option[item.optionTitle]"
                :value="option[item.optionKey]"
              >
              </el-option>
            </el-select>

            <el-select
              class="jlk-search"
              :style="{
                maxWidth: item.multiple && !item.width ? '220px' : 'auto',
                marginRight: '10px',
                width: item.width || '102px',
                marginTop: '5px',
                marginBottom: '5px',
              }"
              v-model="queryForm[item.name]"
              v-if="item.type === 'select'"
              :key="index"
              :clearable="
                item.hasOwnProperty('clearable') ? item.clearable : true
              "
              collapse-tags
              :multiple="item.multiple"
              :placeholder="item.placeholder"
              :class="['selectAll', item.multiple ? 'multiple' : '']"
            >
              <el-option
                v-for="(option, optionIndex) in item.optionList"
                :key="optionIndex"
                :label="option[item.optionTitle]"
                :value="option[item.optionKey]"
              >
              </el-option>
            </el-select>
            <number-range
              style="margin-top: 5px; margin-bottom: 5px; margin-right: 10px"
              v-else-if="item.type === 'numberRange'"
              :key="index"
              :prop="item.name"
              :fixed="item.fixed || 0"
              :queryForm="queryForm"
            />
            <input-group
              style="margin-top: 5px; margin-bottom: 5px; margin-right: 10px"
              v-else-if="item.type === 'inputGroup'"
              :key="index"
              :placeholder="item.placeholder"
              :prop="item.name"
              :queryForm="queryForm"
              :options="item.optionList"
              :propsConfig="{ value: item.optionKey, label: item.optionTitle }"
            />
            <el-input
              style="margin-top: 5px; margin-bottom: 5px; margin-right: 10px"
              v-model="queryForm[item.name]"
              v-else-if="item.type === 'input'"
              :key="index"
              clearable
              :placeholder="item.placeholder"
              :disabled="
                item.associatedField
                  ? !queryForm[item.associatedField]
                  : item.disabled || false
              "
              @input="checkValid(item)"
              @keyup.enter.native="eventCallSearchCondition"
              v-bind="{ ...item.bind }"
            ></el-input>
            <el-date-picker
              :type="item.isShowTime ? 'datetimerange' : 'daterange'"
              style="
                width: 275px;
                margin-top: 5px;
                margin-bottom: 5px;
                margin-right: 10px;
              "
              :style="{ width: item.isShowTime ? '380px' : '275px' }"
              v-else-if="
                item.type === 'datetimerange' || item.type == 'daterange'
              "
              :key="index"
              :picker-options="pickerOptions(item)"
              v-model="defaultDateValue"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :clearable="
                item.hasOwnProperty('clearable') ? item.clearable : true
              "
              @change="dateChangePicker($event, item)"
              :default-time="['00:00:00', '23:59:59']"
              :format="
                item.isShowTime ? item.format || 'yyyy-MM-dd' : 'yyyy-MM-dd'
              "
              :value-format="item.format || 'yyyy-MM-dd'"
            >
            </el-date-picker>
            <el-date-picker
              style="
                width: 275px;
                margin-top: 5px;
                margin-bottom: 5px;
                margin-right: 10px;
              "
              v-else-if="item.type === 'date' || item.type === 'month'"
              v-model="queryForm[item.name]"
              :picker-options="pickerOptions(item)"
              :key="index"
              :type="item.type || 'date'"
              :format="item.format || 'yyyy-MM-dd'"
              :value-format="item.valueFormat || 'yyyy-MM-dd'"
              :placeholder="item.placeholder || '选择日期'"
            >
            </el-date-picker>
          </template>
          <el-button
            type="primary"
            size="mini"
            style="color: #fff; height: 30px; line-height: 0px"
            @click="eventCallSearchCondition"
            >搜索</el-button
          >
          <el-button
            style="line-height: 0px"
            type="text"
            @click="eventCallEmptySearch"
            >清空</el-button
          >
        </slot>
      </template>
      <template slot="downRight">
        <slot name="downRight"> </slot>
      </template>
      <!-- </slot> -->
    </JlkContentHeader>
    <div style="display: flex; justify-content: space-between">
      <div class="treeContent" v-if="hasTree">
        <slot name="tree_search">
          <el-select
            style="width: 100%; height: 46px; margin-bottom: 15px"
            v-model="queryForm[filterSel.name]"
            @change="filterTree"
            :disabled="filterSel.disabled"
            v-if="hasFilterSel && filterSel"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in filterSel.data"
              :key="index"
              :value="item[filterSel.optionKey]"
              :label="item[filterSel.optionTitle]"
            ></el-option>
          </el-select>
        </slot>
        <el-tree
          class="showTree"
          :style="{
            height:
              (hasFilterSel && filterSel
                ? (tableHeight || tbodyHeight) - 61
                : tableHeight || tbodyHeight) + 'px',
            overflow: 'auto',
          }"
          :expand-on-click-node="false"
          :default-expanded-keys="expandDefault"
          :data="treedata"
          :props="treeProps"
          :node-key="treeQueryParamKey"
          @node-click="nodeClick"
          :load="loadNode"
          :lazy="lazy"
          ref="tree"
        >
        </el-tree>
      </div>
      <JlkTable
        ref="jlkTable"
        :style="{ width: hasTree ? 'calc(100% - 275px)' : '100%' }"
        :tableHead="tableHead"
        :tableData="tableData"
        :border="border"
        :tableHeight="tableHeight || tbodyHeight"
        :cellMerge="cellMerge"
        @sortChange="sortChange"
        :rowKey="rowKey"
        :tableTreeProps="tableTreeProps"
        :showSummary="showSummary"
        :selection="selection"
        @selectionChange="handleSelectionChange"
        @cellClick="cellClick"
      >
        <!-- <template v-for="item in tableHead">
          <template :slot="item.slot" slot-scope="scope">
            <slot :name="item.slot" :scope="scope.scope"></slot>
          </template>
          <template v-for="item in item.children || []" :slot="item.slot" slot-scope="scope">
            <slot :name="item.slot" :scope="scope.scope"></slot>
          </template>
        </template> -->
        <template
          v-for="item in tableHead"
          :slot="item.slot"
          slot-scope="scope"
        >
          <slot :name="item.slot" :scope="scope.scope"></slot>
        </template>
        <!-- <template slot="transaction_amt" slot-scope="scope">
          <slot name="transaction_amt" :scope="scope.scope"></slot>
        </template> -->
      </JlkTable>
    </div>
    <div class="pagination" v-if="showPagination" ref="pagination">
      <div>
        <slot name="paginationLeft"></slot>
      </div>
      <JlkPagination
        ref="myPaginationRef"
        :formPage.sync="formPage"
        :total="total"
        @change="handlePaginationChange"
      />
    </div>
  </div>
</template>
<script>
import { isArray } from "lodash";
import moment from "moment";
import numberRange from "./components/numberRange.vue";
import inputGroup from "./components/inputGroup.vue";
export default {
  name: "QueryTable",
  props: {
    tableTreeProps: {
      type: Object,
      default: () => {
        return { children: "children", hasChildren: "hasChildren" };
      },
    },
    rowKey: {
      type: String,
      default: () => "id",
    },
    // 是否开启多选
    selection: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    headerQueryList: {
      type: Array,
    },
    tableHead: {
      type: Array,
    },
    tableData: {
      type: Array,
    },
    pageSizes: {
      type: Array,
      default: () => {
        return [10, 20, 30, 50, 100];
      },
    },
    total: {
      type: Number,
      default: 0,
    },
    tableHeight: {
      // 可以监听屏幕高度获取。 // 高度
      default: 0,
    },
    filter: {
      type: Boolean,
      default: false,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    fullScreen: {
      type: Boolean,
      default: true,
    },
    // 表格边框
    border: {
      type: Boolean,
      default: true,
    },
    showSummary: {
      type: Boolean,
      default: false,
    },
    cellMerge: {
      type: Function,
    },
    queryTree: {
      //返回的query查询数据
      type: String,
      default: "",
    },
    showOpened: {
      type: Boolean,
      default: true,
    },
    hasTree: {
      default: false,
    },
    //左侧是否存在筛选菜单树的下拉框
    filterSel: {},
    //左侧筛选菜单树下拉框的数据
    hasFilterSel: {
      default: false,
    },
    //左侧菜单树的数据
    treedata: {},
    // tree是否懒加载
    lazy: {
      type: Boolean,
      default: true,
    },
    //点击菜单树用于查询的参数名
    treeQueryParamKey: {
      type: String,
    },
    // 默认选择中属性菜单节点
    deafultKey: {
      type: [String, Number],
    },
    //构建菜单树时，内部的一些参数
    treeProps: {
      default() {
        return {
          children: "children",
          label: "label",
        };
      },
    },
    // 点击el-tree是否需要查询对应表数据
    isClickSearch: {
      type: Boolean,
      default: () => true,
    },
  },
  components: {
    numberRange,
    inputGroup,
  },
  data() {
    return {
      tbodyHeight: 0,
      queryForm: {},
      formPage: {
        index: 1,
        size: 20,
      },
      resizeTimer: null,
      defaultDateValue: [],
      opened: false,
      expandDefault: [],
      initFlag: false,
    };
  },
  created() {
    this.initForm();
    this.integrationConditions();
  },
  watch: {
    deafultKey: function (newVal, oldVal) {
      if (newVal) {
        this.$nextTick(() => {
          document.querySelector(".el-tree-node__content").click();
        });
      }
    },
  },
  mounted() {
    window.removeEventListener("resize", this.onResize, true);
    this.getTableHeight();
    this.setResize();
    this.expandDefault.push(this.deafultKey);
    this.$bus.$on("eventBusGetHeight", (isFullScreen) => {
      if (isFullScreen) {
        this.formPage = {
          index: 1,
          size: 20,
        };
        this.integrationConditions();
      }
      this.getTableHeight();
    });
  },
  activated() {
    window.removeEventListener("resize", this.onResize, true);
    this.getTableHeight();
    this.setResize();
  },
  deactivated() {
    window.removeEventListener("resize", this.onResize, true);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize, true);
  },
  computed: {
    pickerOptions() {
      return function (item) {
        if (Object.prototype.hasOwnProperty.call(item, "pickerOptions")) {
          return item.pickerOptions;
        } else {
          return {
            disabledDate(time) {
              return time.getTime() > moment(Date.now()).endOf("day");
            },
          };
        }
      };
    },
  },
  methods: {
    toggle() {
      this.opened = !this.opened;
      this.getTableHeight();
    },
    /** 检查数字 */
    checkValid(item) {
      let g = this.queryForm[item.name];
      // 把非数字的都替换掉
      /// /[^\d]/g
      g = g.replace(item.reg, "");
      this.queryForm[item.name] = g;
    },
    remoteMethod(query, item) {
      item.remoteMethod(query, item).then((res) => {
        this.$set(item, "optionList", res);
      });
    },
    setResize() {
      window.addEventListener("resize", this.onResize);
    },
    getTableHeight() {
      this.$nextTick(() => {
        const queryTableHeight =
          (this.$refs.queryTable && this.$refs.queryTable.offsetHeight) || 0;
        const wrapHeight =
          (document.querySelector(".queryTable > .wrap") &&
            document.querySelector(".queryTable > .wrap").offsetHeight) ||
          0;
        const paginationHeight =
          (this.$refs.pagination && this.$refs.pagination.offsetHeight) || 0;
        console.log(
          queryTableHeight,
          wrapHeight,
          paginationHeight,
          queryTableHeight - wrapHeight - paginationHeight
        );
        this.tbodyHeight = queryTableHeight - wrapHeight - paginationHeight;
      });
    },
    onResize() {
      clearInterval(this.resizeTimer);
      this.resizeTimer = setInterval(() => {
        clearInterval(this.resizeTimer);
        this.resizeTimer = null;
        this.getTableHeight();
      }, 300);
    },
    // init form
    initForm() {
      this.queryForm = {};
      if (this.headerQueryList) {
        this.headerQueryList.map((item, index) => {
          if (item.type === "datetimerange" || item.type === "daterange") {
            this.defaultDateValue = item.defaultValue;
          }
          if (isArray(item.name) && !item.multiple) {
            item.name.forEach((val, index) => {
              this.$set(this.queryForm, val, item.defaultValue[index] || "");
            });
          } else {
            this.$set(this.queryForm, item.name, item.defaultValue);
          }
          if (item.type === "regionSelect") {
            this.$refs.JlkCascader && this.$refs.JlkCascader[0].reFormData();
          }
        });
        if (this.filterSel && this.filterSel.triggerSearch) {
          this.$set(
            this.queryForm,
            this.filterSel.name,
            this.filterSel.defaultValue || ""
          );
        }
      }
      if (this.$refs.tree) {
        this.$nextTick(() => {
          this.$refs.tree.setCurrentKey(this.deafultKey);
        });
      }
      this.$set(this.queryForm, this.queryTree, this.deafultKey);
    },
    // 改变页码  不会触发搜索事件
    changePage(page) {
      Object.assign(this.formPage, page);
    },
    handleCascader(data) {
      Object.assign(this.queryForm, data);
      this.eventCallSearchCondition();
    },
    //Tree顶部过滤tree事件
    filterTree() {
      this.queryForm[this.queryTree] = ""; // 切换时清楚除了默认参数之外的值
      if (this.filterSel.triggerSearch) {
        this.eventCallSearchCondition();
      }
      this.$emit("changeTreeData", this.queryForm[this.filterSel.name]);
    },
    handleSelectFilterable(row) {
      if (row.associatedField) {
        this.queryForm[row.associatedField] = "";
      }
      this.eventCallSearchCondition();
    },
    loadNode(node, resolve) {
      // 开始进来就来调用节点数据;点击加载tree的子集
      this.$emit("loadNode", node, resolve);
    },
    //左侧tree点击事件
    nodeClick(obj) {
      this.formPage = {
        index: 1,
        size: this.formPage.size,
      };
      this.$set(this.queryForm, this.queryTree, obj[this.treeQueryParamKey]);
      this.$emit("nodeClick", obj);
      // 是否需要关联查询列表
      if (this.isClickSearch) {
        this.integrationConditions();
      }
    },
    //改变当前点击的树节点的子节点
    setCurrentChildren(data) {
      this.$refs.tree.updateKeyChildren(this.queryForm[this.queryTree], data);
    },

    // 查询方法
    eventCallSearchCondition() {
      this.formPage = {
        index: 1,
        size: this.formPage.size,
      };
      this.integrationConditions();
    },
    // 清空条件

    eventCallEmptySearch() {
      this.initForm();
      this.eventCallSearchCondition();
    },
    // 调用外部查询方法
    externalSearchMethod(queryData, cb) {
      if (!this.initFlag) {
        this.initFlag = true;
        queryData = Object.keys(queryData).reduce(
          (previousValue, currentValue) => {
            if (queryData[currentValue] !== "") {
              this.$set(previousValue, currentValue, queryData[currentValue]);
            }
            return previousValue;
          },
          {}
        );
        this.$emit("searchMethod", queryData, cb);
        setTimeout(() => {
          // 防止多次调用
          this.initFlag = false;
        });
      }
    },
    // 分页改变事件
    handlePaginationChange(page) {
      this.$emit("changePage", page);
      this.formPage = page;
      this.integrationConditions();
    },
    // handleSizeChange(e) {
    //   this.$set(this.formPage, 'size', e)
    //   this.integrationConditions()
    // },
    // // 当前页面改变事件
    // handleCurrentChange(e) {
    //   this.$set(this.formPage, 'index', e)
    //   this.integrationConditions()
    // },
    // 整合分页条件与查询条件
    async integrationConditions(cb) {
      if (!this.showPagination) {
        this.formPage = {};
      }
      const queryForm = await Object.assign({}, this.queryForm, this.formPage);
      this.externalSearchMethod(queryForm, cb);
    },
    // 时间选择事件
    dateChangePicker(e, { name, format, isShowTime, defaultValue }) {
      if (!e) {
        e = defaultValue;
        this.defaultDateValue = defaultValue;
      }
      let startTime = "";
      let endTime = "";
      if (isShowTime) {
        startTime = e[0];
        endTime = e[1];
      } else {
        startTime =
          format === "yyyy-MM-dd HH:mm:ss" && e[0] ? e[0] + " 00:00:00" : e[0];
        endTime =
          format === "yyyy-MM-dd HH:mm:ss" && e[1] ? e[1] + " 23:59:59" : e[1];
      }
      this.queryForm[name[0]] = startTime;
      this.queryForm[name[1]] = endTime;
      this.integrationConditions();
    },
    handleSelectionChange(row) {
      this.$emit("selectionChange", row);
    },
    // 获取所有搜索条件
    getQueryParams() {
      return this.queryForm;
    },
    sortChange(row) {
      if (row.order === "ascending") {
        this.queryForm.sort = row.prop;
        this.queryForm.order = "asc";
      } else if (row.order === "descending") {
        this.queryForm.sort = row.prop;
        this.queryForm.order = "desc";
      } else {
        this.queryForm.sort = "";
        this.queryForm.order = "";
      }
      this.integrationConditions();
    },
    cellClick(row, column, cell, event) {
      this.$emit("cellClick", row, column, cell, event);
    },
    clearSelection() {
      this.$refs.jlkTable.clearSelection();
    },
  },
};
</script>
<style lang="scss" scoped>
.queryTable {
  height: 100%;

  ::v-deep.upRight {
    display: flex;
    align-items: center;
  }

  ::v-deep.down {
    margin: -5px 0 10px;
    transition: all 0.5s;
  }
}

.treeContent {
  ::v-deep.is-current {
    > .el-tree-node__content {
      .el-tree-node__label {
        color: #0ba976;
      }
    }
  }
}

.btn_span {
  width: 73px;
  height: 30px;
  background: #f2f2f2;
  opacity: 1;
  border-radius: 4px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  margin-left: 15px;

  span {
    @include mixin-font_14_4($secondary-color, PingFang SC);
  }

  .iconfont {
    font-size: 12px;
    margin-left: 5px;
  }
}

.treeContent {
  border: 1px solid #efefef;
  border-radius: 6px 6px 0px 0px;
  width: 260px;

  ::v-deep .el-select {
    .el-input__inner {
      background: #efefef;
      border: none;
    }
  }
}

.multiple {
  ::v-deep.el-select__tags {
    > span {
      display: flex;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.downLeft {
  .el-input--suffix {
    ::v-deep .el-input__suffix {
      display: flex;
      align-items: center;
    }
  }
}

::v-deep {
  .el-input__inner::placeholder,
  .el-date-editor .el-range-input::placeholder {
    color: #999;
  }
}

.jlk-search {
  ::v-deep .el-input__inner::placeholder {
    color: #666;
  }

  ::v-deep.el-input {
    width: 100%;

    .el-input__inner {
      width: 100%;
    }
  }
}

.pagination {
  padding: 0 15px;
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// .showTree {
//   padding: 10px 0;
// }
.select-search ::v-deep {
  .el-select__tags-text {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-width: 105px;
    font-size: 14px;
  }

  .el-tag__close.el-icon-close {
    top: -7px;
  }

  .el-select__input {
    background: none !important;
    border: none !important;
  }
}
</style>

<template>
  <div class="wrap">
    <div class="forgetPassPage">
      <div class="_title">
        <img src="https://jlk-sys-image.oss-cn-zhangjiakou.aliyuncs.com/logo.jpg" alt="聚来客LOGO" />
        <div>聚哒客·微会员</div>
      </div>
      <div class="_content">
        <div class="__container">
          <div class="___title">系统初始化</div>
          <div v-loading="loading" style="margin-top: 300px" v-if="initStatus"></div>
          <div style="text-align: center; font-size: 16px; margin-top: 360px" v-if="initStatus">
            正在分配系统资源，请耐心等待…
          </div>
          <div v-if="!initStatus" style="text-align: center; font-size: 18px; margin-top: 360px">
            <el-button type="text" size="mini" @click="rest">{{
              text
            }}</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      forgetPassForm: {},
      loading: true,
      initStatus: true,
      text: "系统初始化失败,请点击重试",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.initStatus = true;
      const merchant = sessionStorage.getItem("merchant")
        ? JSON.parse(sessionStorage.getItem("merchant"))
        : null;
      if (merchant && merchant.merchant_id) {
        const ret = await this.$API.initSystem(merchant.merchant_id);
        if (ret && ret.ok) {
          this.$confirm("系统化初始化完成,请重新登录~", "重新登录", {
            confirmButtonText: "重新登录",
            showCancelButton: false,
            showClose: false,
            closeOnPressEscape: false,
            closeOnClickModal: false,
          }).then((res) => {
            this.$router.push({
              name: "login",
            });
            sessionStorage.clear();
            localStorage.clear();
            location.reload();
            this.$JlkMessage("退出成功", "success");
          });
        }
      }
    },
    rest() {
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixin.scss";

.wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .level1 {
    width: 100%;
    height: 100%;

    .el-carousel {
      width: 100%;
      height: 100%;

      ::v-deep.el-carousel__container {
        width: 100%;
        height: 100% !important;
      }

      ::v-deep.el-carousel__indicators,
      .el-carousel__indicators--horizontal {
        position: absolute;
        bottom: 23% !important;
        transform: translate(-100%, 100%);

        .el-carousel__indicator {
          .el-carousel__button {
            opacity: 0;
            width: 8px;
            height: 8px;
            background: #fcfcfc;
            opacity: 0.5;
            border-radius: 6px;
            padding: 0;
            margin: 22px;
          }
        }

        .is-active {
          &>.el-carousel__button {
            width: 40px !important;
            height: 8px;
            background: #fcfcfc;
            opacity: 1;
            border-radius: 6px;
          }
        }
      }
    }

    .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 150px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }
  }

  .level2 {
    top: 0;
    left: 0;
    z-index: 4;
    position: absolute;
    width: 100%;
    height: 87%;

    .loginContainer {
      width: 400px;
      height: 480px;
      box-sizing: border-box;
      padding: 60px;
      background: $grounding-color;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 13%;
      box-shadow: 0px 10px 25px rgba(68, 87, 207, 0.1);
      opacity: 1;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .hoverBtn {
        width: 56px;
        height: 56px;
        position: absolute;
        right: 25px;
        top: 25px;
        border-radius: 30px;
        box-shadow: 0px 5px 10px rgba(68, 87, 207, 0.1);
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &>.iconfont {
          font-size: 22px;
          color: $primary-color;
        }
      }

      .login-form {
        .el-form-item {
          width: auto;

          .SMSDiv {
            display: inline-flex;
            width: 100%;

            .el-input {
              margin-right: 20px;
            }
          }
        }
      }

      .qRCode {
        height: 300px;

        .qrCodeLayout {
          display: flex;
          justify-content: center;
          height: 280px;

          ::v-deep .qrcode {
            width: 160px;
            height: 160px;
          }

          &>div {

            // width: 220px;
            // padding: 20px;
            // box-sizing: border-box;
            // margin: 20px;
            // box-shadow: 0px 5px 10px rgba(68, 87, 207, 0.05);
            // border-radius: 16px;
            // position: relative;
            .failureMask {
              position: absolute;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.5);
              top: 0;
              left: 0;
              border-radius: 16px;
              display: flex;
              justify-content: center;
              align-items: center;

              .el-button {
                padding: 5px 10px;
                font-size: 12px;
                color: #0ba976;
              }
            }
          }
        }
      }

      ::v-deep .el-form-item__label:before {
        content: "";
      }

      ::v-deep .el-form-item__label {
        margin-bottom: 0px;
        @include mixin-font_16_b($secondary-color);
      }

      ::v-deep .el-input__inner {
        border-radius: 0;
        border: 0;
        border-bottom: 1px solid $border-color;
        padding: 0;
        height: 41px;
        @include mixin-font_16_b($secondary-color);
      }

      .loginTitle {
        @include mixin-font_24_b($primary-color);
        margin-bottom: 20px;
      }

      .loginFooter {
        height: 128px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .loginAotu {

          // font-size: 12px;
          // color: #666;
          // margin-bottom: 25px;
          ::v-deep .el-checkbox__inner {
            background-color: #e1e1e1 !important;

            &:after {
              border: 1px solid #1777ff !important;
              border-left: 0 !important;
              border-top: 0 !important;
              // transform: rotate(45deg) scaleY(1);
            }

            .el-checkbox__label,
            .el-checkbox__input {
              @include mixin-font_14_4($hint-color);
            }
          }
        }

        button {
          @include mixin-btn(24px);
        }

        .loginFooterHandle {
          display: flex;
          justify-content: space-between;
          @include mixin-font_14_5($primary-color);

          .loginVerificationCode,
          .loginForgetPass {
            cursor: pointer;
          }
        }
      }
    }
  }

  .forgetPassPage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $sidebar-bg;
    display: flex;

    // align-items: center;
    ._title {
      height: 70px;
      background: $grounding-color;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
      display: flex;
      align-items: center;
      padding: 0 30px;

      img {
        width: 65px;
        height: 24px;
        margin-right: 16px;
      }

      div {
        @include mixin-font_18_8($secondary-color);
      }
    }

    ._content {
      flex: 1;
      padding: 20px 18%;

      .__container {
        width: 100%;
        height: 100%;
        background-color: $grounding-color;
        padding: 30px;
        box-sizing: border-box;

        .___title {
          height: 48px;
          background-color: $subtitle-color;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          @include mixin-font_20_b($forgetPass-color);
        }

        .forgetFormContainer {
          width: 350px;
          margin: 80px auto 0;

          .el-form {
            .el-form-item {
              width: 100%;

              ::v-deep.el-input__inner {
                height: 42px;
                // box-sizing: border-box;
              }
            }

            ::v-deep.el-form-item__content {
              display: flex;
              align-items: center;

              .codeGeting {
                background: #cdcdcd;
                border-color: #cdcdcd;
              }

              .verificationCodeBtn {
                width: 120px;
                margin-left: 20px;

                button {
                  @include mixin-font_16_4($grounding-color);
                }
              }

              .tip {
                position: absolute;
                top: 10px;
                left: 352px;
                width: 242px;
                @include mixin-font_14_4($hint-color);
              }

              button {
                @include mixin-btn(6px);
                @include mixin-font_20_5($grounding-color);
              }
            }
          }

          div {
            text-align: end;
            @include mixin-font_16_b($primary-color);
          }
        }

        .changePwdFinish {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 260px;
          justify-content: space-between;

          .icon-chenggong1 {
            font-size: 120px;
            color: #0ba976;
            line-height: 1;
          }

          .tipText {
            display: flex;
            flex-direction: column;
            height: 50px;
            justify-content: space-between;

            &>span:nth-of-type(1) {
              font-size: 20px;
              font-weight: bold;
              color: #2b2b2b;
            }

            &>span:nth-of-type(2) {
              color: #999999;

              a {
                color: #0ba976;
              }
            }
          }

          .toLoginBtn {
            width: 280px;
            border-radius: 24px;
            border-color: #0ba976;
            color: #0ba976;
          }
        }
      }
    }
  }
}

::-webkit-input-placeholder {
  /*Webkit browsers*/
  color: #000000 !important;
  font-size: 16px;
}

:-moz-placeholder {
  /*Mozilla Firefox 4 to 8*/
  color: #000000 !important;
  font-size: 16px;
}

::moz-placeholder {
  /*Mozilla Firefox 19+*/
  color: #000000 !important;
  font-size: 16px;
}

:-ms-input-placeholder {
  /*Internet Explorer 10+*/
  color: #000000 !important;
  font-size: 16px;
}

.qrCodeLayout {
  #weixin {
    color: red;

    html {
      color: red;
    }

    .impowerBox {
      color: red;

      .wrp_code {
        .qrcode {
          width: 160px;
        }
      }
    }
  }
}
</style>

import request from "@/utils/request";
//查看会员列表
export const memberLists = (params) => {
  return request({
    method: "GET",
    url: "/api/vip-infos",
    params,
  });
};
//新增会员信息
export const addMember = (data) => {
  return request({
    method: "POST",
    url: "/api/vip-infos",
    data,
  });
};
//获取会员信息
export const memberDetails = (memberId) => {
  return request({
    method: "GET",
    url: `/api/vip-infos/${memberId}`,
  });
};
//修改会员信息
export const editMember = (memberId, data) => {
  return request({
    method: "PUT",
    url: `/api/vip-infos/${memberId}`,
    data,
  });
};
//修改会员信息
export const editMemberState = (memberId, data) => {
  return request({
    method: "PUT",
    url: `/api/vip-infos/${memberId}/state`,
    data,
  });
};
//充值记录
export const rechageRecords = (params, id) => {
  return request({
    method: "GET",
    url: `/api/vip-infos/recharge-records?vip_id=${id}`,
    params,
  });
};
///api/vip-infos/coupon-report
//优惠券报表
export const couponRecord = (params, id) => {
  return request({
    method: "GET",
    url: `/api/vip-infos/coupon-report?vip_id=${id}`,
    params,
  });
};

// 其他调整
export const otherAdust = (data) => {
  return request({
    method: "POST",
    url: "/api/vip-trim-order/other",
    data,
  });
};

// 余额调整
export const balanceAudst = (data) => {
  return request({
    url: "/api/vip-trim-order/balance",
    method: "POST",
    data,
  });
};

// 修改会员信息
export const updateVipInfo = (data, id) => {
  return request({
    url: `/api/vip-infos/${id}`,
    method: "PUT",
    data,
  });
};
// 消费记录
export const consumeInfo = (params, id) => {
  return request({
    url: `/api/vip-infos/consume/${id}`,
    method: "GET",
    params,
  });
};
//  积分记录
export const getPointReport = (params, id) => {
  return request({
    url: `/api/vip-infos/point-report?vip_id=${id}`,
    method: "GET",
    params,
  });
};

// 退款报表
export const getRefundReport = (params, id) => {
  return request({
    url: `/api/vip-infos/refund-report?vip_id=${id}`,
    method: "GET",
    params,
  });
};

//消费报表
export const getConsumeReport = (params, id) => {
  return request({
    url: `/api/vip-infos/consume-report?vip_id=${id}`,
    method: "GET",
    params,
  });
};

// 会员权益排序
export const getPriGrade = (params) => {
  return request({
    url: "/api/grade-privileges/dic",
    method: "GET",
    params,
  });
};

// 修改会员权益排序
export const putPriGrade = (data) => {
  return request({
    url: "/api/grade-privileges/sort",
    method: "PUT",
    data,
  });
};

// 获取会员卡的类型

export const getCardType = (params) => {
  return request({
    url: "/api/dictionaries/vip-card-type",
    method: "GET",
    params,
  });
};

// 作废优惠券

export const putNouseCoupon = (data) => {
  return request({
    url: "/api/vip-infos/nullify-no-use-coupon",
    method: "PUT",
    data,
  });
};

// 获取商户参数
//
export const getMerchantSycConfig = (data) => {
  return request({
    url: "/api/merchants/sys-config",
    method: "GET",
    data,
  });
};

// 修改商户参数
//
export const putMerchantSycConfig = (data) => {
  return request({
    url: "/api/merchants/sys-config",
    method: "PUT",
    data,
  });
};

import request from '@/utils/request'
export const getVipTotal = (params) => {
  return request({
    url: '/api/report/vip',
    method: 'GET',
    params,
  })
}
///api/report/store-value-report
export const getStoreTotal = (params) => {
  return request({
    url: '/api/report/stored-value-detail',
    method: 'GET',
    params,
  })
}

export const getConsumeTotal = (params) => {
  return request({
    url: '/api/report/consume',
    method: 'get',
    params,
  })
}

export const getConsumeTotalDetail = (params) => {
  return request({
    url: '/api/report/consume-detail-report',
    method: 'get',
    params,
  })
}
///api/report/refund-report
export const getrefunList = (params) => {
  return request({
    url: '/api/report/refund-report',
    method: 'get',
    params,
  })
}

export const getPointTotal = (params) => {
  return request({
    url: '/api/report/point-detail',
    method: 'GET',
    params,
  })
}

export const getCoupon = (params) => {
  return request({
    url: '/api/report/coupon-statistical-report',
    method: 'GET',
    params,
  })
}

// 开卡统计
export const openCardTotal = (params) => {
  return request({
    url: '/api/report/open-card',
    method: 'GET',
    params,
  })
}
// 充值统计

export const chargeTotal = (params) => {
  return request({
    url: '/api/report/recharge',
    method: 'GET',
    params,
  })
}

// 充值统计的所有统计数据
export const chargeAll = (params) => {
  return request({
    url: '/api/report/recharge-statistical',
    method: 'GET',
    params,
  })
}

export const refundDetail = (params) => { 
  return request({
    url: "/api/report/refund-detail-report",
    method: "GET",
    params
  })
}

// 赠送统计
export const getGiftReport = (params) => {
  return request({
    url: '/api/report/gifts',
    method: 'GET',
    params,
  })
}
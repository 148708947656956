<template>
  <div class="jlkReportHeader">
    <div class="jlkReportHeader-header flexContent">
      <div>
        <span>{{ title }}</span>
        <span v-if="remark" class="jlkReportHeader-remark">{{ remark }}</span>
        <el-tooltip
          v-if="tooltipContent"
          class="item"
          effect="dark"
          placement="right-start"
        >
          <div
            slot="content"
            style="font-size: 14px"
            v-html="tooltipContent"
          ></div>
          <i class="iconfont icon-bangzhu_1" style="margin-left: 10px"></i>
        </el-tooltip>
      </div>
      <slot name="search">
        <div v-if="datePicker">
          <el-date-picker
            v-model="dateValue"
            style="width: 275px"
            size="mini"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="handleChange"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
      </slot>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    remark: {
      type: String,
      default: "",
    },
    datePicker: {
      type: Boolean,
      default: false,
    },
    tooltipContent: {
      type: String,
      default: "",
    },
    defaultValue: {
      type: Array,
      default: () => [],
    },
    endDate: {
      type: String,
      default: "today",
    },
  },
  data() {
    return {
      dateValue: [],
    };
  },
  computed: {
    pickerOptions() {
      const endDate = this.endDate;
      let result = {
        shortcuts: [
          {
            text: endDate === "today" ? "今天" : "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              if (endDate === "yesterday") {
                start.setTime(start.getTime() - 3600 * 1000 * 24);
                end.setTime(end.getTime() - 3600 * 1000 * 24);
              }
              console.log([start, end]);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              if (endDate === "yesterday") {
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 8);
                end.setTime(end.getTime() - 3600 * 1000 * 24);
              }
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              if (endDate === "yesterday") {
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 31);
                end.setTime(end.getTime() - 3600 * 1000 * 24);
              }
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              if (endDate === "yesterday") {
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 91);
                end.setTime(end.getTime() - 3600 * 1000 * 24);
              }
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近半年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
              if (endDate === "yesterday") {
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 181);
                end.setTime(end.getTime() - 3600 * 1000 * 24);
              }
              picker.$emit("pick", [start, end]);
            },
          },
        ],
        disabledDate(time) {
          if (endDate === "yesterday") {
            return (
              time.getTime() + 3600 * 1000 * 24 >
              moment(Date.now()).endOf("day")
            );
          } else {
            return time.getTime() > moment(Date.now()).endOf("day");
          }
        },
      };
      return result;
    },
  },
  created() {
    if (this.defaultValue && this.defaultValue.length) {
      this.dateValue = this.defaultValue;
    }
  },
  methods: {
    handleChange() {
      this.$emit("change", this.dateValue || ["", ""]);
    },
  },
};
</script>

<style lang="scss" scoped>
.jlkReportHeader {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 6px;
  .jlkReportHeader-header {
    color: #2b2b2b;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 15px;
  }
  .icon-bangzhu_1 {
    font-weight: normal;
    &:hover {
      color: $primary-color;
    }
  }
  .jlkReportHeader-remark {
    font-size: 16px;
    font-weight: bold;
    color: $hint-color;
    padding-left: 10px;
  }
}
</style>

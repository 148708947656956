import request from '@/utils/request'
import { partialRight } from 'lodash'
export const getPersonal = () => {
    return request({
        method: 'GET',
        url: '/api/personal',
    })
}
export const editPersonal = (data = {}) => {
    return request({
        method: 'PUT',
        url: '/api/personal',
        data,
    })
}

export const unBindWechat = () => {
    return request({
        method: 'PUT',
        url: '/api/personal/unbind-wechat',
    })
}

export const editPassword = (data) => {
    return request({
        method: 'PUT',
        url: '/api/personal/pw',
        data,
    })
}

// 更新手机号码
export const updatePhone = (data) => {
    return request({
        method: 'PUT',
        url: '/api/personal/bind-new-phone',
        data,
    })
}

// 效验密码

export const getCheckPassword = (params = {}) => {
    return request({
        method: 'POST',
        url: '/api/personal/check-password',
        data: params
    })
}

// 短信验证

export const getCheckMessage = (params = {}) => {
    return request({
        method: 'POST',
        url: '/api/sms/verify-other-code',
        data: params
    })
}

// 登录保持时间
export const putLongTime = (params = {})=>{
    return request({
        method:"PUT",
        url:'/api/personal/keep-login-time',
        data:params
    })
}

export const getSendNewPhone = (params = {})=>{
     return  request({
         method:'POST',
         url:'/api/sms/send/new-phone',
         data:params
     })
}
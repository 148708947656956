import { JlkMessage, JlkNotify, JlkLoading } from "@/utils/message";
import { getMerchantDetailInfo } from "@/services/merchant.js";
import { resetLoad } from "@/router/index.js";
import $router from "@/router/index.js";

const state = {
  user: sessionStorage.getItem("user")
    ? JSON.parse(sessionStorage.getItem("user"))
    : "",
  token: sessionStorage.getItem("token")
    ? JSON.parse(sessionStorage.getItem("token"))
    : "",
};
const mutations = {
  setToken(state, payload) {
    console.log(payload);
    sessionStorage.setItem(
      "token",
      payload ? JSON.stringify(payload) : payload
    );
    state.token = payload;
  },
  setUser(state, payload) {
    sessionStorage.setItem("user", payload ? JSON.stringify(payload) : payload);
    state.user = payload;
  },
};
const actions = {
  setToken({ commit }, pay) {
    return new Promise((resolve, reject) => {
      commit("setToken", pay);
      resolve();
    });
  },
  setUserAction({ commit }, pay) {
    return new Promise((resolve, reject) => {
      resetLoad();
      commit("setUser", pay);
      resolve()
    });
  },
  LOINGOUT({ commit }, view) {
    return new Promise((resolve, reject) => {
      commit("setUser", null);
      sessionStorage.clear();
      localStorage.clear();
      $router.replace("/");
      resolve(JlkMessage("退出成功", "success"));
    });
  },
  getMemberInfoStore() {
    return new Promise(async (resolve, reject) => {
      const merchant = sessionStorage.getItem("merchant")
        ? JSON.parse(sessionStorage.getItem("merchant"))
        : "";
      const ret = await getMerchantDetailInfo(merchant.merchant_id);
      if (ret && ret.ok) {
        resolve(ret.data);
      }
    });
  },
};

const getters = {};
export default {
  state,
  mutations,
  actions,
  getters,
};

<template>
  <div class="wrap_outer">
    <div class="contain">
      <img
        src="https://jlk-sys-image.oss-cn-zhangjiakou.aliyuncs.com/202308311052524912703153408305819.png"
        alt=""
      />
      <p>{{ $route.query.title }}服务未开通，请联系您的服务商开通~</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.wrap_outer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contain {

  text-align: center;
  img {
    width: 260px;
    height: 260px;
  }
  p {
    font-size: 18px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #2b2b2b;
  }
}
</style>
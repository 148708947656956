<template>
  <div class="dialog_tabs">
    <div class="dialog_header">
      <div class="dialog_tabs">
        <span v-for="(item, index) in tabPaneList" :key="index" class="tab_item" :class="[value == index ? 'active' : '']"
          @click="tab(index)">
          <a>{{ item }}</a>
          <div class="right" v-if="value == index">
            <slot :name="`rightBtn${index}`"></slot>
          </div>
        </span>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  name: "myTabs",
  components: {},
  props: {
    value: {
      type: [String, Number],
      default: () => 0
    },
    tabPaneList: {
      default: () => [],
      type: Array,
    },
  },
  methods: {
    tab(index) {
      this.$emit("input", index);
    }
  },
};
</script>

<style lang="scss" scoped>
.dialog_header {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  color: #2B2B2B;
  margin-bottom: 20px;
  position: relative;
  border-bottom: 3px solid #F9FAFE;
}

.tab_header {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  color: #2B2B2B;
  margin-bottom: 20px;
  border-bottom: 3px solid #F9FAFE;
}

.dialog_tabs {
  height: 40px;

  flex: 1;

  .tab_item {
    margin-right: 30px;
    display: inline-block;
    height: 35px;
    cursor: pointer;
    font-size: 16px;
    color: #2b2b2b
  }

  .active {
    border-bottom: 3px solid #0BA976 !important;

    a {
      color: #0BA976 !important;

    }
  }

  .right{
    position: absolute;
    right: 0px;
    bottom: 12px;
  }
}
</style>

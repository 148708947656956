<template>
  <div id="app" @click="handleRightMenuVisible(false)">
    <router-view />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { getNameQuery } from "@/utils/handleData";
export default {
  name: "app",
  provide() {
    return {
      reload: this.reload,
      beforeunloadTime: 0,
      unloadTime: 0,
    };
  },
  computed: {
    visitedViews() {
      return this.$store.state.tagsView.visitedViews;
    },
  },
  created() {},
  mounted() {
    let isVip = sessionStorage.getItem("isVip")
      ? sessionStorage.getItem("isVip")
      : "true";
    if (getNameQuery() && getNameQuery()["isVip"] === "false") {
      isVip = getNameQuery()["isVip"];
      sessionStorage.removeItem("tabViews");
    }
    sessionStorage.setItem("isVip", isVip);
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
    window.addEventListener("unload", (e) => this.unload(e));
  },
  destroyed() {
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e)
    );
    window.removeEventListener("unload", (e) => this.unload(e));
  },
  methods: {
    ...mapActions({
      handleRightMenuVisible: "tagsView/handleRightMenuVisible",
    }),
    beforeunloadHandler() {
      this.beforeunloadTime = new Date().getTime();
    },
    saveMeuns() {
      const tabViews = this.$store.state.tagsView.visitedViews.map((item) => {
        return {
          fullPath: item.fullPath,
          hash: item.hash,
          meta: { ...item.meta },
          name: item.name,
          params: { ...item.params },
          path: item.path,
          query: { ...item.query },
          title: item.title,
        };
      });
      sessionStorage.setItem("tabViews", JSON.stringify(tabViews));
    },
    unload() {
      this.unloadTime = new Date().getTime();
      if (this.unloadTime - this.beforeunloadTime <= 1) {
        // 执行退出登录
        sessionStorage.clear();
        localStorage.clear();
      } else {
        this.saveMeuns();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

<template>
  <el-table-column :width="rows.width || ''" :min-width="rows.minWidth" :prop="rows.prop" :label="rows.label"
    :fixed="rows.isFixed" :align="rows.align || (rows.slot === 'action' ? 'center' : 'left')" :sortable="rows.sort"
    :header-align="rows.headerAlign ||
      rows.align ||
      (rows.slot === 'action' ? 'center' : 'left')
      ">
    <template slot-scope="scope" slot="header">
      <template v-if="rows.customHeaderRender">
        <VNode :content="rows.customHeaderRender(scope.row, rows)"></VNode>
      </template>
      <span v-if="rows.header_tooltips">
        {{ rows.label }}
        <el-tooltip :aa="scope" class="item" effect="dark" :content="rows.header_tooltips" placement="top-start">
          <i class="iconfont icon-tishi1" style="color: #666;font-size:14px;margin-left:2px"> </i>
        </el-tooltip>
      </span>
      <span v-else>{{ rows.label }}</span>
    </template>
    <template slot-scope="scope">
      <div :class="{ 'jlk-pointer': rows.allowCopy }">
        <slot v-if="rows.slot" :scope="scope" :name="rows.slot" />
        <template v-else-if="rows.customRender">
          <VNode :content="rows.customRender(scope.row, rows)"></VNode>
        </template>
        <template v-else>
          <el-tooltip ref="key" slot="reference" placement="top" effect="dark" v-if="rows.tooltip">
            <template slot="content">{{ scope.row[rows.prop] }}</template>
            <span class="myNote">{{
              scope.row[rows.prop] || scope.row[rows.prop] === 0
                ? scope.row[rows.prop]
                : "/"
            }}</span>
            <i class="iconfont icon-fuzhi" @click="handleColClick(scope.row, rows)"
              style="font-size: 14px; color: #4457cf; margin-left: 6px" v-if="rows.allowCopy"></i>
          </el-tooltip>
          <span v-else-if="rows.fixed">
            {{ $Fixed(scope.row[rows.prop], Number(rows.fixed)) }}
            <i class="iconfont icon-fuzhi" @click="handleColClick(scope.row, rows)"
              style="font-size: 14px; color: #4457cf; margin-left: 6px" v-if="rows.allowCopy"></i></span>
          <span class="myNote" v-else>{{
            scope.row[rows.prop] || scope.row[rows.prop] === 0
              ? scope.row[rows.prop]
              : "/"
          }}
            <i class="iconfont icon-fuzhi" style="font-size: 14px; color: #4457cf; margin-left: 6px"
              @click="handleColClick(scope.row, rows)" v-if="rows.allowCopy"></i></span>
        </template>
      </div>
    </template>
    <slot></slot>
  </el-table-column>
</template>

<script>
import VNode from "./VNode.vue";
export default {
  props: {
    rows: {
      type: Object,
      default: () => { },
    },
  },
  components: {
    VNode,
  },
  methods: {
    handleColClick(row, headInfo) {
      if (headInfo.allowCopy) {
        this.$copyText(row[headInfo.prop]).then(
          (res) => {
            this.$JlkMessage("复制成功", "success");
          },
          (err) => {
            console.log(err);
            this.$JlkMessage("复制失败", "error");
          }
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.myNote {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>

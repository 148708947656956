<template>
  <div>
    <el-form class="formItem" ref="formRef" :model="ruleForm" :rules="rules">
      <el-form-item label="新手机号码" prop="phone" style="width: 100%;">
        <el-input style="width: 100%" v-model="ruleForm.phone" :placeholder="'新手机号码'" />
      </el-form-item>
      <el-form-item label="验证码" prop="captcha_code" style="width: 100%;">
        <div style="display: flex;align-items: center;justify-content: center;width: 100%;">
          <el-input v-model="ruleForm.captcha_code" :placeholder="'验证码'" />
          <el-button style="margin-left: 10px;" size="mini" type="primary" :loading="loading" :disabled="!!count" plain
            @click="getCaptcha" class="btn">
            {{ !loading && count ? count + "s" : "获取验证码" }}
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>


export default {
  props: {
    formData: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新手机号码"));
      } else if (!/^1[0-9]{10}$/.test(value)) {
        callback(new Error("手机号码格式不正确"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        phone: "",
        captcha_code: "",
      },
      rules: {
        captcha_code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        phone: [{ required: true, validator: validatePhone, trigger: "blur" }],
      },
      loading: false,
      countDownTimer: null,
      count: 0
    };
  },
  methods: {
    async getCaptcha() {
      if (this.loading || !this.ruleForm.phone) return false;
      this.loading = true;
      try {
        const ret = await this.$API.postSendNewCaptcha(this.ruleForm);
        if (ret && ret.ok) {
          this.loading = false;
          this.count = 60;
          this.countDownTimer = setInterval(() => {
            this.count--;
            if (this.count <= 0) {
              clearInterval(this.countDownTimer);
              this.count = 0
            }
          }, 1000);
        } else {
          this.loading = false
        }
      } catch (error) {
        this.loading = false;
      }
    },
    handleOk() {
      return new Promise(async (resolve, reject) => {
        const valid = await this.$refs.formRef.validate();
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.ruleForm));
          const ret = await this.$API.updateMercPhone(data);
          if (ret && ret.ok) {
            resolve(this.$JlkMessage('账号手机号码已更换，请重新登录~', 'success'));
          }
        }
      });
    },
  },
  beforeDestroy() {
    if (this.countDownTimer) {
      clearInterval(this.countDownTimer);
    }
  },
};
</script>

<style lang="scss" scoped>
.btn {
  width: 92px;
  text-align: center;
}
</style>

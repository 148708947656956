<template>
  <div class="menu_contain">
    <div class="header">账号中心</div>
    <div v-for="item in accountMenuEnum" :key="item.value" class="menu-item"
      :class="{ active: activeIndex === item.value }" @click="handleMenu(item)">
      <i :class="['iconfont', item.iconClass]" style="font-size:20px"></i>
      <span style="padding-left: 10px">{{ item.label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeLabel: {
      type: [Number, String],
      default: "",
    },
    activeIndex: {
      type: [Number, String],
      default: "",
    },
  },
  data() {
    return {
      accountMenuEnum: [
        { label: "账号资料", value: 1, iconClass: "icon-zhanghaoziliao" },
        { label: "安全设置", value: 2, iconClass: "icon-anquanshezhi" },
      ]
    };
  },
  methods: {
    handleMenu(menuItem) {
      this.$emit('update:activeLabel', menuItem.label);
      this.$emit('update:activeIndex', menuItem.value);
      this.$emit('tab', menuItem.value)
    },
  },
};
</script>

<style lang="scss" scoped>
.menu_contain {
  width: 260px;
  min-width: 130px;
  height: 600px;
  background: #fff;
  font-weight: bold;
  border-radius: 8px;
}

.header {
  padding: 20px;
  font-size: 16px;
  border-bottom: 1px solid #f2f2f2;
}

.menu-item {
  display: flex;
  justify-items: center;
  align-items: center;
  padding: 20px;

  &:hover {
    background: #F8F8F8;
    cursor: pointer;
  }

  &.active {
    background-color: rgba(11, 169, 118, 0.05);
    color: #0ba976;
  }
}
</style>

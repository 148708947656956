<template>
  <div style="width: 200px;border-radius: 4px; border: 1px solid #dcdfe6;overflow: hidden;">
    <el-input
      size="mini"
      style="width: 45%;"
      v-model="queryForm[prop[0]]"
      clearable
      :placeholder="placeholder[0]"
      @input="checkNum(prop[0])"
      @blur="handleBlur($event, prop[0])"
      @clear="eventCallSearchCondition"
      @keyup.enter.native="eventCallSearchCondition"
    />
    <el-input class="mid-input" size="mini" style="width: 10%;" value="-" readonly />
    <el-input
      size="mini"
      style="width: 45%;"
      v-model="queryForm[prop[1]]"
      clearable
      :placeholder="placeholder[1]"
      @input="checkNum(prop[1])"
      @blur="handleBlur($event, prop[1])"
      @clear="eventCallSearchCondition"
      @keyup.enter.native="eventCallSearchCondition"
    />
  </div>
</template>

<script>
export default {
  props: {
    prop: {
      type: Array,
      default: () => []
    },
    queryForm: {
      type: Object,
      default: () => {}
    },
    placeholder: {
      type: Array,
      default: () => ['最小金额', '最大金额']
    },
    fixed: {
      type: Number,
      default: 0
    }
  },
  methods: {
    /** 检查数字 */
    checkNum(key) {
      let g = String(this.queryForm[key])
      // 先把非数字的都替换掉，除了数字和.
      g = g.replace(/[^\d.]/g, '')
      // 保证只出现一个.而没有多个.
      g = g.replace(/\.{2,}/g, '.')
      // 必须保证第一个不为.
      g = g.replace(/^\./g, '')
      // 保证.只出现一次，而不能出现两次以上
      g = g.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
      this.queryForm[key] = g
    },
    handleBlur(e, key) {
      if (e.target.value || e.target.value === 0) {
        this.queryForm[key] = parseFloat(e.target.value).toFixed(this.fixed)
      }
    },
    eventCallSearchCondition() {
      this.$emit('change')
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-input__inner {
      border-width: 0px;
      border-radius: 0px;
      text-align: center;
      font-size: 14px;
    }
    .el-input--suffix .el-input__inner {
      padding-right: 15px;
    }
  }
  .mid-input ::v-deep .el-input__inner {
    padding: 0;
  }
</style>

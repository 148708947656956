<template>
  <div class="detail_contain wrap_outer">
    <div class="nav" style="padding: 0px 30px">
      <img
        src="https://jlk-sys-image.oss-cn-zhangjiakou.aliyuncs.com/202208200942435040047927399094430.png"
        alt=""
      />
      <i class="iconfont icon-bangding1"></i>
      <img :src="row.wechat_avatar" />
    </div>

    <div class="nav">
      <div>{{ row.actual_name }}</div>
      <div>{{ row.wechat_name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    handleOk() {
      return new Promise((resolve, reject) => {
        this.$confirm(
          `账号「${this.row.phone}」将和微信「${this.row.wechat_name}」解绑，解除绑定后，您将不可使用微信扫码快捷登录系统，确定继续解绑？`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            // type: 'warning'
          }
        ).then(async () => {
          const ret = await this.$API.personalUnbindWechat();
          if (ret && ret.ok) {
            resolve(this.$JlkMessage("解绑成功", "success"));
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.detail_contain {
  padding: 100px 35px;
  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      height: 100px;
      width: 100px;
      border-radius: 6px;
    }
    div {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #202020;
      margin-top: 22px;
      width: 160px;
      text-align: center;
    }
  }
  .iconfont {
    font-size: 50px;
  }
}
</style>
import request from '@/utils/request'
//删除多倍积分规则
export const delPointRuleMultiple = (id) => {
  return request({
    method: 'DELETE',
    url: `/api/point-rule/multiple?pointRuleDetailId=${id}`,
  })
}
//获取多倍积分规则
export const PointRuleMultipledetails = () => {
  return request({
    method: 'GET',
    url: '/api/point-rule/multiple',
  })
}
//设置多倍积分规则
export const PointRuleMultipleSetting = (data) => {
  return request({
    method: 'POST',
    url: '/api/point-rule/multiple',
    data,
  })
}
//修改多倍积分
export const updateSetting = (data,id) => {
  return request({
    method: 'PUT',
    url: `/api/point-rule/multiple/${id}`,
    data,
  })
}

//获取基础积分规则
export const pointRuleBase = () => {
  return request({
    method: 'GET',
    url: '/api/point-rule/base',
  })
}

//设置基础积分规则
export const PointRuleBaseSetting = (data) => {
  return request({
    method: 'POST',
    url: '/api/point-rule/base',
    data,
  })
}

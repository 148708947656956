<template>
  <div style="padding: 30px">
    <section class="block-item">
      <i style="color: #0ba976;font-size: 30px;" class="iconfont icon-shoujihao"></i>
      <div class="item_phone" style="flex: 1; padding: 0 20px">
        <div class="phone_label">手机号码</div>
        <div style="color: #666666">
          已绑定手机号码「{{ initData.phone }}」
        </div>
      </div>
      <div class="btn" @click="openValidPhone">更改</div>
    </section>
    <section class="block-item" style="margin-top: 20px">
      <i style="color: #0ba976;font-size: 30px;" class="iconfont icon-mima"></i>
      <div class="item_phone" style="flex: 1; padding: 0 20px">
        <div class="phone_label">账户密码</div>
        <div style="color: #666666">已设置，可通过账号密码登录</div>
      </div>
      <div style="display: flex;align-items: center;">
        <div class="btn" @click="openUpdatePwd" style="margin-right:10px;">修改密码</div>
        <div class="btn" @click="resetPwd">重置密码</div>
      </div>
    </section>
    <section class="block-item" style="margin-top: 20px">
      <!-- icon-weibangdingweixin -->
      <i style="color: #0ba976;font-size: 30px;" class="iconfont icon-weixinyibangding_1"></i>
      <div class="item_phone" style="flex: 1; padding: 0 20px">
        <div class="phone_label">微信登录</div>
        <div style="color: #666666">{{ initData.wechat_union_id ? "已设置，可通过账号密码登录" : "未绑定微信" }}</div>
      </div>
      <div class="btn" @click="bindWechat(initData.wechat_union_id)">{{ initData.wechat_union_id ? '解除绑定' : '立即绑定' }}
      </div>
    </section>
  </div>
</template>

<script>
import ValidPhone from "./ValidPhone.vue";
import BindPhone from "./BindPhone.vue";
import UpdatePwd from "./UpdatePwd.vue";
import resetPass from "./resetPass.vue";
import bindWechat from "./bindWechat.vue"
import bindWechatDetail from "./bindWechatDetail.vue"
export default {
  props: {
    initData: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      formData: {}
    };
  },
  mounted() {
    this.formData = this.initData
  },
  methods: {
    /**
     * 打开修改密码弹框
     */
    openUpdatePwd() {
      this.$dialog(
        UpdatePwd,
        {
          ok: () => { },
        },
        {
          title: "更改密码",
          width: '460px',
          okText: "确定",
          footer: true,
        }
      );
    },
    /**
     * 打开绑定手机号弹框
     */
    openBindPhone() {
      this.$dialog(
        BindPhone,
        {
          formData: this.initData,
          ok: () => {
            setTimeout(() => {
              this.logOut()
            }, 1500)
          },
        },
        {
          title: "新手机号绑定",
          width: '460px',
          okText: "确定",
          footer: true,
        }
      )
    },
    //退出登录
    async logOut() {
      this.$store.dispatch("LOINGOUT");
    },
    /*
    *  重置密码
    */
    resetPwd() {
      this.$dialog(
        resetPass,
        {
          formData: this.initData,
          ok: () => {

          },
        },
        {
          title: "重置密码",
          width: '460px',
          okText: "确定",
          footer: true,
        }
      )
    },
    /*
    * bindWechat绑定微信
    */
    bindWechat() {
      const title = this.initData.wechat_union_id ? '解除绑定' : '立即绑定'
      const comp = this.initData.wechat_union_id ? bindWechatDetail : bindWechat
      const isFooter = this.initData.wechat_union_id ? true : false
      this.$dialog(
        comp,
        {
          row: this.initData,
          ok: () => {
            this.$emit("getCurrentInit", 2)
          },
        },
        {
          title: title,
          width: '700px',
          okText: "确定",
          footer: isFooter,
          okText: "解除绑定"
        }
      )
    },
    /**
     * 打开安全验证弹框
     */
    openValidPhone() {
      this.$dialog(
        ValidPhone,
        {
          formData: this.initData,
          ok: () => {
            this.openBindPhone();
          },
        },
        {
          title: "安全验证",
          width: "460px",
          okText: "下一步",
          footer: true,
        }
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.block-item {
  background: #F8F8F8;
  padding: 30px;
  border-radius: 8px;
  display: flex;
  align-items: center;

  .item_phone {
    padding: 0 20px;

    .phone_label {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
}

.btn {
  background: #F8F8F8;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #CCCCCC;
  padding: 6px 12px;
  color: #666666;
  cursor: pointer;

  &:hover {
    border-color: #0BA976;
    color: #0BA976;
  }
}
</style>

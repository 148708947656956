import { render, staticRenderFns } from "./loginCom.vue?vue&type=template&id=019b2ba5&scoped=true"
import script from "./loginCom.vue?vue&type=script&lang=js"
export * from "./loginCom.vue?vue&type=script&lang=js"
import style0 from "./loginCom.vue?vue&type=style&index=0&id=019b2ba5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "019b2ba5",
  null
  
)

export default component.exports
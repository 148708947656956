<template>
  <div class="richContain">
    <el-tabs type="border-card">
      <el-tab-pane>
        <span slot="label"><i class="el-icon-date"></i> 文字</span>
        <textarea cols="30" rows="10" class="text"></textarea>
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label"><i class="el-icon-date"></i> 图片</span>
        <div class="btnWrap">
          <div class="upload">
            <div class='uploadFile'>
              <input class="file" type="file" style="opacity:0">
              <img src="" alt="" style='height:30px;width:36px'>
            </div>
            <div class="ts">选择图片</div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
.richContain {
  ::v-deep.el-tabs {
    .el-tabs__header {
      border-bottom: none !important;
      .is-active {
        background-color: #f5f7fa !important;
      }
      .el-tabs__item {
        border-right: none;
        border-left: none;
        border-bottom: none !important;
        z-index: 1;
      }
      .el-tabs__nav {
        z-index: 0;
      }
    }
  }
  .text {
    width: 100%;
    height: 280px;
    border: none;
    outline: none;
  }
  .btnWrap {
    width: 100%;
    height: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .upload {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 80px;
      .uploadFile {
        position: relative;
      }
      .file {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 120px;
        height: 120px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>

const reports = [
  {
    name: 'transactionFlowDetail',
    path: '/payReports/transactionData/transactionFlow/consumption',
    component: () => import('@/views/payReports/transactionData/transactionFlow/detail.vue'),
    meta: {
      title: '消费订单',
      noCache: true
    }
  },
  {
    name: 'transactionFlowDetail',
    path: '/payReports/transactionData/transactionFlow/refund',
    component: () => import('@/views/payReports/transactionData/transactionFlow/detail.vue'),
    meta: {
      title: '退款订单',
      noCache: true
    }
  },
  {
    name: 'staffManagementAdd',
    path: '/system/staff/staffManagement/add',
    component: () => import('@/views/system/staff/staffManagement/add.vue'),
    meta: {
      title: '员工新增',
      noCache: false
    }
  },
  {
    name: 'staffManagementDetail',
    path: '/system/staff/staffManagement/detail',
    component: () => import('@/views/system/staff/staffManagement/detail.vue'),
    meta: {
      title: '员工资料',
      noCache: false
    }
  },
]
export default reports

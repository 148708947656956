<template>
  <div class="forgetPassPage">
    <div class="_title">
      <img src="https://jlk-sys-image.oss-cn-zhangjiakou.aliyuncs.com/202208151056534746160042807478817.png"
        alt="据来客LOGO" style="width: 38px; height: 38px" />
      <div class="right_line"></div>
      <div style="font-size: 16px">聚哒客商户中心</div>
    </div>
    <div class="_content">
      <div class="__container">
        <div class="___title">提示</div>
        <div class="loginOutContainer">
          <img style="width: 360px; height: 360px"
            src="https://jlk-sys-image.oss-cn-zhangjiakou.aliyuncs.com/202208160909304879361562743217237.png" alt="" />
          <div class="loginOut">
            <p class="tips">您的账号还没有关联商户，请联系服务商处理~</p>
            <span class="btn" @click="btn_loginOut">退出登录</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    async btn_loginOut() {
      const ret = await this.$API.getLogout()
      if (ret && ret.ok) {
        this.$store.dispatch("LOINGOUT");
        this.$emit("tabClick", 0);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.forgetPassPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $sidebar-bg;
  display: flex;

  ._title {
    height: 54px;
    background: $grounding-color;
    display: flex;
    align-items: center;
    padding: 0 30px;
    box-sizing: border-box;

    img {
      width: 65px;
      height: 24px;
      margin-right: 16px;
    }

    div {
      @include mixin-font_18_8($secondary-color);
    }
  }

  .return_btn {
    text-align: right !important;

    &:hover {
      color: rgba(11, 169, 118, 0.8) !important;
    }
  }

  ._content {
    padding: 20px 18%;
    height: calc(100% - 54px);
    overflow-y: auto;
    box-sizing: border-box;

    .__container {
      width: 100%;
      background-color: $grounding-color;
      padding: 30px;
      box-sizing: border-box;
      text-align: center;

      .___title {
        height: 48px;
        background-color: $subtitle-color;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include mixin-font_20_b($forgetPass-color);
      }

      .forgetFormContainer {
        width: 350px;
        margin: 80px auto 0;

        .el-form {
          .el-form-item {
            width: 100%;
            margin-bottom: 30px;

            ::v-deep.el-input__inner {
              height: 52px;
              line-height: 52px;
              border: 1px solid #e1e1e1;
            }
          }

          .is-error {
            ::v-deep.el-input__inner {
              border: 1px solid rgba(255, 74, 74, 0.502);
            }
          }

          .set_btn {
            width: 100%;
            height: 100%;
            border-radius: 6px;
            background: #0ba976;
            border: 1px solid #0ba976;
            font-size: 20px;
            margin-top: 30px;
            @include mixin-font_20_5($grounding-color);

            &:hover {
              opacity: 0.8;
            }
          }

          ::v-deep.el-form-item__content {
            display: flex;
            align-items: center;

            .codeGeting {
              background: #cdcdcd;
              border-color: #cdcdcd;
            }

            .el-form-item__error {
              right: 0px;
              text-align: right;
              font-weight: 400;
            }

            .tip {
              position: absolute;
              top: 10px;
              left: 352px;
              width: 242px;
              @include mixin-font_14_4($hint-color);
            }
          }
        }

        div {
          text-align: center;
          font-size: 16px;
          color: #0ba976;
          font-weight: bold;
          font-family: "PingFang SC";
        }
      }

      .loginOutContainer {
        padding: 125px 98px 0px 90px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .loginOut {
          .tips {
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 24px;
            color: #666666;
            margin-bottom: 50px;
          }

          .btn {
            display: inline-block;
            width: 400px;
            height: 58px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 58px;
            text-align: center;
            color: #999999;
            border: 1px solid #cccccc;
            opacity: 1;
            border-radius: 4px;

            &:hover {
              color: rgba(11, 169, 118, 1);
              border: 1px solid rgba(11, 169, 118, 1) !important;
              cursor: pointer;
            }
          }
        }
      }

      .changePwdFinish {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 260px;
        justify-content: space-between;

        .icon-chenggong1 {
          font-size: 120px;
          color: #0ba976;
          line-height: 1;
        }

        .tipText {
          display: flex;
          flex-direction: column;
          height: 50px;
          justify-content: space-between;

          &>span:nth-of-type(1) {
            font-size: 20px;
            font-weight: bold;
            color: #2b2b2b;
          }

          &>span:nth-of-type(2) {
            color: #999999;

            a {
              color: #0ba976;
            }
          }
        }

        .toLoginBtn {
          width: 280px;
          border-radius: 24px;
          border-color: #0ba976;
          color: #0ba976;
        }
      }
    }
  }
}
</style>

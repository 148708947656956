<template>
    <div class="wechatContain wrap_outer">
        <div class="right">
            <div v-if="!isBind" class="leftContent">
                <div class="wxQrcode">
                    <div id="weixin" style="height: 280px"></div>
                    <div class="text">请使用微信扫描二维码来绑定此账号</div>
                </div>
            </div>
            <div v-else class="leftContent">
                <img class="avatar" width="132" height="132" style="padding: 130px 0 10px" :src="row.wechat_avatar"
                    alt="" />
                <div class="nickname">{{ row.wechat_name }}</div>
            </div>
        </div>
        <div class="wechat_tips" v-if="row.tips">{{ row.tips + ',请重新扫码绑定' }}</div>
        <div class="footer">
            <el-button size="mini" plain @click="cancel_btn">取消</el-button>
        </div>
    </div>
</template>

<script>

import { wxRedirectUrl, wxAppid } from '@/utils/config'
export default {
    props: {
        row: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {
            isBind: false,
            wechatInfo: {
                headimgurl: "",
                nickname: "",
            },
            first_step: false,
            next_disabled: true,
            personalInfo: {},
            formData: {
                password: "",
            },
        };
    },
    mounted() {
        if (this.row && this.row.wechat_id) {
            this.isBind = true
        } else {
            this.isBind = false
        }
        this.getErCode();
    },
    methods: {
        async checkPassword() { },
        cancel_btn() {
            this.$parent.handleClose();
        },
        getErCode() {
            const s = document.createElement("script");
            s.type = "text/javascript";
            s.src = "https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js";
            let redirectUrl = wxRedirectUrl;
            let appid = wxAppid;
            const wxElement = document.body.appendChild(s);
            wxElement.onload = function () {
                var obj = new WxLogin({
                    id: "weixin", // 需要显示的容器id
                    self_redirect: false,
                    appid: appid,
                    scope: "snsapi_login",
                    state: Math.ceil(Math.random() * 1000), // 可设置为简单的随机数加session用来校验
                    redirect_uri: redirectUrl + "/account", // 扫码成功后 跳转的地址
                    href: "data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsNCiAgZGlzcGxheTogbm9uZTsNCn0NCi5pbXBvd2VyQm94IC5zdGF0dXMuc3RhdHVzX2Jyb3dzZXIgew0KICBkaXNwbGF5OiBub25lOw0KfQ0KLmltcG93ZXJCb3ggLnFyY29kZSB7DQogIGJvcmRlcjogbm9uZTsNCiAgd2lkdGg6IDIwMHB4Ow0KICBoZWlnaHQ6IDIwMHB4Ow0KfQ0KLmltcG93ZXJCb3ggLnN0YXR1c3sNCiAgZGlzcGxheTogbm9uZQ0KfQ==",
                    //css 转码为base64
                });
            };
        }
    },
};
</script>

<style lang="scss" scoped>
.wechatContain {
    position: relative;
}

.wechat_tips {
    text-align: center;
    color: #ff4a4a;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 40px;
}

.leftContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .wxQrcode {
        margin: 62px 0;
        display: flex;
        position: relative;
    }

    .text {
        position: absolute;
        bottom: 48px;
        white-space: nowrap;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .nickname {
        padding-bottom: 110px;
    }
}

.right {
    height: 320px;
}

.footer {
    text-align: right;
}

.btn {
    position: absolute;
    right: 0px;
    top: -8px;
}

.first_step {
    .el-form-item {
        width: 100%;
    }

    p {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ff4a4a;
        margin-bottom: 20px;
    }
}
</style>
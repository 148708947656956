<template>
  <component :ref="comList[comIndex] + ''" :is="comList[comIndex]" @tabClick="tabClick" :formData="formData">
  </component>
</template>
<script>
import Vue from "vue";
import loginCom from "./component/loginCom.vue";
import forgetPassCom from "./component/forgetPass.vue";
import relatMerchant from "./component/relatMerchant.vue";
import activaMerchant from "./component/activaMerchant.vue"
import validPhoneCode from './component/validPhoneCode.vue'
export default Vue.extend({
  name: "login",
  data() {
    return {
      comIndex: 0,
      comList: {
        0: loginCom,
        1: forgetPassCom,
        2: relatMerchant,
        3: activaMerchant,
        4: validPhoneCode
      },
      formData: "",
      rules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            validator: function (rule, value, callback) {
              if (/^1[3-9]\d{9}$/.test(value) === false) {
                callback(new Error("手机号格式错误"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 18,
            message: "长度在 6 到 18 个字符",
            trigger: "blur",
          },
        ],
        verification_code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          {
            min: 6,
            max: 6,
            message: "请输入6位数验证码",
            trigger: "blur",
          },
        ],
        new_password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur",
          },
        ],
        confirm_password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur",
          },
        ],
      },

      carousel: [
        "https://jlk-sys-image.oss-cn-zhangjiakou.aliyuncs.com/202208101611155363936077474207177.png",
      ],
    };
  },
  methods: {
    tabClick(index, formData, action) {
      this.formData = formData  // 带来的登录信息
      this.comIndex = index;
      if (action == 'login') {
        console.log(formData)
        this.handleLoginInfo(formData)
      }
    },
    handleLoginInfo(ret) {
      if (ret && ret.ok) {
        const data = ret.data;
        this.$store.dispatch("setToken", data).then(() => {
          this.getPermissions()
        })
      }
      this.$JlkLoading("close");
    },
    async getPermissions() {
      const ret = await this.$API.getPermissSource()
      if (ret && ret.ok) {
        Object.assign(ret.data, sessionStorage.getItem("token") ? JSON.parse(sessionStorage.getItem("token")) : '')
        this.$store.dispatch("tagsView/delAllViews");
        this.$JlkMessage("登录成功", "success");
        this.$store.commit("setUser", ret.data)
        sessionStorage.setItem("isVip", true);
        this.$router.replace("/");
      } else if (ret.err.code == 8012) {
        // 是否关联手机号
        this.tabClick(2)
      }
      this.$JlkLoading("close");
    },
    initQuery() {
      this.SMSForm = {
        phone: "",
        sms: "",
      };
      this.ruleForm = {};
    },

    forgetPass_btn() {
      this.isLogin = !this.isLogin;
    },

    keydown(e) {
      if (e.keyCode == 13) {
        this.logIn();
      }
    },

    // 验证码登录获取验证码
    getSms() {
      this.$JlkMessage("获取验证码", "error");
    },
    // 切换账号登录或者验证码登录
    getMessage() {
      this.isQRCode = 3;
      this.loginTitle = "验证码登录";

      this.otherMethods = [
        {
          iconfont: "icon-a-1-3",
          method: "getInfoLogin",
          content: "账号密码登录",
        },
        {
          iconfont: "icon-saomadenglu",
          method: "getWechantScan",
          content: "微信扫码登录",
        },
      ];
    },
  },
  $route() {
    if (this.$route.query.code != undefined) {
      this.getWeixin(this.$route.query.code);
    }
  },
});
</script>
<style lang="scss" scoped>
@import "@/styles/mixin.scss";

.code_btn {
  position: relative;

  .verificationCodeBtn {
    position: absolute;
    right: 0px;
    top: 6px;
    width: 120px;
    border-left: 1px solid #e1e1e1;
    cursor: pointer;
    text-align: center;

    span {
      padding: 7px 20px;
      font-size: 16px;
      font-weight: bold;
      color: $primary-color;
    }
  }
}

::-webkit-input-placeholder {
  /*Webkit browsers*/
  color: #000000 !important;
  font-size: 16px;
}

:-moz-placeholder {
  /*Mozilla Firefox 4 to 8*/
  color: #000000 !important;
  font-size: 16px;
}

::moz-placeholder {
  /*Mozilla Firefox 19+*/
  color: #000000 !important;
  font-size: 16px;
}

:-ms-input-placeholder {
  /*Internet Explorer 10+*/
  color: #000000 !important;
  font-size: 16px;
}

.qrCodeLayout {
  #weixin {
    color: red;

    html {
      color: red;
    }

    .impowerBox {
      color: red;

      .wrp_code {
        .qrcode {
          width: 160px;
        }
      }
    }
  }
}

.codeContain {
  margin-top: 80px;
  display: flex;
  justify-content: center;
}

.verifiCodeTip {
  padding: 20px;
  background: rgba(248, 248, 248, 1);
  width: 600px;
  height: 156px;
  box-sizing: border-box;

  p {
    margin-top: 8px;
    font-size: 14px;
    color: #666666;
    text-align: left;
    font-weight: 400;
  }
}
</style>

import { Notification, Message, Loading, MessageBox } from 'element-ui'

let loading = null
let timer = null

export function JlkMessage(message, type) {
  // type: success,info,warning,error
  const success = 'icon-chenggong1'
  const warning = 'icon-qita'
  const error = 'icon-cuowu'
  const myIconClass = { success, warning, error }
  const iconClass = `iconfont ${myIconClass[type]}`
  const showClose = true
  const duration = 3000
  const offset = 106 + 16
  const center = true
  Message({ showClose, duration, offset, center, iconClass, message, type })
}
export function JlkNotify(title, message) {
  const duration = 3000
  const offset = 100
  Notification({ title, duration, offset, message })
}

export function JlkConfirm(title, message, confirmButtonText, cancelButtonText, type, fn1, fn2) {
  const iconClass = `iconfont ${type}`
  MessageBox.confirm(message, title, {
    confirmButtonText,
    cancelButtonText,
    iconClass,
  })
    .then(() => {
      fn1()
    })
    .catch(() => {
      fn2()
    })
}
export function JlkLoading(type, text) {
  if (type === 'open') {
    const lock = true
    const spinner = 'el-icon-loading'
    const background = 'rgba(0, 0, 0, 0.7)'
    loading = Loading.service({ lock, text, spinner, background })
    // timer = setTimeout(() => {
    //   if (loading) loading.close()
    //   timer = null
    //   clearTimeout(timer)
    // }, 2000)
  } else if (type === 'close') {
    clearTimeout(timer)
    if (loading) loading.close()
  }
}

<template>
  <div class="pwd_contain">
    <div class="pass_label">
      密码规则：长度6到20位，支持数字、字母、符号
    </div>
    <el-form class="formItem" ref="formRef" :model="formData" :rules="rules">
      <el-form-item prop="old_pwd" label="当前登录密码" style="width: 100%">
        <el-input style="width: 100%" type="password" v-model="formData.old_pwd" placeholder="请输入当前密码" />
      </el-form-item>
      <el-form-item label="新密码" prop="pwd" style="width: 100%">
        <el-input style="width: 100%" type="password" v-model="formData.pwd" placeholder="新密码与当前密码不能相同" />
      </el-form-item>
      <el-form-item label="确认密码" prop="re_pwd" class="last-item" style="width: 100%">
        <el-input style="width: 100%" type="password" v-model="formData.re_pwd" placeholder="确认密码和新密码保持一致" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

import { $_md5 } from "@/utils/md5";
import { cloneDeep } from 'lodash'
export default {
  data() {
    const confirmPwd = (rule, value, callback) => {
      if (value && value !== this.formData.pwd) {
        return callback(new Error("两次输入的密码不一致!"));
      } else {
        callback();
      }
    };
    const validatePwd = (rule, value, callback) => {
      if (!value || value === "") {
        callback();
      } else if ((value && value.length < 6) || (value && value.length > 20)) {
        callback(new Error("请输入6-20个字符的密码"));
      } else if (!/^[A-Za-z0-9!@#$%]+$/.test(value)) {
        callback(new Error("密码格式不正确"));
      } else {
        callback();
      }
    };
    return {
      formData: {
        old_pwd: "",
        pwd: "",
        re_pwd: "",
      },
      rules: {
        old_pwd: [
          {
            required: true,
            message: "请输入当前密码",
            trigger: "blur",
          },
          { validator: validatePwd, trigger: "blur" },
        ],
        pwd: [
          {
            required: true,
            trigger: "blur",
            message: "请输入新密码",
          },
          { validator: validatePwd, trigger: "blur" },
        ],
        re_pwd: [
          {
            required: true,
            trigger: "blur",
            message: "请再次输入新密码",
          },
          { validator: confirmPwd, trigger: "blur" },
        ],
      },
    }
  },
  methods: {
    forgotPassword() {
      this.$router.push({ path: "/forgotPassword" });
      this.$emit("close");
    },
    handleOk() {
      return new Promise((resolve, reject) => {
        this.$refs.formRef.validate(async (valid) => {
          if (valid) {
            const cloneData = cloneDeep(this.formData);
            cloneData.old_pwd = $_md5(cloneData.old_pwd);
            cloneData.pwd = $_md5(cloneData.pwd);
            cloneData.re_pwd = $_md5(cloneData.re_pwd);
            const ret = await this.$API.putAccountPwd(cloneData);  // updatePass
            if (ret && ret.ok) {
              this.$JlkMessage('更新成功', "success")
              resolve();
            }

          }
        });
      });
    }

  },
}
</script>

<style lang="scss" scoped>
.pwd_contain {
  .pass_label {
    font-weight: bold;
    font-size: 14px;
    padding-bottom: 20px;
  }
}

.forgetPass {
  color: #0BA976;
  cursor: pointer;
  font-weight: bold;
  position: absolute;
  right: 0px;
  top: 0px;
}

:deep(.el-form-item__label) {
  display: flex !important;
  justify-content: flex-start;
  padding: 0;
}

:deep(.el-form-item) {
  margin-bottom: 30px !important;
}

:deep(.last-item.el-form-item) {
  margin-bottom: 0 !important;
}
</style>

import dialog from "@/components/publicComponents/Dialog";
import "@/plugins/element";
import { Fixed, isEmptyObject, toFixed, toPercent } from "@/utils/handleData";
import { JlkLoading, JlkMessage, JlkNotify } from "@/utils/message";
import "default-passive-events";
import VueCropper from "vue-cropper";
import moment from "moment";

// 复制到粘贴板
import VueViewer from "v-viewer";
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import App from "./App.vue";
import JlkCard from "./components/professionalComponents/jlkCard/index.vue";
import JlkContentHeader from "./components/professionalComponents/JlkContentHeader/index.vue";
import JlkReportHeader from "./components/professionalComponents/jlkReportHeader/index.vue";
import JlkButton from "./components/publicComponents/JlkButton/index.vue";
import JlkButtonText from "./components/publicComponents/JlkButton/jlkButtonText.vue";
import JlkContainer from "./components/publicComponents/JlkContainer/index.vue";
import JlkContent from "./components/publicComponents/JlkContent/index.vue";
import JlkDialog from "./components/publicComponents/JlkDialog/index.vue";
import JlkFragPrompt from "./components/publicComponents/JlkFragPrompt/index.vue";
import JlkInfoButton from "./components/publicComponents/JlkInfoButton/index.vue";
import JlkInfoContain from "./components/publicComponents/JlkInfoContain/index.vue";
import JlkPagination from "./components/publicComponents/JlkPagination/index.vue";
import JlkRichText from "./components/publicComponents/JlkRichText/index.vue";
import JlkTable from "./components/publicComponents/JlkTable/index.vue";
import JlkTabs from "./components/publicComponents/JlkTabs/index.vue";
import JlkTotal from "./components/publicComponents/JlkTotal/index.vue";
import JlkUploads from "./components/publicComponents/JlkUploads/index.vue";
import QueryTable from "./components/publicComponents/QueryTable/index.vue";
import UploadImg from "./components/publicComponents/uploadImg/index.vue";
import router from "./router";
import * as API from "./services/index";
import store from "./store";
import "./styles/index.scss";
import $utils from "./utils/index";
import "./plugins/directive";
Vue.use(VueCropper);
Vue.use(VueClipboard);
Vue.use(VueViewer);
VueClipboard.config.autoSetContainer = true;

Vue.config.productionTip = false;

Vue.prototype.$API = API.default;
Vue.prototype.$utils = $utils;
Vue.prototype.$dialog = dialog;
Vue.prototype.$JlkMessage = JlkMessage;
Vue.prototype.$JlkNotify = JlkNotify;
Vue.prototype.$JlkLoading = JlkLoading;
Vue.prototype.$isEmptyObject = isEmptyObject;
Vue.prototype.$Fixed = Fixed;
Vue.prototype.$toFixed = toFixed;
Vue.prototype.$toPercent = toPercent;
Vue.prototype.$moment = moment;
Vue.component("JlkContentHeader", JlkContentHeader);
Vue.component("JlkReportHeader", JlkReportHeader);
Vue.component("JlkCard", JlkCard);
Vue.component("JlkTable", JlkTable);
Vue.component("JlkPagination", JlkPagination);
Vue.component("JlkDialog", JlkDialog);
Vue.component("QueryTable", QueryTable);
Vue.component("JlkButton", JlkButton);
Vue.component("JlkButtonText", JlkButtonText);
Vue.component("JlkContent", JlkContent);
Vue.component("JlkInfoButton", JlkInfoButton);
Vue.component("JlkContainer", JlkContainer);
Vue.component("JlkTabs", JlkTabs);
Vue.component("JlkRichText", JlkRichText);
Vue.component("JlkInfoContain", JlkInfoContain);
Vue.component("JlkUploads", JlkUploads);
Vue.component("UploadImg", UploadImg);
Vue.component("JlkTotal", JlkTotal);
Vue.component("JlkFragPrompt", JlkFragPrompt);
new Vue({
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
/*
  1.组件
    publicComponents
        1.这个下面的组件放到其他地方也可以使用
          - 目前就放了一个表格组件,里面带有分页组件,固定了分页器大小,表格会占剩下的区域,使用的时候用一个容器包起来,组件的大小是根据这个容器来决定的
    professionalComponents
        1.这个下面的组件放置的是耦合的组件
          - jlkContentHeader是内容区域的头部,为了少些一些结构而写的,
          - jlkCard同上,
  2.请求
      拦截器封装再utils里面,具体的请求封装再services里面
  3.路由
      -登录路由
      -layout路由
          -是一个嵌套路由,头部,侧边栏是固定的,中间区域会变化
          - 动态路由生成实在路由守卫里面做的，调用addRoute方法,注意的:因为有些三级路由有新增修改按钮,点击去到地方也是一个路由组件,所有在判断他有这个三级路由的时候要生成相应的新增修改路由,关于有没有这个
            新增修改的权限,可以在按钮上面做,权限都在vuex里面
  4.css
      已经配置好,可以在全局使用变量和混合
*/

import { filteParams } from "@/utils/handleData.js";
import request from "@/utils/request";
// 等级管理列表
export const getGradeList = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/vip-grades",
    params,
  });
};

// 等级的字典表
export const getDictList = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/grade",
    params,
  });
};
// 修改等级详情数据
export const updateGrade = (id, data = {}) => {
  return request({
    method: "PUT",
    url: `/api/vip-grades/${id}`,
    data,
  });
};
//权益列表信息
export const getpriviless = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/grade-privileges",
    params,
  });
};
// 删除等级权益
export const delePrivliless = (id, params = {}) => {
  return request({
    method: "DELETE",
    url: `/api/grade-privileges/${id}`,
    params,
  });
};

// 修改等级权益
export const updatePriviless = (id, data) => {
  return request({
    method: "PUT",
    url: `/api/grade-privileges/${id}`,
    data,
  });
};

// 新增等级权益
export const addPriviless = (data) => {
  return request({
    method: "POST",
    url: "/api/grade-privileges",
    data,
  });
};

// 获取权益信息
export const getPrivilessDetail = (id, params) => {
  return request({
    method: "GET",
    url: `/api/grade-privileges/${id}`,
    params,
  });
};

// 获取优惠券列表
export const getCouponsList = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/coupons/choose",
    params,
  });
};
// 保存充值方案信息

export const saveCase = (data) => {
  return request({
    method: "POST",
    url: "/api/recharge-rule",
    data,
  });
};
// 删除充值方案信息
export const delCase = (id, data) => {
  return request({
    method: "DELETE",
    url: `/api/recharge-rule/${id}`,
    data,
  });
};

// 获取充值说明
export const getdes = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/system",
    params,
  });
};

// 设置充值说明
export const setDes = (data = {}) => {
  return request({
    method: "POST",
    url: "/api/system",
    data,
  });
};

// 查询单个充值方案详情
export const getOneRecharge = (id, create_date, params = {}) => {
  return request({
    method: "GET",
    url: `/api/recharge-rule/${id}`,
    params,
  });
};

// 充值方案字典
export const getDictChargeDict = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/recharge-rule",
    params,
  });
};

//新增会员卡配置
export const addBaseMemberCard = (data) => {
  return request({
    method: "POST",
    url: "/api/vip-card-config/base-info",
    data,
  });
};
export const updateBaseMemberCard = (data) => {
  return request({
    method: "PUT",
    url: "/api/vip-card-config/base-info",
    data,
  });
};

// 新增会员卡等级经验信息
export const addGradeInfo = (data) => {
  return request({
    method: "POST",
    url: "/api/vip-card-config/grade-exp",
    data,
  });
};
// 更新
export const updateGradeInfo = (data) => {
  return request({
    method: "PUT",
    url: "/api/vip-card-config/grade-exp",
    data,
  });
};

export const updateShareInfo = (data) => {
  return request({
    method: "PUT",
    url: "/api/vip-card-config/share-info",
    data,
  });
};
// 获取会员卡信息

export const getCardInfo = (data) => {
  return request({
    method: "GET",
    url: "/api/vip-card-config",
    data,
  });
};

// 获取激活字典信息
export const getfiledList = (params) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/activation-filed",
    params,
  });
};

// 赠送礼物
export const giveGift = (data) => {
  return request({
    method: "POST",
    url: "/api/vip-infos/send-gift",
    data,
  });
};
// 会员概况
export const memberProfile = (params) => {
  return request({
    method: "GET",
    url: "/api/report/vip-key-report",
    params: filteParams(params),
  });
};
//会员消费排行
export const consumeTop = (params) => {
  return request({
    method: "GET",
    url: "/api/report/vip-consumetop-report",
    params: filteParams(params),
  });
};

//会员充值排行
export const rechargeTop = (params) => {
  return request({
    method: "GET",
    url: "/api/report/vip-rechargetop-report",
    params: filteParams(params),
  });
};

// 会员统计折线图
export const briskReport = (params) => {
  return request({
    method: "GET",
    url: "/api/report/vip-brisk-report",
    params: filteParams(params),
  });
};

export const getSurvery = (params) => {
  return request({
    method: "GET",
    url: "/api/vip-infos/survey",
    params,
  });
};

export const vipChargeInfo = (params, id) => {
  return request({
    method: "GET",
    url: `/api/vip-infos/recharge-records?vip_id=${id}`,
    params,
  });
};

// 充值流水
export const getChargeFlow = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/recharge-rule/flow",
    params,
  });
};

// 会员新增走势
export const getVipAddTrend = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/report/vip-add-trend",
    params,
  });
};

// 会员充值走势
export const getVipRechargeTrend = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/report/vip-recharge-trend",
    params,
  });
};

// 会员排名
export const getVipRanking = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/report/vip-ranking",
    params,
  });
};

// 累计积分渠道分析
export const getVipPointAnaly = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/report/vip-point-analysis",
    params,
  });
};

// 累计储值渠道分析
export const getVipStoreAnaly = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/report/vip-stored-value-analysis",
    params,
  });
};

// 实时数据

export const getRealTimeData = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/report/real-time-data",
    params,
  });
};
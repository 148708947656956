<template>
  <div style="padding: 20px 30px">
    <div style="color:#2B2B2B;font-size:16px;font-weight:bold;margin-bottom: 38px;">基本资料</div>
    <el-form ref="formData" :model="formData" :rules="rules" class="formItem">
      <el-row>
        <el-col :span="12">
          <el-form-item label="编号" prop="base_account_id">
            <el-input v-model="formData.base_account_id" :placeholder="'编号'" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="手机号码" prop="phone" class="base_no">
            <el-input type="text" disabled v-model="formData.phone" :placeholder="'手机号码'">
              <span slot="suffix" class="iconfont icon-fuzhi" style="cursor: pointer;z-index: 1000;"
                @click="getClick(formData.phone)"></span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="姓名" prop="actual_name">
            <el-input v-model="formData.actual_name" maxlength="40" :placeholder="'姓名'" clearable />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="性别" prop="gender">
            <el-select v-model="formData.gender" :placeholder="'性别'">
              <el-option v-for="item in GenderListEnum" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="电子邮箱" prop="email">
            <el-input v-model="formData.email" maxlength="40" :placeholder="'电子邮箱'" clearable />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="身份证号码" prop="id_card">
            <el-input v-model="formData.id_card" maxlength="18" :placeholder="'身份证号码'" clearable />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-button type="primary" @click="onSubmit">更新信息</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    initData: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    var checkEmail = (rule, value, cb) => {
      if (value) {
        var reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        if (!reg.test(value)) {
          return cb("请输入有效的邮箱地址")
        }
        cb()
      }
      cb()
    };
    var checkCard = (rule, value, cb) => {
      if (value) {
        var reg = /(^\d{15}$)|(^\d{17}([0-9]|X)$)/
        if (!reg.test(value)) {
          return cb("请输入正确的身份证号码")
        }
        cb()
      }
      cb()
    };
    var checkActual = (rule, value, cb) => {
      if (value) {
        var reg = /^[A-Za-z0-9\u4e00-\u9fa5]+$/
        if (!reg.test(value)) {
          return cb("只支持输入中英文，数字")
        }
        cb()
      }
      cb()
    };
    return {
      GenderListEnum: [
        {
          label: '男',
          value: 1
        },
        {
          label: '女',
          value: 2
        }
      ],
      formData: {
        base_account_id: "",
        phone: "",
        actual_name: "",
        gender: "",
        email: "",
        id_card: ""
      },
      rules: {
        email: [
          {
            validator: checkEmail, trigger: 'blur'
          }
        ],
        id_card: [
          {
            validator: checkCard, trigger: 'blur'
          }
        ],
        actual_name: [{
          validator: checkActual, trigger: 'blur'
        }]
      },
    };
  },


  methods: {
    getClick(row) {
      this.$copyText(row).then(
        (res) => {
          this.$JlkMessage("复制成功", "success");
        },
        (err) => {
          console.log(err);
          this.$JlkMessage("复制失败", "error");
        }
      );
    },

    async onSubmit() {
      let params = {
        actual_name: "",
        gender: "",
        email: "",
        id_card: ""
      }
      Object.keys(params).forEach(item => {
        params[item] = this.formData[item]
      })
      const valid = await this.$refs.formData.validate()
      if (valid) {
        const ret = await this.$API.putPersonalInfo(params);
        if (ret && ret.ok) {
          this.$JlkMessage("更新成功", "success")
          this.$emit("init")
        }
      }
    },
  },
  watch: {
    initData: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          console.log('执行')
          Object.assign(this.formData, this.initData)
        }
      },
      immediate: true,
      deep:true
    }
  },
};
</script>

<style lang="scss" scoped>
.jlk-input {
  :deep(.svg-icon) {
    color: #666666;
  }
}

.base_no {
  ::v-deep.el-input {
    .el-input__suffix {
      z-index: 1000;
    }
  }

}

.avatar_contain {
  display: flex;
  align-items: flex-end;

  .avatar_img {
    width: 100px;
    height: 100px;
    margin-right: 20px;
    border-radius: 50%;
  }

  .avatar_name {
    width: 100px;
    height: 100px;
    margin-right: 20px;
    border-radius: 50%;
    background: #0ba976;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 48px;
    font-weight: bold;
  }
}

:deep(.el-form-item) {
  margin-bottom: 30px !important;
}

:deep(.el-select__wrapper) {
  height: 38px;
}
</style>

import Vue from 'vue';


//  点击输入禁止使用15s
Vue.directive('disable-button', {
    bind: function (el, binding, vnode) {
        // 获取传入的时间值，默认为15秒
        const time = binding.value || 15000;

        // 在按钮点击事件中禁用按钮
        el.addEventListener('click', function () {
            el.disabled = true;
            el.classList.add('btn_disabled');

            // 在指定时间后启用按钮
            setTimeout(function () {
                el.disabled = false;
                el.classList.remove('btn_disabled');
            }, time);
        });
    }
});
<template>
  <div class="flexCenter" style="border-radius: 4px; border: 1px solid #dcdfe6;overflow: hidden;background: #fff;">
    <el-select
      style="width: 120px;"
      size="mini"
      v-model="queryForm[prop[0]]"
      :placeholder="placeholder"
      @change="eventCallSearchCondition"
    >
      <el-option
        v-for="(item, index) in options"
        :key="index"
        :label="item[propsConfig.label]"
        :value="item[propsConfig.value]"
      />
    </el-select>
    <el-input
      style="width: 200px;"
      size="mini"
      v-model="queryForm[prop[1]]"
      clearable
      :placeholder="inputPlaceholder"
      @blur="eventCallSearchCondition"
      @clear="eventCallSearchCondition"
      @keyup.enter.native="eventCallSearchCondition"
    />
  </div>
</template>

<script>
export default {
  props: {
    prop: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    queryForm: {
      type: Object,
      default: () => {}
    },
    propsConfig: {
      type: Object,
      default: () => {}
    },
    placeholder: {
      type: String,
      default: ''
    },
  },
  computed: {
    inputPlaceholder() {
      const obj = this.options.find(item => this.queryForm[this.prop[0]] === item[this.propsConfig.value])
      let result = obj ? obj[this.propsConfig.label] : this.placeholder
      return '请输入' + result
    }
  },
  methods: {
    eventCallSearchCondition() {
      this.$emit('change')
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-input__inner {
      border-width: 0px;
      border-radius: 0px;
      font-size: 14px;
    }
    .el-select {
      margin-left: 0 !important;
      input.el-input__inner {
        height: 28px !important;
        line-height: 28px !important;
        background-color: #fff !important;
        border-right: 1px solid #dcdfe6 !important;
      }
    }
  }
</style>

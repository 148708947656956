import request from '@/utils/request'
//

export const getCloudBackList = (params) => {
  return request({
    method: 'GET',
    url: '/api/cloud-backups',
    params,
  })
}

export const getBranchBackups = (branchId) => {
  return request({
    method: 'GET',
    url: `/api/cloud-backups/${branchId}`,
  })
}

// 
export const getOpenService = (params) => {
  return request({
    method: "GET",
    url: '/api/dictionaries/branch-open-service-status',
    params
  })
}

export const getCloudOpt = (data) => {
  return request({
    method: 'put',
    url: '/api/cloud-backups/opr',
    data
  })
}

// 获取云备份记录

export const getCloudRecord = (params) => {
  return request({
    method: 'GET',
    url: '/api/cloud-backups/logs',
    params
  })
}

// 是否开通云备份

export const checkOpenService = (params) =>{
  return request({
    method:"GET",
    url:'/api/merchants/open-service-status',
    params
  })
}
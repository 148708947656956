import Vue from 'vue'
import dialog from './Dialog.vue'
import { deepCopy } from '@/utils/handleData'
let dialogConstructor = Vue.extend(dialog)
let theDialog = function(components, componentProps, configProps) {
  let dialogDom = new dialogConstructor({
    el: document.createElement('div')
  })
  //new一个对象，然后插入body里面
  document.body.appendChild(dialogDom.$el)
  dialogDom.visible = true
  //为了使dialog的扩展性更强，这个采用对象的方式传入，所有的字段都可以根据需求自定义
  dialogDom.content = components
  dialogDom.componentProps = componentProps
  const result = deepCopy(configProps)
  result.customStyle = Object.assign(dialogDom.customStyle || {}, result.customStyle)
  Object.assign(dialogDom.configProps, result)
  Object.assign(dialogDom, {
    '$store': this.$store
  })
}
export default theDialog

<template>
  <div class="uploadContainer">
    <div class="done" v-for="(item, index) in imageUrl" :key="index">
      <img :src="item" />
      <div class="operationImg">
        <i class="el-icon-zoom-in" @click="showBigImg(item)"></i>
        <i class="el-icon-delete" @click="delImg(index)"></i>
      </div>
    </div>
    <div
      class="uploading"
      v-if="imageUrl.length < imageMax"
      @click="eventCallUpload"
    >
      <span class="iconfont icon-tianjiatubiao1"></span>
      <div class="selectIcon">{{ title }}</div>
    </div>
    <el-upload
      ref="uploadRef"
      style="display: none"
      class="avatar-uploader"
      action="https://jsonplaceholder.typicode.com/posts/"
      :show-file-list="false"
      :http-request="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
    >
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "uploadImg",
  props: {
    imageMax: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    imgUrl: {
      default: () => "",
    },
    title: {
      default: () => "选择图片",
    },
  },
  data() {
    return {
      imageUrl: [],
      dialogVisible: false,
      dialogImageUrl: "",
    };
  },
  watch: {
    imgUrl(newVal) {
      console.log(newVal);
      if (!newVal) {
        this.imageUrl = [];
        return;
      }
      if (newVal && Array.isArray(newVal)) {
        this.imageUrl = newVal;
        return;
      }
      this.imageUrl = [];
      this.imageUrl.push(newVal);
    },
  },

  mounted() {
    if (this.imgUrl) {
      let arr = [];
      arr.push(this.imgUrl);
      this.imageUrl = arr;
    }
  },

  methods: {
    loadDefaltImg(imageUrls) {
      this.imageUrl = imageUrls;
    },
    showBigImg(url) {
      this.dialogImageUrl = url;
      this.dialogVisible = true;
    },
    delImg(index) {
      this.imageUrl.splice(index, 1);
      if (this.imageMax === 1) {
        this.$emit("setUploadFiles", "");
      } else {
        this.$emit("setUploadFiles", this.imageUrl);
      }
    },
    eventCallUpload() {
      if (this.disabled) {
        return this.$JlkMessage("请选择背景封面", "warning");
      }
      this.$refs.uploadRef.$refs["upload-inner"].$refs.input.click();
    },
    beforeAvatarUpload(file) {
      const isJPG = ["image/jpeg", "image/jpg", "image/png"].includes(
        file.type
      );
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (!isJPG) {
        this.$JlkMessage("图片只能是jpg,jpeg,png", "error");
        return false;
      }
      if (!isLt1M) {
        this.$JlkMessage("图片大小不能超过 1MB!", "error");
        return false;
      }
      return isJPG && isLt1M;
    },
    async handleAvatarSuccess(options) {
      const fd = new FormData();
      fd.append("file", options.file);
      const result = await this.$API.uploading(fd);
      if (result && result.ok) {
        this.imageUrl.push(result.data);
        console.log(this.imageUrl);
        if (this.imageMax === 1) {
          console.log(this.imageUrl[0]);
          this.$emit("setUploadFiles", this.imageUrl[0]);
        } else {
          this.$emit("setUploadFiles", this.imageUrl);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.uploadContainer {
  display: flex;
  flex-wrap: wrap;
  .done {
    width: 100px;
    height: 100px;
    position: relative;
    .operationImg {
      background: rgba(0, 0, 0, 0.5);
      height: 100px;
      width: 100px;
      border-radius: 6px;
      position: absolute;
      top: 0;
      color: #fff;
      font-size: 20px;
      display: none;
      align-items: center;
      justify-content: space-around;
      i {
        cursor: pointer;
      }
    }
    &:hover {
      .operationImg {
        display: flex;
      }
    }
    img {
      width: 100px;
      height: 100px;
      border-radius: 8px;
      margin-right: 10px;
      object-fit: cover;
    }
  }
  .uploading {
    height: 100%;
    padding: 20px 22px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    .iconfont {
      color: $grounding-color;
      font-size: 30px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      margin-bottom: 10px;
    }
    .selectIcon {
      font-size: 14px;
      color: #ffffff;
      font-weight: 400;
      font-family: "PingFang Medium";
      height: 20px;
    }
  }
}
</style>

<script>
export default {
  props: {
    content:{
      type: [Object, String, Number]
    }
  },
  render() {
    return <div>{this.content}</div>
  }
}
</script>


<template>
  <div class="warpHeader">
    <div class="warpHeaderTop">
      <div class="_left">
        <img src="https://jlk-sys-image.oss-cn-zhangjiakou.aliyuncs.com/202208151056534746160042807478817.png" alt="" />
        <div class="right_line"></div>
        <div>{{ isVip ? "聚哒客商户中心" : "聚哒客·微会员" }}</div>
      </div>
      <div class="_right">
        <div style="display: flex; align-items: center" @mouseleave="getLevel">
          <span @mouseenter="getEnter" :class="['ioncQrcode']" style="margin-right: 20px; position: relative"
            v-if="merchant && merchant.merchant_id">
            <img :src="enterActive
              ? 'https://jlk-sys-image.oss-cn-zhangjiakou.aliyuncs.com/202403211134575399388690933112504.png'
              : 'https://jlk-sys-image.oss-cn-zhangjiakou.aliyuncs.com/202403211134544775487027818416928.png'
              " alt="" />
            <div class="qrcode" v-show="enterActive">
              <span class="out"></span>
              <span class="iner"></span>
              <img referrerpolicy="no-referrer" :src="memberInfo && memberInfo.applets_qrcode
                ? memberInfo.applets_qrcode
                : 'https://jlk-sys-image.oss-cn-zhangjiakou.aliyuncs.com/202403211708165218232846072740370.png'
                " class="img" v-if="memberInfo" />
              <div class="qrcode_title">
                {{
                  memberInfo && memberInfo.applets_qrcode
                    ? "微会员小程序码"
                    : "小程序码未配置"
                }}
              </div>
              <div class="qrcode_tips">
                {{
                  memberInfo && memberInfo.applets_qrcode
                    ? "使用微信扫码"
                    : "请联系服务商处理"
                }}
              </div>
            </div>
          </span>
          <span @mouseenter="enterActive = false" @click="showUserInfo = !showUserInfo" class="el-dropdown-link">
            {{
              this.$store.state.user.user.actual_name ? this.$store.state.user.user.actual_name :
                this.$store.state.user.user.phone
            }}<i v-if="isVip" class="iconfont icon-ziyuan197" style="margin-left: 10px; font-size: 12px"></i>
          </span>
        </div>
      </div>
    </div>
    <NavbarDialog v-if="showUserInfo && isVip" :userInfo="formDataUserInfo"></NavbarDialog>
  </div>
</template>
<script>
import Vue from "vue";
import NavbarDialog from "./components/NavbarDialog.vue";
export default Vue.extend({
  name: "jlkHeader",
  components: { NavbarDialog },
  data() {
    return {
      registerInfoList: [],
      showUserInfo: false,
      formDataUserInfo: '',
      isVip: "",
      memberInfo: {},
      enterActive: false,
      merchant: "",
      defaultSettings: {
        version: "0.01",
      },
    };
  },
  mounted() {
    this.getPersonInfo()
    this.userInfo = sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : "";
    this.merchant =
      this.$route.query && this.$route.query.merchant_id
        ? this.$route.query
        : sessionStorage.getItem("merchant")
          ? JSON.parse(sessionStorage.getItem("merchant"))
          : "";
    this.isVip = sessionStorage.getItem("isVip") === "true" ? true : false;
    if (this.userInfo && this.merchant.merchant_id) {
      this.getMemberInfo();
    }
  },
  methods: {
    openUpdatePwd() {
      this.$dialog(
        // UpdatePwd,
        {
          ok: () => { },
        },
        {
          title: "更改密码",
          width: 460,
          okText: "确定",
          footer: true,
        }
      );
    },

    getEnter() {
      this.enterActive = true;
    },
    getLevel() {
      this.enterActive = false;
    },
    closeUserInfo() {
      this.showUserInfo = false;
    },
    async getPersonInfo() {
      const ret = await this.$API.getPersonalInfo()
      if (ret && ret.ok) {
        this.formDataUserInfo = ret.data
      }
    },
    async getMemberInfo() {
      const ret = await this.$API.getMerchantDetailInfo(
        this.merchant.merchant_id
      );
      if (ret && ret.ok) {
        this.memberInfo = ret.data;
        sessionStorage.setItem("memberInfo", JSON.stringify(ret.data));
      }
    }
  },
});
</script>
<style lang="scss" scoped>
.user-dialog-container {
  width: 320px;
  box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  position: fixed;
  right: 0;
  top: 46px;
  margin: 5px 10px;
  transition: height 0.1s;
  z-index: 999;
  overflow: hidden;
  border-radius: 12px;
  box-sizing: border-box;

  .user_contain {
    padding: 30px 20px;
    flex-direction: column;
    display: flex;
    align-items: center;

    .user_img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }

    .user_img_name {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: #0ba976;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      font-weight: bold;
    }

    .user_name {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      padding-top: 15px;
    }

    .user_phone {
      text-align: center;
      color: #666666;
      font-size: 14px;
      padding-top: 10px;
    }

    .account_btn {
      margin-top: 15px;
      height: 40px;
      width: 200px;
    }
  }
}

.dia-footer {
  height: 50px;
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #666;
  border-top: 1px solid #f2f2f2;

  &:hover {
    background-color: #ff4a4a;
    color: #fff;
  }
}

.line {
  width: 100%;
  height: 1px;
  background: #f2f2f2;
}

.warpHeader {
  height: 100%;
  display: flex;
  flex-direction: column;

  .qrcode {
    background: #fff;
    padding: 20px 35px;
    box-sizing: border-box;
    position: absolute;
    width: 220px;
    top: 42px;
    right: -14px;
    box-shadow: 0px 5px 10px 1px rgba(11, 169, 118, 0.05);
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #f5f5f5;
    text-align: center;
    z-index: 1;

    span {
      position: absolute;
      right: 20px;
      height: 0px;
      width: 0px;
    }

    /*上部小三角实现样式开始*/
    span.out {
      line-height: 0;
      border-width: 10px;
      border-color: transparent transparent #f5f5f5 transparent;
      border-style: dashed dashed solid dashed;
      top: -20px;
    }

    span.iner {
      border-width: 10px;
      border-color: transparent transparent #fff transparent;
      border-style: dashed dashed solid dashed;
      top: -19px;
      line-height: 0;
    }

    .img {
      height: 160px;
      width: 160px;
    }

    .qrcode_title {
      font-weight: bold;
      font-size: 14px;
      color: #2b2b2b;
      margin: 10px 0px 5px;
    }

    .qrcode_tips {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #999999;
    }
  }

  .warpHeaderTop {
    position: unset;
    z-index: 102;
    height: 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 20px 15px;
    border-bottom: 1px solid #f2f2f2;
    @include mixin-font_16_b($secondary-color);

    ._right {
      .ioncQrcode {
        width: 30px;
        height: 30px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .icon-xiaochengxuxuanzhong {
        color: #0ba976;
      }

      .el-dropdown-link {
        cursor: pointer;
        color: #2b2b2b;
        font-weight: 700;
        font-size: 16px;

        &:hover {
          color: #0ba976 !important;

          .iconfont {
            color: #0ba976 !important;
          }
        }
      }

      .textBtn {
        color: #2b2b2b;
        margin-right: 30px;
      }
    }
  }

  .warpHeaderBottom {
    flex: 1;
    width: 100%;
  }
}

._left {
  display: flex;
  align-items: center;

  img {
    width: 38px;
    height: 38px;
    margin-right: 15px;
  }

  .right_line {
    height: 24px;
    background: #f2f2f2;
    width: 1px;
    margin-right: 15px;
  }
}

.el-dropdown-menu {
  padding: 0;
  width: 250px;

  .el-dropdown-menu__item:nth-of-type(1) {
    padding: 10px 20px;
    line-height: 20px;
    font-size: 12px;

    .userName {
      color: #2b2b2b;
      font-size: 16px;
      line-height: 30px;
    }
  }

  .el-dropdown-menu__item:nth-of-type(2) {
    color: #2b2b2b;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .el-button {
      padding: 8px 12px;
    }

    .balance {
      margin-left: 5px;
      color: #0ba976;
    }

    &>span {
      display: inline-flex;
      align-items: center;
    }
  }

  .el-dropdown-menu__item:hover,
  .el-dropdown-menu__item:focus {
    color: #666666;
    background: #f8f8f9;
  }

  .el-dropdown-menu__item--divided {
    margin: 0;
  }

  .el-dropdown-menu__item--divided:before {
    content: none;
    height: 0px;
  }

  .el-dropdown-menu__item:last-of-type {
    text-align: center;
    padding: 2px 20px;

    &::before {
      height: 0;
    }

    &:hover {
      color: #fff;
      background: #ff4a4a;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  .unreadMessages,
  .personalMessages,
  .changePassword {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #666666;
    padding: 5px 20px;

    .messages {
      display: flex;
      align-items: center;

      .el-button {
        margin-left: 5px;
        border-radius: 20px;
        padding: 4px 8px;
      }
    }
  }
}
</style>

<template>
  <div class="container">
    <div class="info_left">
      <div class="fontcolortitle" style="padding-bottom:15px">
        {{title}}
      </div>
      <div class="fontcolorcontent">
        {{content}}
      </div>
    </div>
    <div class="info_right">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    title: {
      default: ''
    },
    content: {
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 15px;
  background: #f2f2f2;
  opacity: 1;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}
</style>

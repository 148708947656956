<template>
  <el-dialog
    class="myDialog"
    :visible.sync="dialogVisible"
    :width="width"
    append-to-body
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <template #title>
      <i class="iconfont" :class="iconfont"></i>
      <span class="fontcolortitle">{{ title }}</span>
      <!-- 自定义副标题 -->
      <span class="fontcolorcontent" style="color: #666666; margin-left: 10px">
        <slot name="diyTitle"></slot>
      </span>
    </template>
    <slot></slot>
    <template #footer>
      <!-- 自定义底部按钮区域 -->
      <slot name="diyFooter"></slot>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "myDialog",
  components: {},
  mixins: [],
  props: {
    name: String,
    title: String, // 标题
    width: {
      default: "50%", // 宽度
      type: String,
    },
    iconfont: String, // icon
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  filters: {},
  computed: {},
  methods: {
    handleShow() {
      Object.assign(this, { dialogVisible: true });
    },
    handleClose() {
      Object.assign(this, { dialogVisible: false });
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.myDialog {
  z-index: 101;
}
</style>

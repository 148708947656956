import request from '@/utils/request'
export const getRoleList = (params = {}) => {
  return request({
    method: 'GET',
    url: '/api/roles',
    params
  })
}
export const addRoles = data => {
  return request({
    method: 'POST',
    url: '/api/roles',
    data
  })
}
export const changeRoles = (id, data) => {
  return request({
    method: 'PUT',
    url: '/api/roles/' + id,
    data
  })
}
export const deleteRoles = data => {
  return request({
    method: 'PUT',
    url: '/api/roles',
    data
  })
}
export const queryRolePermission = id => {
  return request({
    method: 'GET',
    url: `/api/roles/${id}/permissions`
  })
}
export const settingRolePermission = (id, data) => {
  return request({
    method: 'PUT',
    url: `/api/roles/${id}/permissions`,
    data: data
  })
}
export const queryMember = id => {
  return request({
    method: 'GET',
    url: `/api/roles/${id}/users`
  })
}
export const settingMember = (id, data) => {
  return request({
    method: 'PUT',
    url: `/api/roles/${id}/users`,
    data
  })
}
export const copyItem = data => {
  return request({
    method: 'POST',
    url: '/api/roles/copy',
    data
  })
}
export const queryRoleHandleLog = (id, params) => {
  return request({
    method: 'GET',
    url: `/api/roles/${id}/logs`,
    params
  })
}

<template>
    <div class="wrap">
        <div class="bg">
            <img src="https://jlk-sys-image.oss-cn-zhangjiakou.aliyuncs.com/202208101611155363936077474207177.png"
                alt="" srcset="" />
        </div>
        <div class="wrap_title">
            <img src="http://jlk-pay-image.oss-cn-zhangjiakou.aliyuncs.com/jlkLogo.png" alt="聚来客LOGO" />
            <div style="margin-left: 15px">聚哒客商户中心</div>
        </div>
        <div class="level2">
            <div class="loginContainer">
                <div class="loginTitle" style="margin-bottom: 10px;">账号验证</div>
                <div class="loginTitle" style="font-size: 14px;color:#666;margin: 0px 0px 20px;">首次登录需进行身份验证</div>
                <div v-if="isQRCode == 1 || isQRCode == 3">
                    <!--账号登录-->
                    <el-form :model="ruleForm" ref="ruleForm" v-show="isQRCode == 1" :rules="rules" class="login-form">
                        <el-form-item label="" prop="phone">
                            <el-input v-model="ruleForm.phone" disabled auto-complete="new-password" autocomplete="off"
                                placeholder="请输入手机号码"></el-input>
                        </el-form-item>
                        <el-form-item prop="captcha_code" class="code_btn">
                            <el-input v-model="ruleForm.captcha_code" placeholder="请输入验证码" auto-complete="new-password"
                                autocomplete="off" maxlength="8">
                            </el-input>
                            <div class="verificationCodeBtn">
                                <span @click="!loading && count ? '' : getCaptcha()">{{
                                    !loading && count ? count + "s" : "获取验证码"
                                }}</span>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="otherLogin">
                    <div style="
                    height: 58px !important;
                    width: 100%;
                    margin: 20px 0px 20px;
                  ">
                        <el-button type="primary" :loading="isLoginLoading"
                            style="height: 58px;width: 100%;font-size: 20px; font-weight: bold" @click="phonelogIn">登 录
                        </el-button>
                    </div>
                    <div class="otherMethod">
                        <span class="methodContain" @click="getCancel()">
                            <span class="content">取消 </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 忘记密码界面 -->
        <div class="info_bottom">
            <span>© {{ new Date().getFullYear() }} 湖南聚来客网络科技有限公司</span>
            <span style="cursor: pointer" @click="goPutOnRecords(1)">湘ICP备20012615号-6</span>
            <img src="https://jlk-sys-image.oss-cn-zhangjiakou.aliyuncs.com/202110141620195609576769661116266.png"
                alt="" srcset="" style="height: 20px; width: 20px" />
            <span style="cursor: pointer" @click="goPutOnRecords(2)">湘公网安备 43019002001153号</span>
        </div>
    </div>
</template>

<script>
import { $_md5 } from "@/utils/md5";
import { setToken, getToken, removeToken } from "@/utils/cookie.js";
import { wxRedirectUrl, wxAppid } from "@/utils/config";
export default {
    props: {
        formData: {
            type: Object,
            default: () => { }
        },
    },
    mounted() {
        window.addEventListener("keydown", this.keydown);
        this.ruleForm.phone = this.formData.account
    },
    data() {
        return {
            isLogin: true,
            isLoginLoading: false,
            automaticLogin: false,
            loginQRCode:
                "https://jlk-sys-image.oss-cn-zhangjiakou.aliyuncs.com/202107201123385501988503400300802.png",
            getCode: "获取验证码",
            isGeting: false, // 用于正在获取验证码时候的class绑定
            loginTitle: "账号登录",
            isQRCode: 1,
            toggleAccountSMSText: "手机验证码登录",
            noRelation: false,
            isMember: true,
            open: true,
            ruleForm: {
                phone: "",
                captcha_code: "",
            },

            otherMethods: [
                {
                    iconfont: "icon-weixinsaomadenglu",
                    method: "getWechantScan",
                    content: "取消",
                },
            ],
            rules: {
                phone: [
                    { required: true, message: "请输入手机号", trigger: "blur" },
                    {
                        validator: function (rule, value, callback) {
                            if (/^1[3-9]\d{9}$/.test(value) === false) {
                                callback(new Error("手机号格式错误"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur",
                    },
                ],
                captcha_code: [
                    {
                        required: true,
                        message: "请输入验证码",
                        trigger: "blur",
                    },
                ],
            },
            loading: false,
            count: 0,
            countDownTimer: null
        };
    },
    methods: {
        getCancel() {
            this.$emit('tabClick', 0)
        },
        async getCaptcha() {
            if (this.loading || !this.ruleForm.phone) return false;
            this.loading = true;
            try {
                const ret = await this.$API.getLoginCode({ phone: this.ruleForm.phone });
                if (ret && ret.ok) {
                    this.loading = false;
                    this.count = 60;
                    this.countDownTimer = setInterval(() => {
                        this.count--;
                        if (this.count <= 0) {
                            clearInterval(this.countDownTimer);
                            this.count = 0
                        }
                    }, 1000);
                } else {
                    this.loading = false;
                }
            } catch (error) {
                this.loading = false;
            }
        },
        goPutOnRecords(e) {
            const url =
                e === 1
                    ? "https://beian.miit.gov.cn/#/Integrated/index"
                    : "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43019002001299";
            window.open(url); // 新窗口打开外链接
        },
        async phonelogIn() {
            await this.$refs.ruleForm.validate();
            const ret = await this.$API.phoneLogin(this.ruleForm);
            if (ret && ret.ok) {
                this.logIn()
            }
        },
        async logIn() {
            console.log(this.formData)
            let form = JSON.parse(JSON.stringify(this.formData));
            // 记住密码
            if (this.automaticLogin) {
                // 如果勾选了记住账号密码
                setToken(JSON.stringify(form))
            } else {
                // 没有勾选就去除cookie
                removeToken();
            }
            // RSA加密
            form.pwd = $_md5(form.pwd);
            this.$JlkLoading("open", "用户登录中...");
            const ret = await this.$API.getPwdLogin(form);
            this.handleLoginInfo(ret)
        },
        handleLoginInfo(ret) {
            if (ret && ret.ok) {
                const data = ret.data;
                this.$store.dispatch("setToken", data).then(() => {
                    this.getPermissions()
                })
            } else if (ret.err.code == 8100) {
                //  未激活
                this.$confirm('系统账号体系全新升级，因您的手机号在不同的产品中拥有账号，导致无法登录，完成账号激活操作后即可恢复正常！?', '系统提示', {
                    confirmButtonText: '激活账号',
                    cancelButtonText: '取消',
                }).then(() => {
                    this.$emit("tabClick", 3)
                })
            } else if (ret.err.code == 8101) {
                // 未验证
                this.$emit("tabClick", 4, this.ruleForm)
            }
            this.$JlkLoading("close");
        },
        async getPermissions() {
            const ret = await this.$API.getPermissSource()
            if (ret && ret.ok) {
                this.$store.dispatch("tagsView/delAllViews");
                this.$JlkMessage("登录成功", "success");
                // 是否是十天免登陆
                this.$store.commit("setUser", ret.data)
                sessionStorage.setItem("isVip", true);
                this.$router.replace("/");
            } else if (ret.err.code == 8012) {
                // 是否关联手机号
                this.$emit('tabClick', 2)
            }
            this.$JlkLoading("close");
        },
    },
};
</script>

<style lang="scss" scoped>
.code_btn {
    position: relative;

    .verificationCodeBtn {
        position: absolute;
        right: 0px;
        top: 6px;
        width: 120px;
        border-left: 1px solid #e1e1e1;
        cursor: pointer;
        text-align: center;

        span {
            padding: 7px 20px;
            font-size: 16px;
            font-weight: bold;
            color: $primary-color;
        }
    }
}

.wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    opacity: 1;
    border-radius: 0px;

    .bg {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .info_bottom {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        display: flex;
        align-items: center;
        color: #fff;
        position: absolute;
        bottom: 56px;

        img {
            margin: 0px 10px;
        }
    }

    .bgImg {
        position: absolute;
        left: 140px;
        top: 140px;
        width: 1000px;
        height: 800px;
    }

    .hoverTips {
        display: none;
        position: absolute;
        top: 38px;
        right: 90px;

        .content {
            background: #ffffff;
            border: 1px solid rgba(68, 87, 207, 0.2);
            box-shadow: 0px 5px 10px rgba(68, 87, 207, 0.1);
            opacity: 1;
            border-radius: 4px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            width: 147px;
            height: 30px;
            line-height: 30px;
            color: #0ba976;
            text-align: center;
        }
    }

    .wrap_title {
        position: absolute;
        left: 60px;
        top: 50px;
        display: flex;
        align-items: center;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #fff;
    }

    .right_line {
        height: 24px;
        background: #f2f2f2;
        width: 1px;
        margin-right: 15px;
    }

    .level1 {
        width: 100%;
        height: 100%;

        .el-carousel {
            width: 100%;
            height: 100%;

            ::v-deep.el-carousel__container {
                width: 100%;
                height: 100% !important;
            }

            ::v-deep.el-carousel__indicators,
            .el-carousel__indicators--horizontal {
                position: absolute;
                bottom: 23% !important;
                transform: translate(-100%, 100%);

                .el-carousel__indicator {
                    .el-carousel__button {
                        opacity: 0;
                        width: 8px;
                        height: 8px;
                        background: #fcfcfc;
                        opacity: 0.5;
                        border-radius: 6px;
                        padding: 0;
                        margin: 22px;
                    }
                }

                .is-active {
                    &>.el-carousel__button {
                        width: 40px !important;
                        height: 8px;
                        background: #fcfcfc;
                        opacity: 1;
                        border-radius: 6px;
                    }
                }
            }
        }

        .el-carousel__item h3 {
            color: #475669;
            font-size: 14px;
            opacity: 0.75;
            line-height: 150px;
            margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
            background-color: #99a9bf;
        }

        .el-carousel__item:nth-child(2n + 1) {
            background-color: #d3dce6;
        }
    }

    .hoverBtn:hover+.hoverTips {
        display: block;
    }

    .level2 {
        z-index: 4;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -260px;
        margin-top: -284px;
        width: 520px;
        height: 588px;
        background: #ffffff;
        box-shadow: 0px 10px 25px rgba(11, 169, 118, 0.1);
        opacity: 1;
        border-radius: 16px;
        box-sizing: border-box;
        padding: 50px 60px;

        .loginContainer {
            width: 100%;
            height: 100%;

            .login-form {
                .is-error {
                    ::v-deep.el-form-item__label {
                        color: #ff4a4a;
                    }

                    ::v-deep.el-input__inner {

                        border: 1px solid rgba(255, 74, 74, 0.502);
                        background: #f8f8f8;
                        opacity: 1;
                        border-radius: 4px;
                        box-sizing: border-box;
                    }

                    ::v-deep.el-form-item__error {
                        right: 0px;
                        text-align: right;
                    }
                }

                ::v-deep.el-input__inner::-webkit-input-placeholder {
                    color: #999;
                }

                .pass {
                    position: relative;

                    ::v-deep.iconfont {
                        font-size: 20px;
                        color: #666666;
                        position: absolute;
                        right: 15px;
                        bottom: 5px;
                        cursor: pointer;
                    }
                }

                .el-form-item {
                    width: auto;
                    margin-bottom: 30px;

                    .SMSDiv {
                        display: inline-flex;
                        width: 100%;

                        .el-input {
                            margin-right: 20px;
                        }
                    }
                }
            }

            .qRCode {
                height: 311px;
                position: relative;

                .qr_tips {
                    position: absolute;
                    bottom: 90px;
                    left: 110px;
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    line-height: 0px;
                    color: #999999;
                    text-align: center;

                    span {
                        color: #0ba976;
                        margin: 0px 5px;
                    }
                }

                .qrCodeLayout {
                    display: flex;
                    justify-content: center;
                    overflow: hidden;
                    height: 285px;

                    ::v-deep .qrcode {
                        width: 160px;
                        height: 160px;
                    }

                    &>div {
                        .failureMask {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            background: rgba(0, 0, 0, 0.5);
                            top: 0;
                            left: 0;
                            border-radius: 16px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .el-button {
                                padding: 5px 10px;
                                font-size: 12px;
                                color: #0ba976;
                            }
                        }
                    }
                }
            }

            ::v-deep .el-form-item__label:before {
                content: "";
                margin-right: 0px;
            }

            ::v-deep .el-form-item__label {
                margin-bottom: 0px;
                @include mixin-font_16_b($secondary-color);
            }

            ::v-deep .el-input__inner {
                height: 52px;
                line-height: 52px;
                background: #f8f8f8;
                opacity: 1;
                border-radius: 4px;
                color: #2b2b2b;
                font-size: 16px;
                font-weight: bold;
                box-sizing: border-box;
                border: 1px solid #f8f8f8;

                &:focus {
                    border: 1px solid rgba(11, 169, 118, 0.5) !important;
                }
            }

            .loginTitle {
                @include mixin-font_24_b($primary-color);
                margin-bottom: 37px;
                text-align: center;
            }

            .loginFooter {
                .loginCheck {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #666666;

                    .loginForgetPass {
                        cursor: pointer;
                        font-weight: bold;
                        color: $primary-color;
                    }
                }

                .el-button {
                    width: 100%;
                    height: 100%;

                    &:hover {
                        opacity: 0.8;
                    }
                }

                .loginAotu {
                    font-size: 12px;

                    ::v-deep.el-checkbox__label {
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #666666;
                    }
                }
            }
        }

        .otherLogin {
            .methodContain {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .otherTitle {
                font-size: 14px;
                color: #999999;
                font-weight: 400;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 40px;

                &::before {
                    content: "";
                    width: 138px;
                    height: 1px;
                    background: #f2f2f2;
                    display: inline-block;
                }

                &::after {
                    content: "";
                    width: 138px;
                    height: 1px;
                    background: #f2f2f2;
                    display: inline-block;
                }
            }

            .otherMethod {
                display: flex;
                justify-content: space-between;
                align-items: center;

                &>span {
                    width: 100%;
                    height: 56px;
                    text-align: center;
                    cursor: pointer;
                    line-height: 56px;
                    border: 1px solid #e1e1e1;
                    box-sizing: border-box;
                    border-radius: 4px;

                    &:hover {
                        border: none;
                        background: $primary-color;

                        .iconfont {
                            color: #fff;
                        }

                        .content {
                            color: #fff;
                        }
                    }

                    .iconfont {
                        color: $primary-color;
                        font-size: 25px;
                        margin-right: 15px;
                    }

                    .content {
                        font-size: 16px;
                        font-weight: 400;
                        color: #666666;
                    }
                }
            }
        }
    }
}
</style>
<template>
  <div style="width:100%;height:60px;position:relative">
    <div class="topHeader">
      <div class="content_header">
        <div class="header_left">
          {{ title }}
          <slot name="tips"></slot>
        </div>
        <div class="header_right">
          <slot name="action"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      default: '',
    },
  },
}
</script>
<style lang="scss" scoped>
.topHeader {
  width: calc(100% - 56px);
  position: fixed;
  top: 98px;
  right: 0px;
  background: #fafafa;
  margin-bottom: 10px;
  z-index: 1;

  // box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
  .content_header {
    width: 100%;
    display: flex;
    height: 60px;
    line-height: 60px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    // padding: 0px 15px;
    color: #2b2b2b;
    font-size: 16px;

    .header_left {
      font-size: 18px;
      color: #2b2b2b;
      font-weight: bold;
    }
  }

  .main {
    width: 100%;
    height: 100%;
    padding: 15px;
  }
}
</style>

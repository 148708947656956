import request from "@/utils/request";
export const getMerchants = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/merchant",
    params,
  });
};

// 商户结合
export const getMerchantList = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/merchants/all",
    params,
  });
};

// 控制中心
export const getMerchantConsole = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/merchants/consoles",
    params,
  });
};

// 进入商户

export const getEntry = (params) => {
  return request({
    method: "POST",
    url: "/api/merchants/entry/" + params,
  });
};
// 商户下门店集合

export const getMerchantBranchList = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/branches/merchant-center",
    params,
  });
};

// 商户下门店详情

export const getMerchantBranchDetail = (id) => {
  return request({
    method: "GET",
    url: `/api/branches/${id}/merchant-center`,
  });
};

// 商户POS集合

export const getBranchPosList = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/branch-devices/pos",
    params,
  });
};

// 获取门店云喇叭集合

export const getBranchHornList = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/branch-devices/cloud-horns",
    params,
  });
};

// 获取门店的台卡集合

export const getBranchTaikaList = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/branch-devices/taikas",
    params,
  });
};

// 获取商户信息
export const getMerchantDetailInfo = (id) => {
  return request({
    method: "GET",
    url: "/api/merchants/" + id,
  });
};

// 获取商户费率结算

export const getMerchantRate = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/merchants/rate-settlement",
    params,
  });
};

// 获取绑定设备的集合
export const getDeviceBindList = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/branch-devices/device-binds",
    params,
  });
};

// 商户储值集合
//
export const getMerchantStoreValList = (data = {}) => {
  return request({
    method: "POST",
    url: "/api/merchant-store-values",
    data,
  });
};

// 商户储值详情
//
export const getMerchantStoreValDetail = (data = {}) => {
  return request({
    method: "POST",
    url: "/api/merchant-store-values/recharge-order/status",
    data,
  });
};

// 新增充值订单

export const createMerchantRecharegeOrder = (data = {}) => {
  return request({
    method: "POST",
    url: "/api/merchant-store-values/recharge-order",
    data,
  });
};

// 获取储值详情
// 
export const getMerchantStoreDetail= (data = {}) => {
  return request({
    method: "POST",
    url: "/api/merchant-store-values/merchant-store-value-detail",
    data,
  });
};
// 查询订单状态

export const getMerchantReargeOrder = (data = {}) => {
  return request({
    method: "POST",
    url: "/api/merchant-store-values/recharge-order/status",
    data,
  });
};

// 取消订单状态

export const cancelMerchantRechargeOrder = (data = {}) => {
  return request({
    method: "POST",
    url: "/api/merchant-store-values/recharge-order/cancel",
    data,
  });
};

// 确认支付订单
//
export const confirmPayOrder = (data = {}) => {
  return request({
    method: "POST",
    url: "/api/merchant-store-values/recharge-order/confirm-payment",
    data,
  });
};

// 消费记录
//

export const getMerchantComsumpRecord = (data = {}) => {
  return request({
    method: "POST",
    url: "/api/merchant-store-values/consumption-records",
    data,
  });
};

// 充值记录
// 
export const getMerchantRechargeRecord = (data = {}) => {
  return request({
    method: "POST",
    url: "/api/merchant-store-values/recharge-records",
    data,
  });
};


// 获取直接商户结算信息
// 
export const getDirectMerchantInfo = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/merchants/direct-rate-settlement",
    params,
  });
};
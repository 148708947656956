import request from '@/utils/request';
export const getMerchantInfo = (params) => {
    return request({
        method: 'get',
        url: '/api/merchants',
        params
    })
}

export const updateMerInfo = (data = {}) => {
    return request({
        method: 'PUT',
        url: '/api/merchants',
        data
    })
}
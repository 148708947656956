import request from "@/utils/request";
import { filteParams } from "@/utils/handleData.js";
//品牌树查询
export const brandInfoTree = (params) => {
  return request({
    method: "GET",
    url: "/api/item/brand-info-tree",
    params:filteParams(params),
  });
};
//品牌列表查询
export const brandInfoList = (params) => {
  return request({
    method: "GET",
    url: "/api/item/brand-info",
    params: filteParams(params),
  });
};
//批量同步品牌信息
export const syncBrandInfo = (data) => {
  return request({
    method: "POST",
    url: "/api/item/brand-info",
    data,
  });
};
//商品列表查询
export const itemInfoList = (params) => {
  return request({
    method: "GET",
    url: "/api/item/item-info?item_status=1",
    params,
  });
};
//批量同步商品信息
export const syncItemInfo = (data) => {
  return request({
    method: "POST",
    url: "/api/item/item-info",
    data,
  });
};
//类别树查询
export const categoryInfoTree = (params) => {
  return request({
    method: "GET",
    url: "/api/item/category-info-tree",
    params,
  });
};
//类别列表查询
export const categoryInfoList = (params) => {
  return request({
    method: "GET",
    url: "/api/item/category-info",
    params: filteParams(params),
  });
};
//批量同步类别信息
export const syncCategoryInfo = (data) => {
  return request({
    method: "POST",
    url: "/api/item/category-info",
    data,
  });
};
//发送商品同步指令，每个商户指定唯一
export const sendItemSync = (data) => {
  return request({
    method: "POST",
    url: "/api/item/send-item-sync",
    data,
  });
};
//发送类别同步指令
export const sendClsSync = (data) => {
  return request({
    method: "POST",
    url: "/api/item/send-cls-sync",
    data,
  });
};
//品牌同步指令
export const sendBrandSync = (data) => {
  return request({
    method: "POST",
    url: "/api/item/send-brand-sync",
    data,
  });
};

<template>
  <div class="JlkCardWarp">
    <div class="cardTitle" v-if="showTitle">
      <slot name="cardTitleLeft">
        <div class="cardTitleLeft">
          {{ CardTitle }}
        </div>
      </slot>
      <div class="cardTitleRight">
        <slot name="cardTitleRight"></slot>
      </div>
    </div>
    <div class="cardContent">
      <slot name="cardContent"> </slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'jlkCard',
  props: {
    CardTitle: {
      type: String,
      delete: '',
    },
    showTitle: {
      default: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.JlkCardWarp {
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  background-color: $grounding-color;
  border-radius: 6px;

  .cardTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid $border-color;

    .cardTitleLeft {
      @include mixin-font_16_b($secondary-color);
    }
  }

  .cardContent {
    flex: 1;
    padding: 15px 0px;
  }
}</style>

<template>
    <div class="forgetPassPage">
        <div class="_title">
            <img src="https://jlk-sys-image.oss-cn-zhangjiakou.aliyuncs.com/202208151056534746160042807478817.png"
                alt="据来客LOGO" style="width: 38px; height: 38px" />
            <div class="right_line"></div>
            <div style="font-size: 16px">聚哒客商户中心</div>
        </div>
        <div class="_content">
            <div class="__container">
                <div class="___title">账号激活</div>
                <div class="forgetFormContainer">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="forgetPassForm-form">
                        <el-form-item prop="phone">
                            <el-input v-model="ruleForm.phone" :disabled="!!ruleForm.phone" placeholder="请输入手机号码">
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="captcha_code" class="code_btn">
                            <el-input v-model="ruleForm.captcha_code" placeholder="请输入验证码" auto-complete="new-password"
                                autocomplete="off" maxlength="8">
                            </el-input>
                            <div class="verificationCodeBtn">
                                <span @click="!loading && count ? '' : getCaptcha()">{{
                                    !loading && count ? count + "s" : "获取验证码"
                                }}</span>
                            </div>
                        </el-form-item>
                        <el-form-item prop="pwd">
                            <el-input v-model="ruleForm.pwd" placeholder="请输入6-20位新密码" auto-complete="new-password"
                                autocomplete="off" show-password></el-input>
                        </el-form-item>
                        <el-form-item prop="re_pwd">
                            <el-input v-model="ruleForm.re_pwd" placeholder="请确认新密码" show-password></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="newPW" class="set_btn">确定</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { $_md5 } from "@/utils/md5";
import { cloneDeep } from 'lodash'
export default {
    props: {
        formData: {
            type: Object,
            default: () => { }
        },
    },
    mounted() {
        this.ruleForm.phone = this.formData.phone || this.formData.account
    },
    data() {
        const confirmPwd = (rule, value, callback) => {
            if (value && value !== this.ruleForm.pwd) {
                return callback(new Error("两次输入的密码不一致!"));
            } else {
                callback();
            }
        };
        const validatePhone = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入新手机号码"));
            } else if (!/^1[0-9]{10}$/.test(value)) {
                callback(new Error("手机号码格式不正确"));
            } else {
                callback();
            }
        };
        const validatePwd = (rule, value, callback) => {
            if (!value || value === "") {
                callback();
            } else if ((value && value.length < 6) || (value && value.length > 20)) {
                callback(new Error("请输入6-20个字符的密码"));
            } else if (!/^[A-Za-z0-9!@#$%]+$/.test(value)) {
                callback(new Error("密码格式不正确"));
            } else {
                callback();
            }
        };
        return {
            toLoginTime: 3,
            changePwdSuccess: false,
            getCode: "获取验证码",
            ruleForm: {
                phone: "",
                captcha_code: "",
                pwd: "",
                re_pwd: "",
            },
            rules: {
                phone: [{ required: true, validator: validatePhone, trigger: "blur" }],
                captcha_code: [
                    {
                        required: true,
                        message: "请输入验证码",
                        trigger: "blur",
                    },
                ],
                pwd: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入新密码",
                    },
                    { validator: validatePwd, trigger: "blur" },
                ],
                re_pwd: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请再次输入新密码",
                    },
                    { validator: confirmPwd, trigger: "blur" },
                ],
            },
            loading: false,
            count: 0,
            countDownTimer: null
        };
    },
    methods: {
        // 修改完密码前往登录
        toLogin() {
            this.isLogin = true;
        },
        goBack() {
            this.$emit("tabClick", 0);
        },
        // 确认修改密码
        async newPW() {
            const valid = await this.$refs.ruleForm.validate();
            if (valid) {
                const cloneData = cloneDeep(this.ruleForm);
                cloneData.pwd = $_md5(cloneData.pwd);
                cloneData.re_pwd = $_md5(cloneData.re_pwd);
                const ret = await this.$API.getForgetPwd(cloneData);
                if (ret && ret.ok) {
                    this.$JlkMessage("更新成功", "success")
                    this.$emit("tabClick", 0)
                }
            }
        },
        async getCaptcha() {
            if (this.loading || !this.ruleForm.phone) return false;
            this.loading = true;
            try {
                const ret = await this.$API.getForgetCaptcha({ phone: this.ruleForm.phone });
                if (ret && ret.ok) {
                    this.loading = false;
                    this.count = 60;
                    this.countDownTimer = setInterval(() => {
                        this.count--;
                        if (this.count <= 0) {
                            clearInterval(this.countDownTimer);
                            this.count = 0
                        }
                    }, 1000);
                } else {
                    this.loading = false;
                }
            } catch (error) {
                this.loading = false;
            }

        },

    },
};
</script>

<style lang="scss" scoped>
.forgetPassPage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $sidebar-bg;
    display: flex;

    ._title {
        height: 54px;
        background: $grounding-color;
        display: flex;
        align-items: center;
        padding: 0 30px;
        box-sizing: border-box;

        img {
            width: 65px;
            height: 24px;
            margin-right: 16px;
        }

        div {
            @include mixin-font_18_8($secondary-color);
        }
    }

    .return_btn {
        text-align: right !important;

        &:hover {
            color: rgba(11, 169, 118, 0.8) !important;
        }
    }

    ._content {
        padding: 20px 18%;
        height: calc(100% - 54px);
        overflow-y: auto;
        box-sizing: border-box;

        .__container {
            width: 100%;
            background-color: $grounding-color;
            padding: 30px;
            box-sizing: border-box;
            text-align: center;

            .___title {
                height: 48px;
                background-color: $subtitle-color;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                @include mixin-font_20_b($forgetPass-color);
            }

            .forgetFormContainer {
                width: 350px;
                margin: 80px auto 0;

                .el-form {
                    .el-form-item {
                        width: 100%;
                        margin-bottom: 30px;

                        ::v-deep.el-input__inner {
                            height: 52px;
                            line-height: 52px;
                            border: 1px solid #e1e1e1;
                        }
                    }

                    .is-error {
                        ::v-deep.el-input__inner {
                            border: 1px solid rgba(255, 74, 74, 0.502);
                        }
                    }

                    .set_btn {
                        width: 100%;
                        height: 100%;
                        border-radius: 6px;
                        background: #0ba976;
                        border: 1px solid #0ba976;
                        font-size: 20px;
                        margin-top: 30px;
                        @include mixin-font_20_5($grounding-color);

                        &:hover {
                            opacity: 0.8;
                        }
                    }

                    ::v-deep.el-form-item__content {
                        display: flex;
                        align-items: center;

                        .codeGeting {
                            background: #cdcdcd;
                            border-color: #cdcdcd;
                        }

                        .el-form-item__error {
                            right: 0px;
                            text-align: right;
                            font-weight: 400;
                        }

                        .tip {
                            position: absolute;
                            top: 10px;
                            left: 352px;
                            width: 242px;
                            @include mixin-font_14_4($hint-color);
                        }
                    }
                }

                div {
                    text-align: center;
                    font-size: 16px;
                    color: #0ba976;
                    font-weight: bold;
                    font-family: "PingFang SC";
                }
            }

            .loginOutContainer {
                padding: 125px 98px 0px 90px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .loginOut {
                    .tips {
                        font-size: 24px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        line-height: 24px;
                        color: #666666;
                        margin-bottom: 50px;
                    }

                    .btn {
                        display: inline-block;
                        width: 400px;
                        height: 58px;
                        font-size: 20px;
                        font-family: PingFang SC;
                        font-weight: bold;
                        line-height: 58px;
                        text-align: center;
                        color: #999999;
                        border: 1px solid #cccccc;
                        opacity: 1;
                        border-radius: 4px;

                        &:hover {
                            color: rgba(11, 169, 118, 1);
                            border: 1px solid rgba(11, 169, 118, 1) !important;
                            cursor: pointer;
                        }
                    }
                }
            }

            .changePwdFinish {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 260px;
                justify-content: space-between;

                .icon-chenggong1 {
                    font-size: 120px;
                    color: #0ba976;
                    line-height: 1;
                }

                .tipText {
                    display: flex;
                    flex-direction: column;
                    height: 50px;
                    justify-content: space-between;

                    &>span:nth-of-type(1) {
                        font-size: 20px;
                        font-weight: bold;
                        color: #2b2b2b;
                    }

                    &>span:nth-of-type(2) {
                        color: #999999;

                        a {
                            color: #0ba976;
                        }
                    }
                }

                .toLoginBtn {
                    width: 280px;
                    border-radius: 24px;
                    border-color: #0ba976;
                    color: #0ba976;
                }
            }
        }
    }
}

.code_btn {
    position: relative;

    .verificationCodeBtn {
        position: absolute;
        right: 0px;
        top: 6px;
        width: 120px;
        border-left: 1px solid #e1e1e1;
        cursor: pointer;
        text-align: center;

        span {
            padding: 7px 20px;
            font-size: 16px;
            font-weight: bold;
            color: $primary-color;
        }
    }
}
</style>
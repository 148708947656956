<template>
  <div style="height: 100%">
    <div class="warp">
      <div class="header">
        <JlkHeader ref="header" />
      </div>
      <div class="center" @click="closeUserInfo">
        <div class="left">
          <JlkAside ref="menu" />
        </div>
        <div :class="['content', opened ? 'opened' : '']" @click="closeMenus">
          <JlkNav />
          <div class="layoutContain">
            <keep-alive :include="cachedViews">
              <router-view :key="$route.fullPath" />
            </keep-alive>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import JlkAside from "./components/JlkAside/index.vue";
import JlkHeader from "./components/JlkHeader/index.vue";
import JlkNav from "./components/JlkHeader/JlkNav/index.vue";
import { mapState } from "vuex";
export default Vue.extend({
  name: "layout",
  data() {
    return {
      varWidth: "34px",
    };
  },
  mounted() { },
  components: { JlkAside, JlkHeader, JlkNav },
  methods: {
    closeMenus() {
      this.$refs.menu.closeLevelMenus();
    },
    closeUserInfo() {
      this.$refs.header.closeUserInfo();
    },
  },
  computed: {
    visitedViews() {
      return this.$store.state.tagsView.visitedViews;
    },
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    ...mapState({
      opened: (state) => state.app.sidebar,
    }),
  },
});
</script>
<style lang="scss" scoped>
.warp {
  width: 100%;
  height: 100%;

  .left {
    width: auto;
    height: 100%;
    background: chocolate;
    z-index: 2;
  }

  .center {
    height: calc(100% - 54px);
    width: 100%;
    display: flex;

    .header {
      height: 98px;
      z-index: 1;
    }

    .content {
      width: calc(100% - 56px);
      height: 100%;
      background: #fff;
      transition: all 0.5s;

      .layoutContain {
        height: calc(100% - 37px);
        width: 100%;
        box-sizing: border-box;

        ::v-deep.wrap_outer {
          padding: 0 15px;
        }
      }
    }

    .opened {
      width: calc(100% - 180px);
    }
  }
}

.forgetPassPage {
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: $sidebar-bg;
  display: flex;

  ._title {
    height: 70px;
    background: $grounding-color;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    padding: 0 30px;

    img {
      width: 65px;
      height: 24px;
      margin-right: 16px;
    }

    div {
      @include mixin-font_18_8($secondary-color);
    }
  }

  ._content {
    padding: 20px 18%;
    height: 100%;
    box-sizing: border-box;

    .__container {
      width: 100%;
      height: 100%;
      background-color: $grounding-color;
      padding: 30px;
      box-sizing: border-box;
      text-align: center;

      .___title {
        height: 48px;
        background-color: $subtitle-color;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include mixin-font_20_b($forgetPass-color);
      }

      .forgetFormContainer {
        width: 350px;
        margin: 80px auto 0;

        .el-form {
          .el-form-item {
            width: 100%;
            margin-bottom: 30px;

            ::v-deep.el-input__inner {
              height: 52px;
              border: 1px solid #e1e1e1;
            }
          }

          .is-error {
            ::v-deep.el-input__inner {
              border: 1px solid rgba(255, 74, 74, 0.502);
            }
          }

          .set_btn {
            width: 100%;
            height: 100%;
            border-radius: 6px;
            background: #0ba976;
            border: 1px solid #0ba976;
            font-size: 20px;
            @include mixin-font_20_5($grounding-color);

            &:hover {
              opacity: 0.8;
            }
          }

          .return_btn {
            &:hover {
              color: rgba(11, 169, 118, 0.8) !important;
            }
          }

          ::v-deep.el-form-item__content {
            display: flex;
            align-items: center;

            .codeGeting {
              background: #cdcdcd;
              border-color: #cdcdcd;
            }

            .el-form-item__error {
              right: 0px;
              text-align: right;
              font-weight: 400;
            }

            .tip {
              position: absolute;
              top: 10px;
              left: 352px;
              width: 242px;
              @include mixin-font_14_4($hint-color);
            }
          }
        }

        div {
          text-align: end;
          font-size: 16px;
          color: #0ba976;
          font-weight: bold;
          font-family: "PingFang SC";
        }
      }

      .loginOutContainer {
        padding: 125px 98px 0px 90px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .loginOut {
          .tips {
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 24px;
            color: #666666;
            margin-bottom: 50px;
          }

          .btn {
            display: inline-block;
            width: 400px;
            height: 58px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 58px;
            text-align: center;
            color: #999999;
            border: 1px solid #cccccc;
            opacity: 1;
            border-radius: 4px;

            &:hover {
              color: rgba(11, 169, 118, 1);
              border: 1px solid rgba(11, 169, 118, 1) !important;
              cursor: pointer;
            }
          }
        }
      }

      .changePwdFinish {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 260px;
        justify-content: space-between;

        .icon-chenggong1 {
          font-size: 120px;
          color: #0ba976;
          line-height: 1;
        }

        .tipText {
          display: flex;
          flex-direction: column;
          height: 50px;
          justify-content: space-between;

          &>span:nth-of-type(1) {
            font-size: 20px;
            font-weight: bold;
            color: #2b2b2b;
          }

          &>span:nth-of-type(2) {
            color: #999999;

            a {
              color: #0ba976;
            }
          }
        }

        .toLoginBtn {
          width: 280px;
          border-radius: 24px;
          border-color: #0ba976;
          color: #0ba976;
        }
      }
    }
  }
}
</style>

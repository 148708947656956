import request from '@/utils/request'
// 查看轮播图设置信息列表
export const carouselsList = (params) => {
  return request({
    method: 'GET',
    url: '/api/carousels',
    params,
  })
}
//新增轮播图设置信息
export const addCarousels = (data) => {
  return request({
    method: 'POST',
    url: '/api/carousels',
    data,
  })
}
//轮播图设置信息操作(1启用 2禁用 3删除)
export const operationCarousels = (data) => {
  return request({
    method: 'PUT',
    url: '/api/carousels',
    data,
  })
}
//获取轮播图设置信息
export const carouselsDetails = (ID) => {
  return request({
    method: 'GET',
    url: `/api/carousels/${ID}`,
  })
}

//修改轮播图设置信息
export const editCarousels = (ID, data) => {
  return request({
    method: 'PUT',
    url: `/api/carousels/${ID}`,
    data,
  })
}

import request from '@/utils/request'
import {
    filteParams
} from '@/utils/handleData.js'


// 获取资源包
export function getResourceList(params = {}) {
    return request({
        url: '/api/resource-packs/valid-phone',
        method: 'GET',
        params: filteParams(params)
    })
}

// 获取资源包套餐集合

export const getValidPhonePrices = (params) => {
    return request({
        url: '/api/resource-packs/valid-phone-prices',
        method: 'GET',
        params: params
    })
}

// 资源包支付方式

export const getResourcePayDic = (params) => {
    return request({
        url: '/api/dictionaries/resource-pack-order-pay-type',
        method: 'GET',
        params: params
    })
}

// 创建资源包订单
export const createPackOrder = (data) => {
    return request({
        url: '/api/resource-pack-orders',
        method: 'POST',
        data
    })
}

// 获取订单集合
// 
export const getResourceOrder = (params) => {
    return request({
        url: '/api/resource-pack-orders',
        method: 'GET',
        params: params
    })
}

// 查询订单

export const getOrderDetail = (params) => {
    return request({
        url: '/api/resource-pack-orders/status',
        method: "GET",
        params
    })
}

// 取消订单

export const cancelOrder = (data) => {
    return request({
        url: '/api/resource-pack-orders/cancel',
        method: 'PUT',
        data
    })
}

// 确定订单

export const confirmOrder = (data) => {
    return request({
        url: '/api/resource-pack-orders/confirm-payment',
        method: "POST",
        data
    })
}

// 资源包用量统计

export const getUsageTotal = (params) => {
    return request({
        url: '/api/resource-packs/valid-phone-usage-total',
        method: "GET",
        params
    })
}

// 资源包用量曲线
export const getUsageTrend = (params) => {
    return request({
        url: '/api/resource-packs/valid-phone-usage-trend',
        method: "GET",
        params
    })
}

// 额度来源

export const getValidPhoneSource = (params) => {
    return request({
        url: '/api/resource-packs/valid-phone-source',
        method: "GET",
        params
    })
}

// 资源包字典 // 

export const getResourceDic = (params) => {
    return request({
        url: '/api/dictionaries/dic-resource-pack',
        method: "GET",
        params
    })
}
<template>
  <div class="frag_contain">
    <span class="title_icon"></span>
    <span class="title">
      {{ title }}
    </span>
    <span class='sub_title' v-if="sub_title">{{sub_title}}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    sub_title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.frag_contain {
  display: flex;
  align-items: center;
  .title_icon {
    width: 3px;
    height: 18px;
    background: #0ba976;
    border-radius: 0px 3px 3px 0px;
    margin-right: 10px;
  }
  .title {
    font-size: 16px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #2b2b2b;
  }

  .sub_title{
    font-size: 16px;
    color: #666666;
    margin-left:10px;
  }
}
</style>
///
import request from '@/utils/request'

export const getBranches = (params = {}) => {
    return request({
        url: '/api/branches',
        method: "get",
        params
    })
}

export const getBranchDetail = (params={})=>{
	return request({
		url:`/api/branches/${params}`,
		method:'get',
	})
}

export const updateBranch = (id,data = {}) => {
    return request({
        method: 'PUT',
        url: `/api/branches/${id}`,
        data
    })
}
import request from '@/utils/request'
/**
 * 查看积分兑换物品列表
 * 用于查询积分商城和礼品查询，使用exchange_way区分  1 优惠券 2礼品
 */
export const pointsExchangeGoods = (params) => {
  return request({
    method: 'GET',
    url: '/api/points-exchange/goods',
    params,
  })
}
//新增积分兑换物品或者优惠劵
//通过 exchange_way区分  1 优惠券 2礼品
export const addPointsExchangeGood = (data) => {
  return request({
    method: 'POST',
    url: '/api/points-exchange/goods',
    data,
  })
}
//积分兑换物品信息操作(1启用 2禁用 3删除)
export const OperationPointsExchangeGood = (data) => {
  return request({
    method: 'PUT',
    url: '/api/points-exchange/goods',
    data,
  })
}
//查看积分兑换物品信息
export const pointsExchangeGoodDetails = (ID) => {
  return request({
    method: 'GET',
    url: `/api/points-exchange/goods/${ID}`,
  })
}

//修改积分兑换物品信息
export const editPointsExchangeGood = (ID, data) => {
  return request({
    method: 'PUT',
    url: `/api/points-exchange/goods/${ID}`,
    data,
  })
}

//查看积分兑换记录列表
export const pointsExchangeOrders = (params) => {
  return request({
    method: 'GET',
    url: '/api/points-exchange/orders',
    params,
  })
}

import request from '@/utils/request'
export const uploading = data => {
  return request({
    method: 'POST',
    url: '/api/upload/image',
    data
  })
}

export const temporary = (data, appletAppId, type) => {
  return request({
    method: 'POST',
    url: `/api/wechat-material/temporary?appletAppId=${appletAppId}&type=${type}`,
    data
  })
}

<template>
  <div class="valid_contain">
    <div class="valid_label">
      更改手机号需验证您的身份，请通过手机接收验证码
    </div>
    <div class="valid_phone">
      手机号：{{ formData.phone }}
    </div>
    <el-form class="formItem" ref="formData" :model="ruleForm" :rules="rules">
      <el-form-item label="验证码" prop="captcha_code" style="width:100%">
        <div style="display: flex;align-items: center;justify-content: center;width: 100%;">
          <el-input v-model="ruleForm.captcha_code" maxlength="6" :placeholder="'验证码'" style="margin-right: 10px;" />
          <el-button size="mini" type="primary" :loading="loading" :disabled="!!count" plain @click="getCaptcha"
            class="btn">
            {{ !loading && count ? count + "s" : "获取验证码" }}
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

export default {
  props: {
    formData: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      ruleForm: {
        phone: "",
        captcha_code: "",
      },
      rules: {
        captcha_code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      loading: false,
      countDownTimer: null,
      count: 0
    }
  },
  methods: {
    async getCaptcha() {
      if (this.loading) return false;
      this.loading = true;
      try {
        const ret = await this.$API.postSendCaptcha();
        if (ret && ret.ok) {
          this.loading = false;
          this.count = 60;
          this.countDownTimer = setInterval(() => {
            this.count--;
            if (this.count <= 0) {
              clearInterval(this.countDownTimer);
              this.count = 0
            }
          }, 1000);
        } else {
          this.loading = false
        }
      } catch (error) {
        this.loading = false;
      }
    },
    handleOk() {
      return new Promise(async (resolve, reject) => {
        const valid = await this.$refs.formData.validate();
        if (valid) {
          const ret = await this.$API.postCheckCaptcha(this.ruleForm);
          if (ret && ret.ok) {
            resolve();
          }
        }
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.valid_contain {
  .valid_label {
    font-weight: bold;
    font-size: 14px;
  }

  .valid_phone {
    color: #666666;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 30px;
  }
}

.el-button--primary.is-plain {
  background: #fff;
}


:deep(.el-form-item) {
  margin-bottom: 0;
}

.btn {
  width: 92px;
  text-align: center;
}
</style>

import request from '@/utils/request'
import { filteParams } from '@/utils/handleData.js'
export function getReplayList(params = {}) {
  const data = filteParams(params)
  return request({
    url: '/api/official-accounts/keyword',
    method: 'GET',
    params: data
  })
}
export function getMaterList(params = {}) {
  return request({
    url: '/api/wechat-material/forever-materials',
    method: 'POST',
    data: params
  })
}
export function getImageMaterList(params = {}) {
  return request({
    url: '/api/wechat-material/image-text-materials',
    method: 'POST',
    data: params
  })
}

export function addKeyWords(params = {}) {
  return request({
    url: '/api/official-accounts/keyword',
    method: 'POST',
    data: params
  })
}

export function deleKeyWords(params = {}) {
  return request({
    url: '/api/official-accounts/keyword',
    method: 'PUT',
    data: params
  })
}

export function putKeyWords(params = {}, id) {
  return request({
    url: `/api/official-accounts/keyword/${id}`,
    method: 'PUT',
    data: params
  })
}

import request from '@/utils/request'
export const getApplets = (params = {}) => {
  return request({
    method: 'GET',
    url: '/api/applet-manage',
    params
  })
}
export const getAppletsTesters = (params = {}) => {
  return request({
    method: 'GET',
    url: '/api/applet-manage/applet-testers',
    params
  })
}

export const authorizerInfo = (appId) => {
  return request({
    method: 'POST',
    url: `/api/applet-manage/authorizer-info?appId=${appId}`,
    data: { appId }
  })
}
export const getAppletById = (id) => {
  return request({
    method: 'GET',
    url: `/api/applets/${id}`
  })
}
export const unbindTester = (data = {}) => {
  return request({
    method: 'POST',
    url: '/api/applet-manage/unbind-tester',
    data
  })
}

export const bindTester = (data = {}) => {
  return request({
    method: 'POST',
    url: '/api/applet-manage/bind-tester',
    data
  })
}
export const submitAudit = (data = {}) => {
  return request({
    method: 'POST',
    url: `/api/wechat-applets/submit-audit?appletAppId=${data.appletAppId}`,

  })
}
export const release = (data = {}) => {
  return request({
    method: 'POST',
    url: `/api/wechat-applets/release?appletAppId=${data.appletAppId}`
  })
}

export const expQrcode = (params = {}) => {
  return request({
    method: 'GET',
    url: `/api/wechat-applets/exp-qrcode?appletAppId=${params.appletAppId}`
  })
}
export const qrcode = (params = {}) => {
  return request({
    method: 'GET',
    url: `/api/wechat-applets/qrcode?appletAppId=${params.appletAppId}`
  })
}
export const undoAudit = (params = {}) => {
  return request({
    method: 'GET',
    url: `/api/wechat-applets/undo-audit?appletAppId=${params.appletAppId}`
  })
}
export const codeTemplates = (params = {}) => {
  return request({
    method: 'GET',
    url: `/api/wechat-applets/code-templates?templateType=${params.templateType}`
  })
}
export const uploadCode = (data = {}) => {
  return request({
    method: 'POST',
    url: `/api/wechat-applets/upload-code?appletAppId=${data.appletAppId}&templateId=${data.templateId}`
  })
}
export const registered = (data = {}) => {
  return request({
    method: 'POST',
    url: '/api/applet-manage/registered',
    data
  })
}
export const fastregister = (data = {}) => {
  return request({
    method: 'POST',
    url: '/api/applets/fast-register',
    data
  })
}
export const serverDomain = (data = {}) => {
  return request({
    method: 'POST',
    url: `/api/wechat-applets/server-domain?appletAppId=${data.appletAppId}`,
    data
  })
}
export const businessDomain = (data = {}) => {
  return request({
    method: 'POST',
    url: `/api/wechat-applets/business-domain?appletAppId=${data.appletAppId}`,
    data
  })
}
export const hasCategories = (data = {}) => {
  return request({
    method: 'GET',
    url: `/api/wechat-applets/has-categories?appletAppId=${data.appletAppId}`
  })
}
export const delApplet = (data = {}) => {
  return request({
    method: 'PUT',
    url: '/api/applet-manage',
    data
  })
}
export const authLink = (params = {}) => {
  return request({
    method: 'GET',
    url: '/api/wechat-auth/auth-link',
    params
  })
}
export const deleteCategory = (data = {}, appletAppId) => {
  return request({
    method: 'POST',
    url: `/api/wechat-applets/delete-category?appletAppId=${appletAppId}`,
    data
  })
}
export const categories = (data = {}) => {
  return request({
    method: 'GET',
    url: `/api/wechat-applets/categories?appletAppId=${data.appletAppId}`
  })
}
export const addCategories = (data = {}, appletAppId) => {
  return request({
    method: 'POST',
    url: `/api/wechat-applets/add-categories?appletAppId=${appletAppId}`,
    data
  })
}
// 微信认证名称检测 该接口只允许通过api创建的小程序使用。
export const checkVerifyNickname = (params = {}) => {
  return request({
    method: 'POST',
    url: '/api/wechat-applets/check-verify-nickname',
    params
  })
}
// 设置小程序名称
export const setNickname = (data = {}, appletAppId) => {
  return request({
    method: 'POST',
    url: `/api/wechat-applets/set-nickname?appletAppId=${appletAppId}`,
    data
  })
}
// 设置小程序名称
export const functionIntroduction = (data = {}, appletAppId) => {
  return request({
    method: 'POST',
    url: `/api/wechat-applets/function-introduction?appletAppId=${appletAppId}`,
    data
  })
}
// 查询最新一次提交的审核状态
export const latestAuditStatus = (appletAppId) => {
  return request({
    method: 'GET',
    url: `/api/wechat-applets/latest-audit-status?appletAppId=${appletAppId}`
  })
}
// 获取小程序代码发布历史记录
export const appletCodeReleaseHistory = (id) => {
  return request({
    method: 'GET',
    url: `/api/applet-manage/${id}/applet-code-release-history`
  })
}
// 修改小程序的头像
export const modifyHeadImage = (data = {}, appletAppId) => {
  return request({
    method: 'POST',
    url: `/api/wechat-applets/modify-head-image?appletAppId=${appletAppId}`,
    data
  })
}
// 获取上一个版本
export const lastVersion = (appletAppId) => {
  return request({
    method: 'GET',
    url: `/api/wechat-applets/last-version/${appletAppId}`
  })
}
// 更新小程序注册审核，名称审核，代码审核状态
export const updateAllAudit = (id) => {
  return request({
    method: 'PUT',
    url: `/api/applet-manage/update-all-audit/${id}`
  })
}
// 回退版本
export const versionBackups = (params = {}) => {
  return request({
    method: 'GET',
    url: `/api/wechat-applets/version-backups?appletAppId=${params.appletAppId}`
  })
}
// 获取微信公众号粉丝列表
export const officialAccountsFan = (params) => {
  return request({
    method: 'GET',
    url: `/api/official-accounts/fan`,
    params
  })
}
// 获取开放平台账号列表
export const getWechatOpenAccountsList = (params) => {
  return request({
    method: 'GET',
    url: '/api/wechat-open-accounts',
    params
  })
}
// 获取某个小程序/公众号绑定的开放平台appid
export const getWechatOpenAccountsAppid = (appid) => {
  return request({
    method: 'GET',
    url: `/api/wechat-open-accounts/${appid}`
  })
}
// 获取公众号列表
export const getWxOpenOfficialAccountsList = (id) => {
  return request({
    method: 'GET',
    url: `/api/wechat-open-accounts/${id}/official-accounts`
  })
}
// 获取小程序列表
export const getWxOpenAppletsList = (id, params) => {
  return request({
    method: 'GET',
    url: `/api/wechat-open-accounts/${id}/applets`,
    params
  })
}
// 创建开放平台账号
export const createWxOpen = (data) => {
  return request({
    method: 'POST',
    url: '/api/wechat-open-accounts/create',
    data
  })
}
// 小程序/公众号绑定开放平台
export const bindWxOpen = (data) => {
  return request({
    method: 'POST',
    url: '/api/wechat-open-accounts/bind',
    data
  })
}
// 小程序/公众号解绑开放平台
export const unbindWxOpen = (data) => {
  return request({
    method: 'POST',
    url: '/api/wechat-open-accounts/unbind',
    data
  })
}
// 获取小程序菜单字典集合
export const getAppletMenu = (params) => {
  return request({
    method: 'GET',
    url: '/api/dictionaries/applet-menu',
    params
  })
}

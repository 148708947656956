import request from '@/utils/request'
//获取门店集合
export const branchesList = (params) => {
  return request({
    method: 'GET',
    url: '/api/branches',
    params,
  })
}
export const branchesApplyList = (params) => {
  return request({
    method: 'GET',
    url: '/api/branches/apply',
    params,
  })
}

//获取单个门店信息
export const branchesDetails = (ID) => {
  return request({
    method: 'GET',
    url: `/api/branches/${ID}`,
  })
}
//修改门店信息
export const editBranches = (ID, data) => {
  return request({
    method: 'PUT',
    url: `/api/branches/${ID}`,
    data,
  })
}

// 门店操作接口
export const actionBranch = (data) => {
  return request({
    method: 'PUT',
    url: '/api/branches',
    data,
  })
}

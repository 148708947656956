<template>
  <div class="jlk-pagination">
    <el-pagination ref="myPaginationRef" :current-page="formPage.index" :page-size="formPage.size" :total="total"
      :page-sizes="pageSizes" @size-change="handleSizeChange" @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper" />
  </div>
</template>

<script>
import { deepCopy } from '@/utils/handleData'
export default {
  props: {
    formPage: {
      type: Object,
      default: () => {
        return {
          index: 1, size: 20
        }
      }
    },
    total: {
      type: Number,
      default: 0
    },
    pageSizes: {
      type: Array,
      default: () => { return [5, 10, 20, 30, 50, 100] }
    }
  },
  created() {
    this.listQuery = deepCopy(this.formPage)
  },
  data() {
    return {
      listQuery: {}
    }
  },
  methods: {
    // 页面显示数量改变事件
    handleSizeChange(e) {
      this.listQuery = {
        size: e,
        index: 1
      }
      this.onChange()
    },
    // 当前页面改变事件
    handleCurrentChange(e) {
      this.listQuery.index = e
      this.onChange()
    },
    onChange() {
      this.$emit('change', this.listQuery)
    }
  }
}
</script>

<style lang="scss" scoped>
.jlk-pagination {

  // background-color: $footer-color;
  ::v-deep .el-pagination {
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .el-select .el-input .el-input__inner,
    button:disabled,
    .btn-prev,
    .el-pager li,
    .el-pager li.active,
    .btn-next,
    .el-pagination__editor.el-input .el-input__inner {
      background-color: #f8f8f8;
    }
  }
  ::v-deep.el-pagination__jump{
    margin-left:10px
  }
}
</style>

import * as User from "./user";
const menus = require("./menu.js");
const upload = require("./upload.js");
const role = require("./role");
const logManagement = require("./logManagement");
const dictionaries = require("./dictionaries");
const personal = require("./personal");
const applets = require("./applets");
const merchant = require("./merchant");
const officialAccount = require("./officialAccount");
const replay = require("./replay");
const merchantInfo = require("./merchantInfo");
const store = require("./store");
const archives = require("./archives");
const vipInfos = require("./vipInfos");
const rechargeRules = require("./rechargeRules");
const pointsExchange = require("./pointsExchange");
const carousels = require("./carousels");
const coupons = require("./coupons");
const item = require("./item");
const branches = require("./branches");
const marketCampaign = require("./marketCampaign");
const pointRules = require("./pointRules");
const member = require("./member");
const analysisLibrary = require("./analysisLibrary");
const reports = require("./reports");
const cloudBack = require("./cloudBackcups");
const resource = require("./resource");
const merapi = require("./merApi.js");
export default {
  ...resource,
  ...cloudBack,
  ...User,
  ...menus,
  ...upload,
  ...role,
  ...logManagement,
  ...dictionaries,
  ...personal,
  ...applets,
  ...merchant,
  ...officialAccount,
  ...replay,
  ...merchantInfo,
  ...store,
  ...archives,
  ...vipInfos,
  ...rechargeRules,
  ...pointsExchange,
  ...carousels,
  ...coupons,
  ...item,
  ...branches,
  ...marketCampaign,
  ...pointRules,
  ...member,
  ...analysisLibrary,
  ...reports,
  ...merapi,
};

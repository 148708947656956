/*
set sidebar open or close,and some app setting
 */
const state = {
  isFullScreen: false,
  sidebar: true,
}
const mutations = {
  setSideBar(state, payload) {
    state.sidebar = payload
  },
}
const actions = {
  setSideBarAction({ commit }, view) {
    commit('setSideBar', view)
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
}

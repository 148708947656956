import request from "@/utils/request";
//获取角色字典
export const queryRoleDictionaries = (params={}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/role",
    params
  });
};
// 获取操作字典
export const operationDictionaries = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/operate",
    params,
  });
};
// 获取商户会员等级字典集合
export const dictionariesGrade = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/grade",
    params,
  });
};
// 获取行业集合
export const industries = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/industries",
    params,
  });
};
// 获取商户会员启用的等级字典集合
export const enableGrade = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/enable-grade",
    params,
  });
};

// 获取小程序菜单字典集合
export const dictionariesAppletMenu = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/applet-menu",
    params,
  });
};
// 获取小程序菜单字典集合
export const dictionariesVipState = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/vip-state",
    params,
  });
};
//获取券类型 券种类
export const dictionariesCouponKin = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/coupon-kin",
    params,
  });
};
//获取会员价枚举字典
export const dictionariesVipPrice = (params = {}) => {
  return request({
    method: "GET",
    url: "​/api​/dictionaries​/vip-price",
    params,
  });
};
//获取商户会员来源字典集合
export const dictionariesVipSource = (params = {}) => {
  return request({
    method: "GET",
    url: "​/api/dictionaries/vip-source",
    params,
  });
};
//获取商户门店字典集合
export const dictionariesBranch = (
  params = {
    type: 82,
  }
) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/branch",
    params,
  });
};
//获取地区字典级联树
export const dictionariesRegion = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/region",
    params,
  });
};
//获取支付方式
export const dictionariesPayChannel = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/pay-way",
    params,
  });
};
//获取营销活动类型
export const marketCampaign = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/market-campaign",
    params,
  });
};

//  获取营销活动状态字典
//
export const getCampaignStatusDic = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/market-campaign-status",
    params,
  });
};
//获取营销活动类型
export const writeOffScene = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/write-off-scene",
    params,
  });
};

//获取券种类字典
export const couponKind = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/coupon-kind",
    params,
  });
};

//获取券类型字典
export const couponType = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/coupon-type",
    params,
  });
};

//获取会员来源
export const getSource = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/vip-source",
    params,
  });
};

export const getBranchDict = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/branch",
    params,
  });
};

export const payStateDict = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/pay-state",
    params,
  });
};

// 储值类型字典
export const storeValueDict = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/stored-value-type",
    params,
  });
};
// 获取微信审核状态字典
export const checkStatus = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/check-status",
    params,
  });
};

// 获取商户用户下的通道集合

export const getUserPasses = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/merchant-user-passes",
    params,
  });
};

// 订单状态

export const getExchangeOrderStatus = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/points-exchange-order-status",
    params,
  });
};

// 消费类型
//
export const getConsumeTypeDict = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/mer-consume-order-source",
    params,
  });
};

// 充值订单状态
//

export const getMerchantOrderStatus = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/mer-recharge-order-status",
    params,
  });
};

// 商户充值支付方式

export const getMerchantPayDic = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/mer-recharge-order-pay-way",
    params,
  });
};

//

export const getMerchantOrderSourceDic = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/mer-recharge-order-source",
    params,
  });
};

// 直连账单状态

export const getBillStatus = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/gmv-bill-status",
    params,
  });
};

// 服务状态
export const getOpenServiceDic = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/service-open-status",
    params,
  });
};

// 会员开卡方式
//
export const getOpenCardWayDic = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/open-card-way",
    params,
  });
};

// 赠送类型
export const getActivityTypeDic = (params = {}) => {
  return request({
    method: "GET",
    url: "/api/dictionaries/activity-type",
    params,
  });
};


import request from "@/utils/request";

import { filteParams } from "@/utils/handleData.js";
//选择优惠券列表
export const couponsChooseList = (params) => {
  return request({
    method: "GET",
    url: "/api/coupons/choose",
    params,
  });
};
//获取优惠券集合
export const couponsList = (params) => {
  return request({
    method: "GET",
    url: "/api/coupons",
    params: filteParams(params),
  });
};
//新增优惠券
export const addCoupons = (data) => {
  return request({
    method: "POST",
    url: "/api/coupons",
    data,
  });
};
//获取优惠券详情
export const couponsDetails = (
  ID,
  params = {
    item_status: 1,
  }
) => {
  return request({
    method: "GET",
    url: `/api/coupons/${ID}`,
    params,
  });
};

//更新优惠券
export const editCoupons = (ID, data) => {
  return request({
    method: "PUT",
    url: `/api/coupons/${ID}`,
    data,
  });
};

//终止营销活动
export const offShelvesCoupons = (ID) => {
  return request({
    method: "PUT",
    url: `/api/coupons/${ID}/off-shelves`,
  });
};

//优惠券领取记录
export const getRecevieRecord = (params) => {
  return request({
    method: "GET",
    url: `/api/coupons/receive-records`,
    params,
  });
};
// 优惠券核销记录
export const getWriteoffRecord = (params) => {
  return request({
    method: "GET",
    url: `/api/coupons/write-off-records`,
    params,
  });
};

//优惠券消费记录
///api/vip-infos/recharge-records
export const recharge_record = (params, id) => {
  return request({
    method: "GET",
    url: `/api/vip-infos/recharge-records?vip_id=${id}`,
    params,
  });
};
//  获取历史备份数据

export const getCloudtHistoyData = (branchId) => {
  return request({
    method: "GET",
    url: `/api/cloud-backups/${branchId}/history`,
  });
};

// 下载历史数据

export const dowloadHistory = (params) => {
  return request({
    url: "/api/cloud-backups/download",
    method: "GET",
    params,
  });
};

// 数据操作日志

export const getCouponsOprLogs = (params) => {
  return request({
    url: "/api/coupons/data-opr-logs",
    method: "GET",
    params,
  });
};

// 停止操作活动

export const stopCouponActive = (id) => {
  return request({
    method: "PUT",
    url: `/api/coupons/${id}/stoped`,
  });
};

// 作废已领取的优惠券
export const invalidCoupon = (data) => {
  return request({
    url: "/api/coupons/nullify-received",
    method: "PUT",
    data,
  });
};

// 作废单个优惠券
export const invalidSingleCoupon = (data) => {
  return request({
    url: "/api/coupons/nullify-single-received",
    method: "PUT",
    data,
  });
};

// 恢复优惠券活动
export const recoverCoupon = (id) => {
  return request({
    url: `/api/coupons/${id}/restore`,
    method: "PUT",
  });
};

// 获取核销渠道字典
//
export const getWriteOffChannel = (params) => {
  return request({
    url: "/api/dictionaries/write-off-channel",
    method: "GET",
    params,
  });
};

// 获取券对应的商品集合
export const getCouponItemList = (params) => {
  return request({
    url: "/api/coupons/coupon-items",
    method: "GET",
    params: filteParams(params)
  });
};

// import $math from '@/utils/math'
// /**
//  * list -> index(该数组中key=val的索引)
//  * @param list 检测数组
//  * @param key 键名
//  * @param value 键值
//  **/
// export function findIndexByKeyValue(list, key, value) {
//   return list.findIndex(item => item[key] === value)
// }

// /**
//  * 父子关系数组 -> 树形结构
//  * @param value 父子关系数组
//  * @param param {ID: ,PID: } 父子关系数组的ID键名、PID键名
//  **/
// export function treeData(value, params) {
//   console.log(value)
//   console.log(params)
//   const { ID, PID } = params
//   const cloneData = JSON.parse(JSON.stringify(value)) || []
//   // console.log(cloneData)
//   return cloneData.filter(item => {
//     console.log(item)
//     const branchArr = cloneData.filter(child => item[ID] === child[PID])
//     item.children = branchArr.length > 0 ? branchArr : ''
//     if (item[PID] == null) {
//       return item[PID] !== 0 || item[PID] !== ''
//     } else {
//       return item[PID] === 0 || item[PID] === ''
//     }
//   })
// }

/**
 * 对源数据截取decimals位小数，不进行四舍五入
 * @param {*} num 源数据
 * @param {*} decimals 保留的小数位数
 */
export const Fixed = (num, decimals) => {
  if (isNaN(num) || (!num && num !== 0)) {
    return "0.00";
  }
  // 默认为保留的小数点后两位
  const dec = decimals || 2;
  const tempNum = Number(num);
  const pointIndex = String(tempNum).indexOf(".") + 1; // 获取小数点的位置 + 1
  const pointCount = pointIndex ? String(tempNum).length - pointIndex : 0; // 获取小数点后的个数(需要保证有小数位)
  // 源数据为整数或者小数点后面小于decimals位的作补零处理
  if (pointIndex === 0 || pointCount <= dec) {
    let tempNumA = tempNum;
    if (pointIndex === 0) {
      tempNumA = `${tempNumA}.`;
      for (let index = 0; index < dec - pointCount; index++) {
        tempNumA = `${tempNumA}0`;
      }
    } else {
      for (let index = 0; index < dec - pointCount; index++) {
        tempNumA = `${tempNumA}0`;
      }
    }
    return tempNumA;
  }
  let realVal = "";
  // 截取当前数据到小数点后decimals位
  realVal = `${String(tempNum).split(".")[0]}.${String(tempNum)
    .split(".")[1]
    .substring(0, dec)}`;
  // 判断截取之后数据的数值是否为0
  if (realVal === 0) {
    realVal = 0;
  }
  return realVal;
};

/**
 * 对源数据截取decimals位小数，不进行四舍五入
 * @param {*} num 源数据
 * @param {*} decimals 保留的小数位数
 */
export const toFixed = (num, digits = 2) => {
  if (isNaN(num) || (!num && num !== 0)) {
    return (0).toFixed(digits);
  }
  let zeroStrNum = num.toString();

  // 处理科学计算情况
  if (zeroStrNum.includes("e")) {
    const m = zeroStrNum.match(/\d(?:\.(\d*))?e([+-]\d+)/);
    zeroStrNum = num.toFixed(Math.max(0, (m[1] || "").length - m[2]));
  }

  let isNegativeNum = false;
  // 判断是否为负数
  if (zeroStrNum.startsWith("-")) {
    isNegativeNum = true;
    zeroStrNum = zeroStrNum.slice(1);
  }
  // 获取小数点位置
  const dotIndex = zeroStrNum.indexOf(".");
  // 如果是整数/保留小数位数等于超过当前小数长度，则直接用toFixed返回
  if (dotIndex === -1 || zeroStrNum.length - (dotIndex + 1) <= digits) {
    return num.toFixed(digits);
  }

  // 找到需要进行四舍五入的部分
  let numArr = zeroStrNum.match(/\d/g) || [];
  numArr = numArr.slice(0, dotIndex + digits + 1);

  // 核心处理逻辑
  if (parseInt(numArr[numArr.length - 1], 10) > 4) {
    // 如果最后一位大于4，则往前遍历+1
    for (let i = numArr.length - 2; i >= 0; i--) {
      numArr[i] = String(parseInt(numArr[i], 10) + 1);
      // 判断这位数字 +1 后会不会是 10
      if (numArr[i] === "10") {
        // 10的话处理一下变成 0，再次for循环，相当于给前面一个 +1
        numArr[i] = "0";
      } else {
        // 小于10的话，就打断循环，进位成功
        break;
      }
    }
  }
  // 将小数点加入数据
  numArr.splice(dotIndex, 0, ".");

  // 处理多余位数
  numArr.pop();

  // 如果事负数，添加负号
  if (isNegativeNum) {
    numArr.unshift("-");
  }

  return Number(numArr.join("")).toFixed(digits);
};

// /**
//  * 树形结构 -> 父子关系数组
//  * @param list 树形结构
//  * @param key 树形结构中的ID键名
//  * @param keyList {key:null} 树形结构的指定key存储 {} 树形结构所有数据存储
//  **/
// export function flattenTree(list, key, keyList = {}) {
//   const arr = []
//   function handleFlatten(list, PID) {
//     list.forEach(item => {
//       if (isEmptyObject(keyList)) arr.push(item)
//       else {
//         const { ...keyList } = item
//         arr.push(keyList)
//       }
//       if (item.children) handleFlatten(item.children, item[key])
//     })
//   }
//   handleFlatten(list, 0)
//   return arr
// }
// /**
//  * 获取两个数组的不同元素
//  * @param arr1 数组1
//  * @param arr2 数组2
//  **/
// export function getArrDifference(arr1, arr2) {
//   return arr1
//     .concat(arr2)
//     .filter(
//       (item, i, arr) =>
//         arr.map(item => JSON.stringify(item)).indexOf(JSON.stringify(item)) ===
//         arr.map(item => JSON.stringify(item)).lastIndexOf(JSON.stringify(item))
//     )
// }

// /**
//  * 合并两个数组并去重
//  * @param arr1 数组1
//  * @param arr2 数组2
//  **/
// export function mergeList(arr1, arr2) {
//   // console.log(arr1.map(item => JSON.stringify(item)))
//   // console.log(arr2.map(item => JSON.stringify(item)))
//   // console.log(arr1.map(item => JSON.stringify(item)).concat(arr2.map(item => JSON.stringify(item))))
//   return [
//     ...new Set(
//       arr1
//         .map(item => JSON.stringify(item))
//         .concat(arr2.map(item => JSON.stringify(item)))
//     )
//   ].map(item => JSON.parse(item))
// }
// /**
//  * value -> Boole(判断该数据是否为数组 -> 布尔值)
//  * @param value 检测数据
//  **/
// export function isArrayFn(value) {
//   if (typeof Array.isArray === 'function') return Array.isArray(value)
//   else return Object.prototype.toString.call(value) === '[object Array]'
// }
// /**
//  * val -> key(通过对象的键值 -> 键名)
//  * @param value 对象数据
//  * @param val 键值
//  **/
// export function findKey(value, val, compare = (a, b) => a === b) {
//   return Object.keys(value).find(k => compare(value[k], val))
// }

// /**
//  * list -> 模糊搜索
//  * @param list 原数组
//  * @param reg 正则匹配是否含有
//  * @param key 数组中对象的键名
//  **/
// export function fuzzyQueryList(list, reg, key) {
//   const regExp = new RegExp(reg)
//   return list.filter(item => regExp.test(item[key]))
// }
// /**
//  * 判断value是否为一个整数
//  **/
// export function isInteger(value) {
//   return Math.floor(value) === value
// }
// /**
//  * 判断value是否为type数据类型
//  **/
// export function dataType(value, type) {
//   if (!value && value !== 0 && value !== false && value !== '') {
//     if (type === null) { return !value && typeof value !== 'undefined' && value !== 0 } else if (type === 'undefined') return typeof value === 'undefined'
//   } else return value.constructor === type
// }

// // 格式化会员卡号
// export function formattedCard(value) {
//   // // let reg = /^(\d{16}|\d{19})$/
//   // return test
//   const memberCard = value + ''
//   let formattedCard = ''
//   if (/\S{5}/.test(memberCard)) {
//     formattedCard = memberCard.replace(/\s/g, '').replace(/(.{4})/g, '$1 ')
//   } else {
//     formattedCard = memberCard
//   }
//   return formattedCard
// }

// 格式化手机号
export function formattedPhone(value) {
  let values = "";
  if (!value) return values;
  const x = value.substring(3, value.length - 4);
  values = value.replace(x, "****");
  return values;
}

export function filteParams(params) {
  const data = {};
  for (const key in params) {
    if (params[key] && params[key] !== false && params[key] != 0) {
      data[key] = params[key];
    }
  }
  return data;
}

/**
 * @desc 对象深拷贝
 * @param source 来源
 * @return 新的对象
 */
export const deepCopy = (source) => {
  if (typeof source != "object") {
    return source;
  }
  if (source == null) {
    return source;
  }
  var newObj = source.constructor === Array ? [] : {}; //开辟一块新的内存空间
  for (var i in source) {
    newObj[i] = deepCopy(source[i]);
  }
  return newObj;
};
/**
 * 判断是否为空  对象||数组||字符串
 **/
export function isEmptyObject(value) {
  return !value ? true : Object.keys(value).length === 0;
}
/**
 * 判断是否为空  对象||数组||字符串
 **/
export function toPercent(point) {
  if (point === 0) {
    return 0;
  }
  let str = Number(point * 100).toFixed();
  str += "%";
  return str;
}

/**
 *
 */
export function sortKey(key) {
  return function (a, b) {
    const x = a[key];
    const y = b[key];
    return x - y; // 从小到大排序
  };
}

// /**
//  * 补盒子
//  * @param boxWidth 父盒子宽度
//  * @param width 子盒子宽度
//  * @param length 数据长度
//  **/
// export function handleFillBox({ boxWidth, width, length }) {
//   const residue = $math.handleTake(boxWidth, width)
//   const mod = length % residue // 余数
//   const count = mod === 0 ? 0 : $math.operation(residue, mod, 'subtract') // 补盒子的数量
//   return count
// }

// /**
//  * select组件的数据过滤
//  */

// export function filtersArr(options) {
//   // 数组的情况
//   if (Array.isArray(options) && options) {
//     return options
//   }
//   // 对象过滤为数组
//   if (options) {
//     const Arr = []
//     Object.keys(options).forEach(ele => {
//       var obj = {}
//       obj.value = ele
//       obj.label = options[ele]
//       Arr.push(obj)
//     })
//     return Arr
//   }
// }

// /**
//  * 转url?后面的参数
//  */

export function getNameQuery() {
  var url = location.search; //获取url中"?"符后的字串
  var theRequest = {};
  var strs = "";
  if (url.indexOf("?") != -1) {
    var str = url.substr(1);
    strs = str.split("&");
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
    }
  }
  return theRequest;
}

export function downloadBolb(source, name) {
  let b = new Blob([source]); //拿到文件流下载对象, 有的定义不需要.data,根据后端返回字段定
  let url = window.URL.createObjectURL(b); //生成文件流下载链接
  let a = document.createElement("a"); //创建一个a标签用来跳转
  a.href = url; // a标签 href  赋值 链接
  a.download = name + ".zip"; //设置下载文件的文件名和文件格式， 这里一定要加上格式，不然下载的文件一直是txt格式的
  document.body.appendChild(a); //将标签DOM，放置页面
  a.click();
  window.URL.revokeObjectURL(url); //释放 url 对象内存
  document.body.removeChild(a);
}

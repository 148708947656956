<template>
  <el-button
    :size="size"
    :type="type"
    :loading="loading"
    v-if="hasPerms(perms)"
    :disabled="disabled"
    @click="handleClick"
  >
    {{ label }}
  </el-button>
</template>
<script>
import { hasPermission } from './hasPermission'
export default {
  name: 'KtButton',
  props: {
    label: {
      // 按钮显示文本
      type: String,
      default: 'Button'
    },
    size: {
      // 按钮尺寸
      type: String,
      default: 'mini'
    },
    type: {
      // 按钮类型
      type: String,
      default: null
    },
    loading: {
      // 按钮加载标识
      type: Boolean,
      default: false
    },
    disabled: {
      // 按钮是否禁用
      type: Boolean,
      default: false
    },
    perms: {
      // 按钮权限标识，外部使用者传入  查询:get 新增:create 更新:update 删除:delete 导出:export  审核:check 打印:print
      type: String,
      default: null
    }
  },
  data() {
    return {}
  },
  methods: {
    handleClick() {
      // 按钮操作处理函数
      this.$emit('click', {})
    },
    hasPerms(perms) {
      // 根据权限标识和外部指示状态进行权限判断
      if (!perms) {
        return true
      }
      return hasPermission(this.filterRoute, perms)
    }
  },
  computed: {
    filterRoute() {
      let arr = ''
      const permission = this.$store.state.user.user.permissions
      if (permission) {
        permission.forEach(item => {
          if (
            item.menu_level === 3 &&
            item.request_address === this.$route.path
          ) {
            arr = item.permissions
          }
        })
      }
      return arr
    }
  }
}
</script>
<style lang="scss" scoped>
  .el-button--mini,
  .el-button--mini.is-round {
    padding: 7px 15px;
    height: 30px;
    font-size: 14px;
  }
</style>

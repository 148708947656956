<template>
  <div class="contains">
    <el-upload
      :limit="limit"
      action="#"
      :accept="type"
      list-type="picture-card"
      :auto-upload="true"
      :http-request="uploadFile"
    >
      <div slot="default" class="flexColumn">
        <i
          class="iconfont icon-tianjiatubiao1"
          style="color:#fff;display: block;"
        ></i>
        <span style="color:#fff;">选择图片</span>
      </div>
      <div slot="file" slot-scope="{ file }">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>
          <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    limit: {
      default: () => 1,
    },
    type: {
      default: () => 'image/*',
    },
  },
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
    }
  },
  methods: {
    handleRemove(file) {
      const filePath = file.response.data.tmp_path
      // 2.从pics数组中，找到图片对应的索引值
      const i = this.formData.pics.findIndex((x) => x.pic === filePath)
      // 3.调用splice方法，移除图片信息
      this.formData.splice(i, 1)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    async uploadFile(e) {
      const file = e.file
      const size = file.size / 1024 / 1024 / 5
      ///api/upload/image
      if (size > 5) {
        this.$JlkMessage('图片大小必须小于5M', 'warning')
      } else {
        const params = new FormData()
        params.append('file', file)
        const ret = await this.$API.uploading(params)
        if (ret && ret.ok) {
          this.$emit('upload', ret.data)
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.contains {
  width: 100px;
  height: 100px;
  overflow: hidden;

  ::v-deep.el-upload {
    height: 100px;
    width: 100px;
    line-height: 100px;
    background: #000000;
    opacity: 0.5;
    border: none;
  }
}

.flexColumn {
  height: 100px;
  width: 100px;
  box-sizing: border-box;
  padding: 10px 0px;
  line-height: 40px;
}
</style>

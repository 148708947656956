import layout from "@/layout/index.vue";
import store from "@/store";
import { getNameQuery } from "@/utils/handleData";
import versionTood from "@/utils/versionUpdate";
import consoles from "@/views/console.vue";
import dashBoard from "@/views/dashBoard/index.vue";
import init from "@/views/init.vue";
import login from "@/views/login/index.vue";
import available from "@/views/noAvailable.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import reports from "./reports";
import account from "@/views/account/index.vue";

Vue.use(VueRouter);
const routes = [
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/account",
    name: "account",
    component: account,
  },
  {
    path: "/init",
    name: "init",
    component: init,
  },
  {
    path: "/console",
    name: "console",
    component: consoles,
  },
];

const router = new VueRouter({
  mode: "history",
  path: "/",
  routes,
});
const originalPush = VueRouter.prototype.push;
const originReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(function (err) {
    return err;
  });
};
VueRouter.prototype.replace = function (location) {
  return originReplace.call(this, location).catch(function (err) {
    return err;
  });
};
let isLoadRoute = false;
router.beforeEach((to, from, next) => {
  // 检查版本
  versionTood.isNewVersion();
  if (!store.state.user.user) {
    // 跳转到登录页面
    if (to.name === "login") {
      return next();
    } else {
      next({
        name: "login",
      });
    }
  } else {
    const permissions =
      store.state.user.user.vip_permissions &&
      store.state.user.user.vip_permissions.length
        ? "vip_permissions"
        : "merchant_center_permissions";
    const path = "/dashBoard";
    if (!isLoadRoute) {
      isLoadRoute = true;
      addRoute(store.state.user.user[permissions]);
      return next({
        ...to,
        replace: true,
      });
    }
    if (to.name === "login") {
      next({
        path,
      });
    } else {
      if (to.name) {
        next();
      } else {
        next({
          path,
        });
      }
    }
  }
});

function addRoute(route) {
  console.log(route);
  const children = route.reduce((total, currentValue) => {
    let path = currentValue.request_address;
    if (currentValue.menu_type == 2 || currentValue.menu_type == 3) {
      path = path.indexOf("?") > -1 ? path.split("?")[0] : path;
    }
    if (path.length > 2) {
      const arr = path.split("/");
      const name = arr[arr.length - 1];
      total.push({
        path,
        name,
        meta: {
          title: currentValue.menu_name,
        },
        component: (resolve) =>
          require([
            `@/views${path.indexOf("?") > -1 ? path.split("?")[0] : path}`,
          ], resolve),
      });
    }
    return total;
  }, []);
  const r = [
    {
      path: "/",
      component: layout,
      meta: {
        jurisdiction: true,
      },
      children: [
        {
          path: "/available",
          name: "available",
          component: available,
        },
        {
          path: "/dashBoard",
          name: "dashBoard",
          component: dashBoard,
          meta: {
            title: "首页",
          },
        },
        {
          path: "/membermanage",
          name: "membermanage",
          meta: {
            title: "会员管理",
          },
          component: () => import("@/views/member/manage/index.vue"),
          props: true,
        },
        {
          path: "/memberdetails/:id",
          name: "memberdetails",
          meta: {
            title: "会员详情",
            noCache: true,
          },
          component: () => import("@/views/member/manage/details/index.vue"),
          props: true,
        },
        {
          path: "/setMemberCard",
          name: "setMemberCard",
          meta: {
            title: "会员卡设置",
            noCache: true,
          },
          component: () => import("@/views/member/memberCard/setInfo.vue"),
          props: true,
        },
        {
          path: "/userManagement",
          name: "userManagement",
          meta: {
            title: "用户管理",
          },
          component: () => import("@/views/system/userManagement/index.vue"),
          props: true,
        },
        {
          path: "/rechargeDescription",
          name: "rechargeDescription",
          meta: {
            title: "充值说明",
          },
          component: () =>
            import(
              "@/views/member/rechargeManage/rechargeDescription/index.vue"
            ),
          props: true,
        },
        {
          path: "/addCharge",
          name: "addRecharge",
          meta: {
            title: "新增方案",
          },
          component: () =>
            import("@/views/member/rechargeManage/addRecharge/index.vue"),
          props: true,
        },
        {
          path: "/copyCharge/:id",
          name: "copyCharge",
          meta: {
            title: "新增方案",
          },
          component: () =>
            import("@/views/member/rechargeManage/copyCharge/index.vue"),
          props: true,
        },
        {
          path: "/chargeDetail/:id",
          name: "chargeDetail",
          meta: {
            title: "充值方案详情",
            noCache: true,
          },
          component: () =>
            import("@/views/member/rechargeManage/changeRecharge/index.vue"),
          props: true,
        },
        {
          path: "/editRecharge/:id",
          name: "editRecharge",
          meta: {
            title: "编辑方案",
            noCache: true,
          },
          component: () =>
            import("@/views/member/rechargeManage/editRecharge/index.vue"),
          props: true,
        },
        {
          path: "/addrightsandbenefits",
          name: "addRAB",
          meta: {
            title: "新增权益",
          },
          component: () =>
            import("@/views/member/rightsAndBenefits/addRAB/index.vue"),
          props: true,
        },
        {
          path: "/editRrightsandbenefits/:id",
          name: "editRAB",
          meta: {
            title: "编辑权益",
          },
          component: () =>
            import("@/views/member/rightsAndBenefits/editRAB/index.vue"),
          props: true,
        },
        {
          path: "/addCouponDetail",
          name: "addCouponDetail",
          meta: {
            title: "新增优惠券",
          },
          component: () =>
            import("@/views/marketing/couponManage/detail/index.vue"),
          props: true,
        },
        {
          path: "/copyCouponDetail/:id",
          name: "copyCouponDetail",
          meta: {
            title: "新增优惠券",
          },
          component: () =>
            import("@/views/marketing/couponManage/copyDetail/index.vue"),
          props: true,
        },
        {
          path: "/marketing/couponManage/addDiscount",
          name: "addDiscount",
          meta: {
            title: "折扣券",
          },
          component: () =>
            import("@/views/marketing/couponManage/addDiscount/index.vue"),
          props: true,
        },
        {
          path: "/marketing/couponManage/addReduce",
          name: "addReduce",
          meta: {
            title: "满减券",
          },
          component: () =>
            import("@/views/marketing/couponManage/addReduce/index.vue"),
          props: true,
        },
        {
          path: "/marketing/couponManage/addReplaceMoney",
          name: "addReplaceMoney",
          meta: {
            title: "优惠券新增",
          },
          component: () =>
            import("@/views/marketing/couponManage/addReplaceMoney/index.vue"),
          props: true,
        },
        {
          path: "/marketing/couponManage/addSpecial",
          name: "addSpecial",
          meta: {
            title: "特价券",
          },
          component: () =>
            import("@/views/marketing/couponManage/addSpecial/index.vue"),
          props: true,
        },
        {
          path: "/coupondetail/:id",
          name: "coupondetail",
          meta: {
            noCache: true,
          },
          component: () =>
            import("@/views/marketing/couponManage/checkDetail/index.vue"),
          props: true,
        },
        {
          path: "/marketing/checkCouponDetail/:id",
          name: "checkCouponDetail",
          meta: {
            title: "优惠券详情",
            noCache: true,
          },
          component: () =>
            import(
              "@/views/marketing/couponManage/checkCouponDetail/index.vue"
            ),
          props: true,
        },
        {
          path: "/analysisLibrary/member/consumeTotal/detail",
          name: "consumeTotal_detail",
          meta: {
            title: "消费统计详情",
            noCache: true,
          },
          component: () =>
            import("@/views/analysisLibrary/member/consumeTotal/detail.vue"),
          props: true,
        },
        {
          path: "/analysisLibrary/member/memberTotal/pointDetail",
          name: "point_detail",
          meta: {
            title: "积分明细",
            noCache: true,
          },
          component: () =>
            import(
              "@/views/analysisLibrary/member/memberTotal/pointDetail.vue"
            ),
          props: true,
        },
        {
          path: "/analysisLibrary/member/memberTotal/storeDetail",
          name: "point_detail",
          meta: {
            title: "储值明细",
            noCache: true,
          },
          component: () =>
            import(
              "@/views/analysisLibrary/member/memberTotal/storeDetail.vue"
            ),
          props: true,
        },
        {
          path: "/analysisLibrary/member/refund/detail",
          name: "refund_detail",
          meta: {
            title: "退款详情",
            noCache: true,
          },
          component: () =>
            import("@/views/analysisLibrary/member/refund/detail.vue"),
          props: true,
        },
        {
          //detail
          path: "/marketing/marketingCenter/attentionGifts",
          name: "attentionGifts",
          meta: {
            title: "关注有礼",
          },
          component: () =>
            import(
              "@/views/marketing/marketingCenter/attentionGifts/index.vue"
            ),
          props: true,
        },
        {
          //detail
          path: "/marketing/marketingCenter/consumeGifts",
          name: "consumeGifts",
          meta: {
            title: "消费有礼",
          },
          component: () =>
            import("@/views/marketing/marketingCenter/consumeGifts/index.vue"),
          props: true,
        },
        {
          // 新增和编辑
          path: "/marketing/marketingCenter/consumeGifts/default",
          name: "consumeGiftsDefault",
          component: () =>
            import(
              "@/views/marketing/marketingCenter/consumeGifts/default.vue"
            ),
          props: true,
        },
        {
          // 详情
          path: "/marketing/marketingCenter/consumeGifts/detail",
          name: "consumeGiftsDetail",
          meta: {
            noCache: true,
          },
          component: () =>
            import("@/views/marketing/marketingCenter/consumeGifts/detail.vue"),
          props: true,
        },
        {
          //detail
          path: "/marketing/marketingCenter/openCardGifts",
          name: "openCardGifts",
          meta: {
            title: "开卡有礼",
          },
          component: () =>
            import("@/views/marketing/marketingCenter/openCardGifts/index.vue"),
          props: true,
        },
        {
          //detail
          path: "/marketing/marketingCenter/givingGifts",
          name: "givingGifts",
          meta: {
            title: "定向赠送",
          },
          component: () =>
            import("@/views/marketing/marketingCenter/givingGifts/index.vue"),
          props: true,
        },
        {
          // 新增和编辑
          path: "/marketing/marketingCenter/givingGifts/default",
          name: "givingGiftsDefault",
          component: () =>
            import("@/views/marketing/marketingCenter/givingGifts/default.vue"),
          props: true,
        },
        {
          // 详情
          path: "/marketing/marketingCenter/givingGifts/detail",
          name: "givingGiftsDetail",
          meta: {
            noCache: true,
          },
          component: () =>
            import("@/views/marketing/marketingCenter/givingGifts/detail.vue"),
          props: true,
        },
        {
          path: "/merchantManagement/merchant/branch",
          name: "merchantBranch",
          meta: {
            title: "门店管理",
          },
          component: () =>
            import("@/views/merchantManagement/merchant/branch.vue"),
          props: true,
        },
        {
          path: "/merchantManagement/merchant/detail",
          name: "merchantDetail",
          meta: {
            title: "商户详情",
          },
          component: () =>
            import("@/views/merchantManagement/merchant/detail.vue"),
          props: true,
        },
        {
          path: "/cloudBackup/cloud/detail",
          name: "cloudBackupDetail",
          meta: {
            title: "备份详情",
          },
          component: () => import("@/views/cloudBackup/cloud/detail.vue"),
          props: true,
        },
        {
          path: "/payManage/resourcePack/phoneDosage/:id",
          name: "phoneDosage",
          meta: {
            title: "用量详情",
          },
          component: () =>
            import("@/views/payManage/resourcePack/phoneDosage/index.vue"),
          props: true,
        },
        {
          path: "/payManage/resourcePack/quickPurchase/:id",
          name: "quickPurchase",
          meta: {
            title: "资源包购买",
          },
          component: () =>
            import("@/views/payManage/resourcePack/quickPurchase/index.vue"),
          props: true,
        },
        {
          path: "/payManage/resourcePack/dosageOrder/:id",
          name: "phoneDosage",
          meta: {
            title: "资源包订单",
          },
          component: () =>
            import("@/views/payManage/resourcePack/dosageOrder/index.vue"),
          props: true,
        },
        {
          path: "/payManage/storeVal/merchantStoreVal/storeOrder/:id",
          name: "storeOrder",
          meta: {
            title: "商户充值",
          },
          component: () =>
            import(
              "@/views/payManage/storeVal/merchantStoreVal/storeOrder.vue"
            ),
          props: true,
        },
        {
          path: "/payManage/storeVal/merchantStoreVal/merchantRecharge/:id",
          name: "merchantRecharge",
          meta: {
            title: "储值订单",
          },
          component: () =>
            import(
              "@/views/payManage/storeVal/merchantStoreVal/merchantRecharge.vue"
            ),
          props: true,
        },
        {
          path: "/system/userManagement/userHanlde",
          name: "userHanlde",
          meta: {},
          component: () =>
            import("@/views/system/userManagement/userHanlde/index.vue"),
          props: true,
        },
        {
          path: "/system/userManagement/userLook",
          name: "userLook",
          meta: {
            noCache: true,
            title: "员工资料",
            noCache: true,
          },
          component: () =>
            import("@/views/system/userManagement/userLook/index.vue"),
          props: true,
        },
        {
          path: "/system/roleManagement/permissionSetting",
          name: "permissionSetting",
          meta: {
            title: "权限设置",
          },
          component: () =>
            import("@/views/system/roleManagement/permissionSetting/index.vue"),
          props: true,
        },
        {
          path: "/authorization/applets/details",
          name: "applet_detail",
          meta: {
            title: "小程序详情",
            noCache: true,
          },
          component: () => import("@/views/authorization/applets/details.vue"),
          props: true,
        },
        {
          path: "/system/storeManagement/detail",
          name: "storeManagement_detail",
          meta: {
            title: "门店编辑",
          },
          component: () => import("@/views/system/storeManagement/detail.vue"),
          props: true,
        },
        ...children,
        ...reports,
      ],
    },
  ];
  router.addRoutes(r);
}

export default router;

export function resetLoad() {
  isLoadRoute = false;
}
